import styled from 'styled-components';

export default styled.button`
  padding: 0 16px;
  margin: 0;
  font-size: 24px;
  line-height: 63px;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
`;
