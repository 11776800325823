import * as React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;

  isOn: boolean;

  onIconName?: string;

  offIconName?: string;

  onLabel?: string;

  offLabel?: string;

  onToggled: () => void;
};

const CameraToggle: React.FunctionComponent<Props> = props => {
  const { className, isOn, onIconName, offIconName, onLabel, offLabel, onToggled } = props;

  const isOnClassName = isOn ? 'on' : 'off';

  const offIconNameDefined = offIconName ? offIconName : onIconName;
  const isIconName = isOn ? onIconName : offIconNameDefined;

  const offLabelDefined = offLabel ? offLabel : onLabel;
  const label = isOn ? onLabel : offLabelDefined;

  return (
    <div className={`${className} ${isIconName} ${isOnClassName}`} onClick={onToggled}>
      <section className="toggle-area">
        <div className="bubble-background"></div>
        <div className="bubble-icon"></div>
      </section>
      <p>{label}</p>
    </div>
  );
};

export default styled(CameraToggle)`
  position: relative;
  padding-top: 8px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-child {
    margin-left: unset;

    > p {
      opacity: 0;
    }
  }

  section.toggle-area {
    cursor: pointer;
    position: relative;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-content: center;

    &:active .bubble-background {
      background-color: white;
      transition: all 0s ease-in-out;
    }

    .bubble-background,
    .bubble-icon {
      content: ' ';
    }

    .bubble-background {
      transition: all 1s ease-in-out;
      position: absolute;
      background-color: transparent;

      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: white;
    }

    .bubble-icon {
      margin: 5px;
      width: 25px;
      height: 25px;
      mask-position: center center;
      mask-size: cover;
      border: none;
      outline: none;
      background-color: black;
    }
  }

  p {
    margin: 0;
    margin-top: 3px;
    padding: 0 2px;
    color: white;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
  }

  &.off {
  }

  &.on {
  }

  &.icon-car-lights > section.toggle-area > .bubble-icon {
    mask-image: url('./icons/car-lights.svg');
  }

  &.icon-car-doors > section.toggle-area > .bubble-icon {
    mask-image: url('./icons/car-doors.svg');
  }

  &.icon-play > section.toggle-area > .bubble-icon {
    mask-image: url('./icons/play.svg');
  }

  &.icon-pause > section.toggle-area > .bubble-icon {
    mask-image: url('./icons/pause.svg');
  }

  &.icon-lock > section.toggle-area > .bubble-icon{
    mask-image: url('./icons/lock.svg');
  }

  &.icon-unlock > section.toggle-area > .bubble-icon {
    mask-image: url('./icons/unlock.svg');
  }
`;
