import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  visible: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  onSelectPrevious?: () => void;
  onSelectNext?: () => void;
};

const ItemSwitcher: React.FunctionComponent<Props> = props => {
  const { className, visible, onSelectPrevious, onSelectNext, hasPrevious, hasNext } = props;

  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');
  return (
    <div className={classNames}>
      <img
        className={`previous-switcher ${hasPrevious ? '' : 'disabled'}`}
        src="./icons/chevron-left-narrow-thin.svg"
        onClick={onSelectPrevious}
        alt=""
      />

      <img
        className={`next-switcher ${hasNext ? '' : 'disabled'}`}
        src="./icons/chevron-right-narrow-thin.svg"
        onClick={onSelectNext}
        alt=""
      />
    </div>
  );
};

export default styled(ItemSwitcher)`
  .previous-switcher,
  .next-switcher {
    width: 86px;
    height: 86px;
    position: absolute;
    top: calc(50% - 43px);

    cursor: pointer;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.75));
    opacity: 1;

    &.disabled {
      pointer-events: none;
      opacity: 0;
    }

    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }

  .previous-switcher {
    left: 13px;
  }

  .next-switcher {
    right: 13px;
  }

  &.hidden {
    .previous-switcher {
      transform: translateX(-86px);
      opacity: 0;
    }

    .next-switcher {
      transform: translateX(86px);
      opacity: 0;
    }
  }

  &.visible {
    .previous-switcher {
      transform: translateX(0px);
    }

    .next-switcher {
      transform: translateX(0px);
    }
  }
`;
