import React from 'react';
import styled from 'styled-components';

import { ViewObject } from '../../../shared/model/FeatureSet';
import AnimatedCheckmark from '../../components/AnimatedCheckmark';

type Props = {
  className?: string;
  view: ViewObject;
  onViewSelected: (view: ViewObject) => void;
};

const ViewItem = (props: Props) => {
  const { className, view, onViewSelected } = props;
  const state = view.isActive ? 'active' : 'inactive';
  const classes = `${className} state-${state}`;
  return (
    <li
      className={classes}
      onClick={() => {
        onViewSelected(view);
      }}
    >
      {view.title}
      {/* <span className={'blend'} /> */}
      <span className={'content'}>{view.title}</span>
      <AnimatedCheckmark visible={view.isFullyConfigured} />
    </li>
  );
};

export default styled(ViewItem)`
  list-style: none;
  position: relative;
  cursor: pointer;
  padding: 14px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 4px;
  letter-spacing: 0.45px;
  text-transform: uppercase;
  color: transparent;

  &.state-active,
  &.state-active span.content {
    font-family: 'ZwoLF Bold';
    letter-spacing: 0.01em;
  }

  .blend {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${p => p.theme.colors.blend};
    mix-blend-mode: ${p => p.theme.mixBlendMode};
    transform: translate3d(0, 0, 0);
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 14px;
    /* dark screen */
    /* color: ${p => p.theme.colors.onboardingText};
    text-shadow: 
      0px 0px 50px rgba(0,0,0,1), 
      1px 0px 20px rgba(0,0,0,0.2), 
      0px 1px 3px rgba(0,0,0.3); */
    /* bright screen */
    color: ${p => p.theme.colors.onboardingBackground};
    text-shadow: 
      0px 0px 50px rgba(255,255,255,1), 
      1px 0px 20px rgba(255,255,255,0.2), 
      0px 1px 3px rgba(255,255,255.3);
  }

  ${AnimatedCheckmark} {
    position: absolute;
    top: 10px;
    right: 0px;
    /* dark screen */
    /* background-color: ${p => p.theme.colors.onboardingText}; */
    /* bright screen */
    background-color: ${p => p.theme.colors.onboardingBackground};
  }
`;
