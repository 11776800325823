import { CarModel, CustomerConfiguration, ID, Preset, Option, View } from '../../shared/model/RawModel';
import { RenderRequest, RenderResponse, ImageType } from '../../shared/model/RenderResponse';
import { AppConfiguration } from '../app/AppConfiguration';
import { useItemWithFetch } from './useItemsFromServer';

type CarRenderRequest = {
  width: number;
  height: number;
  viewID?: ID<View>;
  carModelId?: ID<CarModel>;
  presetId?: ID<Preset>;
  customerConfigurationId?: ID<CustomerConfiguration>;
  delayLoading?: boolean;
  selectedOptions?: ReadonlyArray<ID<Option>>;
  imageType: ImageType;
};

const jsonHeader = {
  'Content-Type': 'application/json',
};

export const useCarImage = (request: CarRenderRequest) => {
  const { width, height, viewID, carModelId, presetId, customerConfigurationId, delayLoading, selectedOptions, imageType } = request;

  const requestOptions: RenderRequest = {
    width: width,
    height: height,
    viewID: viewID,
  };

  let url: string;
  if (carModelId) {
    url = `${AppConfiguration.baseServerUrl}/api/render/carModel/${carModelId}`;
  } else if (presetId) {
    url = `${AppConfiguration.baseServerUrl}/api/render/preset/${imageType}/${presetId}`;
  } else if (customerConfigurationId) {
    url = `${AppConfiguration.baseServerUrl}/api/render/customerConfiguration/${imageType}/${customerConfigurationId}`;
  }
  else {
    throw new Error('This should not happen');
  }

  return useItemWithFetch<RenderResponse>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(requestOptions),
      headers: jsonHeader,
      delayLoading,
    },
    viewID,
    carModelId,
    presetId,
    customerConfigurationId,
    JSON.stringify(selectedOptions)
  );
};
