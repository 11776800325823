import { FeatureObject } from './../../../shared/model/FeatureSet';

export const isActivationFeature = (feature: FeatureObject): boolean => {
  return feature.uiComponent === 'optionToggle' && feature.uiContainer === 'onOffToggleInFeatureSettingsRegion';
};

export const isFeatureActivated = (feature: FeatureObject): boolean => {
  return isActivationFeature(feature) && (feature.selectedOptionId === '_Yes' || feature.selectedOptionId === '_Yes');
};
export const isFeatureDeactivated = (feature: FeatureObject): boolean => {
  return isActivationFeature(feature) && (feature.selectedOptionId === '_No' || feature.selectedOptionId === '_No' );
};
