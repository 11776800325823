import React from 'react';
import styled from 'styled-components';

import { FeatureObject, FeatureSetObject } from '../../../shared/model/FeatureSet';
import { isActivationFeature, isFeatureDeactivated } from './activationFeatureLogic';

type Props = {
  className?: string;
  feature: FeatureSetObject;
  isActive: boolean;
  onSelect: (featureSetEntry: FeatureSetObject) => void;
};

function getChosenOptionNamesOfChildren(feature: FeatureSetObject): string {
  if (feature.layout === 'sidePanel' || feature.layout === 'fullscreen')
    return feature.isConfigured ? 'completed' : 'not visited';

  const activationFeature = feature.featureObjects.find(feature => isActivationFeature(feature));

  if (activationFeature && isFeatureDeactivated(activationFeature)) {
    return getChosenOptionNames(activationFeature);
  }

  const chosenOptions = feature.featureObjects
    .filter(feature => feature.selectedOptionId !== 'Glossy')
    .map(f => getChosenOptionNames(f));

  const filteredChosenOptions = chosenOptions.filter(o => o !== '');
  return filteredChosenOptions.join(', ');
}

function getChosenOptionNames(feature: FeatureObject): string {
  const options = feature.optionSetObjects.flatMap(ose => ose.options);
  const option = feature.selectedOptionId
    ? options.find(o => o.optionId === feature.selectedOptionId)
    : options.find(o => o.status === 'selected-from-preset');
  return option ? option.title : '';
}

const FeatureItem = (props: Props) => {
  const { className, feature, onSelect, isActive } = props;

  const state = isActive
    ? 'active'
    : feature.isConfigured
    ? 'visited'
    : feature.isNextFeatureSet
    ? 'next'
    : 'not-visited';

  const classes = `${className} state-${state}`;
  const combinedChoiceLabel = getChosenOptionNamesOfChildren(feature);

  return (
    <li className={classes} onClick={() => onSelect(feature)}>
      <img className="left" src="./icons/feature-left.svg" alt="" />
      <span className="middle">
        <span className={`feature state-${state}`}>{feature.title}</span>
        <span className={`choice state-${state}`}>{combinedChoiceLabel}</span>
      </span>
      <img className="right" src="./icons/feature-right.svg" alt="" />
    </li>
  );
};

export default styled(FeatureItem)`
  flex-grow: 2;
  list-style: none;
  letter-spacing: 0.45px;
  text-transform: uppercase;
  opacity: 50%;
  display: flex;
  flex-direction: row;
 
  .middle {
    flex-grow: 1;
    height: 57px;
    /* max-width: 148px; */
    padding-left: 0px;
    padding-right: 0px;
    background-color: ${p => p.theme.colors.background};
    color: ${p => p.theme.colors.foreground};

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .feature,
    .choice {
      /* max-width: 140px; */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .feature {
      font-family: 'ZwoLF SemiBold';
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
    }

    .choice {
      /* max-width: 110px; */
      font-family: 'ZwoLF SemiLight';
      font-size: 9px;
      white-space: wrap;

      &.state-next {
      }

      &.state-visited {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:after {
          content: ' ';
          display: inline-block;
          position: relative;
          margin-bottom: 2px;
          right:0;
          width: 16px;
          height:16px;
          mask-image: url('./icons/checkmark.svg');
          mask-size: 100% 100%;
          background-color: ${p => p.theme.colors.foreground};
        }
      }

      &.state-not-visited{
        opacity: 50%;
      }
    }
  }

  &.state-active {
    font-family: 'ZwoLF Bold', sans-serif;
    letter-spacing: 0px;
  }

  &.state-next {
    opacity: 100%;
  }

  &.state-visited {
  }
`;
