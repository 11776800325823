import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../shared/model/FeatureSet';
import { ID, Option } from '../../../../../shared/model/RawModel';

type Props = {
  className?: string;
  feature: FeatureObject;
  options: ReadonlyArray<OptionObject>;
  onResetToPreset: () => void;
  onSelect: (optionId: ID<Option>) => void;
};
const FeatureToggle: React.FunctionComponent<Props> = props => {
  const { options, onSelect } = props;

  if(options.length !== 2)
  {
    return <div className="error-message">
        <>
        Feature Toggle: {props.feature.title} does not have 2 options: {options}
        </>
        </div>
  }
  const first = options[0];
  const second = options[1];
  const isSecondSelected = second.status === 'selected-from-preset' || second.status === 'selected-by-user';
  // const selectedOptionTitle = isSecondSelected ? second.title : first.title;
  const inputChanged: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.checked) {
      onSelect(second.optionId);
    } else {
      onSelect(first.optionId);
    }
  };

  return (
    <section className={props.className}>
      <label className="checkbox-container" htmlFor="toggle">
        <input id="toggle" type="checkbox" checked={isSecondSelected} onChange={inputChanged} />
        <span className="checkmark"></span>
      </label>
    </section>
  );
};
export default styled(FeatureToggle)`
  .checkbox-container {
    margin-left: 10px;
    margin-right: -10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  label {
    content: ' ';
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    width: 49px;
    height: 29px;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    align-self: flex-end;
    top: 0;
    left: 0;
    width: 52px;
    height: 36px;
    background-image: url('./images/switch-off@2x.png');
    background-size: 100% 100%;
  }

  /* Show the active switch image when checked */
  .checkbox-container input:checked ~ .checkmark {
    background-image: url('./images/switch-on@2x.png');
    background-size: 100% 100%;
  }
`;
