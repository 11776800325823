import * as React from 'react';
import styled from "styled-components"

type Props={
    className?: string;
    message: string;
}

const CodeWidgetErrorMessage: React.FunctionComponent<Props> = props => {
  const {className, message} = props;

  return (
    <form className ={className}>
      <div>
        <div className='error-title'>something went wrong :(</div>
        <div className='error-text'>
          <p>
            {message}
          </p>
        </div>
      </div>
    </form>
    )
}

export default styled(CodeWidgetErrorMessage)`
  .error-title{
    color: ${p => p.theme.colors.onboardingText};
    font-family: 'ZwoLF Bold';
    font-size: 32px;
    line-height: 69px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
  }

  .error-text{
    display: flex;
    justify-content: center;
    
    p{
      background-color: ${p => p.theme.colors.bugattiRed};
      color: ${p => p.theme.colors.onboardingText};
      font-family: 'ZwoLF Bold';
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding: 0 10px;
    }
  }
`;