import styled from 'styled-components';

export default styled.button`
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: ${p => p.theme.colors.foreground};
  mask-position: center center;
  mask-size: cover;
  border: none;
  outline: none;

  &:disabled {
    cursor: unset;
    pointer-events: none;
    opacity: 0.5;
  }

  &.icon-hamburger {
    mask-image: url('./icons/hamburger.svg');
  }

  &.icon-visible {
    mask-image: url('./icons/visible.svg');
  }

  &.icon-edit {
    mask-image: url('./icons/edit.svg');
  }

  &.icon-garage {
    mask-image: url('./icons/garage.svg');
  }

  &.icon-car-lights {
    mask-image: url('./icons/car-lights.svg');
  }

  &.icon-car-doors {
    mask-image: url('./icons/car-doors.svg');
  }

  &.icon-close {
    mask-image: url('./icons/close.svg');
  }

  &.icon-settings {
    mask-image: url('./icons/settings.svg');
  }

  &.icon-camera-controller-popup {
    mask-image: url('./icons/camera-controller-popup.svg');
  }

  &.icon-camera-controller-tablet {
    mask-image: url('./icons/camera-controller-tablet.svg');
  }

  &.icon-bugatti {
    mask-image: url('./icons/EttoreBugatti.svg');
  }

  &.icon-arrow-right-thin {
    mask-image: url('./icons/arrow-right-thin.svg');
  }

  &.icon-arrow-down-thin {
    mask-image: url('./icons/arrow-down-thin.svg');
  }

  &.icon-chevron-left-narrow-thin {
    mask-image: url('./icons/chevron-left-narrow-thin.svg');
  }

  &.icon-chevron-right-narrow-thin {
    mask-image: url('./icons/chevron-right-narrow-thin.svg');
  }

  &.icon-chevron-left-narrow-thinner {
    mask-image: url('./icons/chevron-left-narrow-thinner.svg');
  }

  &.icon-chevron-right-narrow-thinner {
    mask-image: url('./icons/chevron-right-narrow-thinner.svg');
  }

  &.icon-chevron-up-thicker {
    mask-image: url('./icons/chevron-up-thicker.svg');
  }

  &.icon-plus {
    mask-image: url('./icons/plus.svg');
  }

  &.icon-minus {
    mask-image: url('./icons/minus.svg');
  }

  &.icon-delete {
    mask-image: url('./icons/delete.svg');
  }

  &.icon-info {
    mask-image: url('./icons/info.svg');
  }

  &.icon-play {
    mask-image: url('./icons/play.svg');
  }

  &.icon-pause {
    mask-image: url('./icons/pause.svg');
  }

  &.icon-lock {
    mask-image: url('./icons/lock.svg');
  }

  &.icon-unlock {
    mask-image: url('./icons/unlock.svg');
  }

`;
