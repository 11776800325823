import * as React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  visible: boolean;
  progress: number;
};
const ProgressBar: React.FunctionComponent<Props> = props => {
  const { className, visible, progress } = props;

  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');

  return (
    <div className={classNames}>
      <span style={{ width: `${progress}%` }}></span>
    </div>
  );
};
export default styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  margin: 0 0 auto 0;
  width: 100vw;
  height: 4px;
  background-color: ${p => p.theme.colors.onboardingBackground};
  z-index: 10;

  &.hidden {
    transform: translateY(-250px);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0px);
    opacity: 1;
  }

  > span {
    display: block;
    margin-top: 3px;
    height: 1px;
    background-color: ${p => p.theme.colors.onboardingText};
    position: relative;
    overflow: hidden;
  }
`;
