import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from './Toast';

type ServerResponse<T> = {
  isLoading: boolean;
  error: Error | null;
  item: T | undefined;
};

type ExtendedInit = {
  delayLoading?: boolean;
} & RequestInit;

export function useItemWithFetch<T>(input: RequestInfo, init?: ExtendedInit, ...deps: any[]): ServerResponse<T> {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [item, setItems] = React.useState<T>();

  const { delayLoading, body, credentials, headers, integrity, keepalive, method, mode, referrer, window } = init || {};

  useEffect(() => {
    if (delayLoading) {
      return;
    }

    setIsLoading(true);
    const loadData = async () => {
      const fetchInit = { body, credentials, headers, integrity, keepalive, method, mode, referrer, window };
      const response = await fetch(input, fetchInit);
      const jsonContent = await response.json();
      if (!response.ok) {
        throw new Error(jsonContent.message || 'Unknown Error');
      }
      setItems(jsonContent as T);
    };

    loadData()
      .catch(e => {
        console.error(e);
        setError(e);
        const message = `An error occurred: ${e.name} - ${e.message}`;
        toast.error(message, toastOptions);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, body, credentials, headers, integrity, keepalive, method, mode, referrer, window, delayLoading, ...deps]);

  return {
    isLoading,
    error,
    item,
  };
}

export function useItemFromUrl<T>(url: string, ...deps: any[]): ServerResponse<T> {
  return useItemWithFetch(url, undefined, ...deps);
}
