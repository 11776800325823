import * as React from 'react';
import styled from 'styled-components';

import { AppConfiguration } from '../app/AppConfiguration';
import ActionIconButton from './ActionIconButton';
import WithBlendedUnderlay from './WithBlendedUnderlay';

type Props = {
  className?: string;
  visible: boolean;
  imageUrl: string;
  onClose: () => void;
};
const ImageViewer: React.FunctionComponent<Props> = props => {
  const { className, imageUrl, visible, onClose } = props;
  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');
  const fullImageUrl = `url(${AppConfiguration.baseServerUrl}${imageUrl})`;
  return (
    <div className={classNames} style={{ backgroundImage: fullImageUrl }}>
      <WithBlendedUnderlay>
        <span className='button-on-circle'>
          <ActionIconButton className="close-button icon-close" onClick={onClose} />
        </span>
      </WithBlendedUnderlay>
    </div>
  );
};
export default styled(ImageViewer)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 75ms ease-in-out;

  &.hidden {
    transform: translateX(100%);
    opacity: 0;
    transform-origin: right;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out; 
  }

  &.visible {
    transform: translateX(0);
    opacity: 1;
    transform-origin: right;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out; 
  }

  span.button-on-circle {
    position: fixed;
    top: 12px;
    right: 10px;
    width: 45px;
    height: 45px;
    margin-left: 10px;
    border-radius: 100px;
    background-color: ${p => p.theme.colors.onboardingText};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .close-button {
      width: 35px;
      height: 35px;
      background-color: ${p => p.theme.colors.onboardingBackground};
    }
  }
`;
