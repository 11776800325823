import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { ConfigurationCodeResponse } from '../../shared/model/ConfigurationCodeResponse';
import ActionButton from './ActionButton';
import ActionIconButton from './ActionIconButton';
import { useGenerateConfigurationCodeFromActiveSlot } from './activeSlot';
import CodeWidgetErrorMessage from './CodeWidgetErrorMessage';
import LoadingSpinner from './LoadingSpinner';

type Props = {
  className?: string;
  onCloseCallback: () => void;
}

const GenerateCodeWidget: React.FunctionComponent<Props> = props => {
  const { className, onCloseCallback } = props;

  const [isGenerating, setIsGenerating] = React.useState<boolean>(true);
  const [isCodeCopied, setIsCodeCopied] = React.useState<boolean>(false);
  const [isCodeValid, setIsCodeValid] = React.useState<boolean>(false);
  const [code, setCode] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const { customerId } = useParams() as {customerId:string};

  const onConfigurationCodeReceived = (codeResponse: ConfigurationCodeResponse) => {
    setCode(codeResponse.code);
    setErrorMessage('');
    setIsGenerating(false);
    setIsCodeValid(true);
    setIsCodeCopied(false);
  }

  const onConfigurationCodeGenerationFailed = (errorMessage: string) =>
  {
    setCode('');
    setErrorMessage(errorMessage);
    setIsGenerating(false);
    setIsCodeValid(false);
    setIsCodeCopied(false);
  }

  useGenerateConfigurationCodeFromActiveSlot(customerId, onConfigurationCodeReceived, onConfigurationCodeGenerationFailed);

  const onClose = () => {
    onCloseCallback();
  }

  async function onCopyToClipboard() {
    await navigator.clipboard.writeText(code);
    setIsCodeCopied(true);
  }

  const formClassName = !isGenerating ? 'form-wrapper isVisible' : 'form-wrapper isHidden';

  return (
    <div className={className}>
      <header className="dark-navigation">
        <h1>
          Save this configuration as code
          <div className="button-on-circle">
            <ActionIconButton className="icon-close" onClick={onClose} />
          </div>
        </h1>
      </header>
      <div className={formClassName}>
        {!isCodeCopied && isCodeValid &&
          <><form>
            <div>
              <input value={code} readOnly type="text" />
            </div>
          </form>
            <ActionButton onClick={onCopyToClipboard}>Copy to clipboard</ActionButton></>
        }
        {
          isCodeCopied && 
          <div className='copied-message'>
            <span className="checkmark" />
            <div>copied to your clipboard</div>
          </div>
        }
        {
          !isCodeValid &&
          <CodeWidgetErrorMessage message={errorMessage}/>
        }
      </div>
      <LoadingSpinner visible={isGenerating} message="one moment please" additionalMessage=""/>
    </div>
  )
}

export default styled(GenerateCodeWidget)`
position: absolute;
top: 0;
left: 0;
right: 0;
height: 100%;
background-color: ${p => p.theme.colors.darkTransparentBackground};
backdrop-filter: blur(8px);
pointer-events: all;

header.dark-navigation {
  h1 {
    color: ${p => p.theme.colors.onboardingText};
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    font-family: 'ZwoLF Bold';
    font-size: 28px;
    line-height: 69px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;

    .button-on-circle {
      position: fixed;
      top:8px;
      right: 8px;
      width: 45px;
      height: 45px;
      margin-left: 10px;
      border-radius: 100px;
      background-color: ${p => p.theme.colors.onboardingText};
      display: flex;
      justify-content: center;
      align-items: center;
      z-index:1;
      
      .close-button {
        width: 35px;
        height: 35px;
        background-color: ${p => p.theme.colors.onboardingBackground};
      }
    }
  }

  div {
    width: 540px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    img {
      top: -6px;
      position: relative;
      width: 48px;
      height: 48px;
    }
  }
}

.form-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 43px;
  padding: 55px;
  color: ${p => p.theme.colors.onboardingText};
  opacity: 0;

  form {
    input{
      width: 578px;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 0;
      text-transform: uppercase;
      flex-grow:1;
      border-bottom: 2px solid ${p => p.theme.colors.onboardingText};
      line-height: 63px;
      color: ${p => p.theme.colors.onboardingText};
      font-family: 'ZwoLF Bold';
      font-size: 32px;
      letter-spacing: 1px;
      background: none;
    }

    label {
      padding: 0 8px;
      font-family: 'ZwoLF Bold';
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  } 

  button {
  padding: 0;
  margin: 0;
  font-size: 24px;
  width: 578px;
  line-height: 63px;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  cursor: pointer;
  }

  .copied-message{
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkmark{
      width: 100px;
      height:100px;
      mask-image: url('./icons/checkmark.svg');
      mask-size: 100% 100%;
      background-color: ${p => p.theme.colors.onboardingText};
    }
  }

  &.isVisible {
    pointer-events: all;
    opacity: 1;
    transition: opacity 1000ms;
  }

  &.isHidden {
    pointer-events: none;
    opacity: 0;
    transition: opacity 1000ms;
  }
} 

${LoadingSpinner}
{
  top: 0px;
}
`;