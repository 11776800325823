import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { CustomerResponse, CarModel } from '../../../shared/model/RawModel';
import { AppConfiguration } from '../../app/AppConfiguration';
import ActionButton from '../../components/ActionButton';
import { Page } from '../../components/Page';
import CarRender from '../../components/CarRender';
import { useItemFromUrl } from '../../components/useItemsFromServer';
import { toastOptions } from '../../components/Toast';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

type Props = {
  className?: string;
};

const createCustomer = async (
  name: string,
  countryVersion: string,
  history: NavigateFunction,
) => {
  try {
    const response = await fetch(`${AppConfiguration.baseServerUrl}/api/customers`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        countryVersion,
      }),
    });
    const jsonBody = await response.json();

    if (!response.ok) {
      throw new Error(jsonBody.message);
    }

    const createdCustomerResponse        = jsonBody as CustomerResponse;
    const createdCustomer                = createdCustomerResponse.customer;
    let configurationUrl:string          = `/model-selection/${createdCustomer.id}/${countryVersion}/Garage`;

    //for each configuration
    for(let i=0; i<createdCustomerResponse.configurationCountries.length; i++)
    {
      //is there a configuration of the wanted country?
      if(createdCustomerResponse.configurationCountries[i]===countryVersion)
      {
        configurationUrl = `/features/${createdCustomer.id}/${createdCustomer.configurationIds[i]}/${countryVersion}`;
        break;
      }
    }

    history(configurationUrl);

  } catch (e) {
    console.error(e);
    const message = `An error occurred: ${(e as any).name} - ${(e as any).message}`;
    toast.error(message, toastOptions);
  }
};

/**
 * Page to create a new customer
 */
const CustomerCreationPage: React.FunctionComponent<Props> = props => {
  const customerInputRef = React.useRef<HTMLInputElement | null>(null);
  const countrySelectRef = React.useRef<HTMLSelectElement | null>(null);
  const [isFormValid, setFormValid] = React.useState<boolean>(false);

  const history = useNavigate();


  React.useEffect(() => {

    setTimeout(() => { customerInputRef && customerInputRef.current && customerInputRef.current.select(); }, 500);

  }, []);
  const { item: carModels } = useItemFromUrl<ReadonlyArray<CarModel>>(`${AppConfiguration.baseServerUrl}/api/models`);
  const defaultCarModel = carModels ? carModels.length > 0 ? carModels[0].id : undefined : undefined;

  const validateForm = () => {
    let isValid = false;

    if (customerInputRef.current && countrySelectRef.current) {
      isValid = customerInputRef.current.value !== '' && countrySelectRef.current.value !== 'default';
    }

    setFormValid(isValid);
  }

  const submitForm = () => {
    if (customerInputRef.current && countrySelectRef.current) {
      return createCustomer(customerInputRef.current.value, countrySelectRef.current.value, history);
    }
  };

  return (
    <Page className={props.className}>
      {defaultCarModel && <CarRender carModelId={defaultCarModel} imageType="HighResStatic"/>}
      <div className="form-wrapper">
        <form className="customer-creation">
          <span className="logo-container">
            <div className="bugatti-logo"></div>
          </span>
          <span className="input-with-label">
            <label>Customer name</label>
            <input ref={customerInputRef} type="text" onChange={() => { validateForm() }} />
          </span>

          <span className="input-with-label">
            <label>Country version</label>
            <span className="select-wrapper">
              <select ref={countrySelectRef} onChange={() => { validateForm() }}>
                <option value="default">Please Select</option>
                <option value="Austria">Austria</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Belgium">Belgium</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Canada">Canada</option>
                <option value="Czech_Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Italy">Italy</option>
                <option value="Japan">Japan</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Monaco">Monaco</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Oman">Oman</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Qatar">Qatar</option>
                <option value="Romania">Romania</option>
                <option value="Saudi_Arabia">Saudi Arabia</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Spain">Spain</option>
                <option value="Switzerland">Switzerland</option>
                <option value="UAE">UAE</option>
                <option value="UK">UK</option>
                <option value="USA">USA</option>
              </select>
            </span>
          </span>
        </form>
        <ActionButton disabled={!isFormValid} onClick={submitForm}>Proceed</ActionButton>
      </div>
    </Page>
  );
};

export type CustomerCreationPageProps = Props;

export default styled(CustomerCreationPage as any)`
  
  > ${CarRender} {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .form-wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${p => p.theme.colors.onboardingText};
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(8px);
    z-index: 1;

    form {
      max-width: 764px;
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 64px;
        display: flex;
        flex-direction: column;
      }

      span.logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: 240px;

        .bugatti-logo {
          align-self: center;
          content: ' ';
          width: 116px;
          height: 59px;
          background: url('./images/bugatti-logo@2x.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      input,
      select,
      button {
        padding: 0;
        margin: 0;
        line-height: 63px;
        min-width: 764px;
        border: none;
        border-radius: 0;
        text-transform: uppercase;
        z-index: 1;
      }

      input {
        text-transform: none !important;
      }

      input,
      select {
        padding: 0 8px;
        color: ${p => p.theme.colors.onboardingText};
        font-family: 'BUGATTIText Bold';
        font-size: 32px;
        letter-spacing: 1px;
        border-bottom: 2px solid ${p => p.theme.colors.onboardingText};
        background: none;
      }

      label {
        padding: 0 8px;
        font-family: 'BUGATTIText Bold';
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        opacity: 0.5;
      }

      input {
      }

      select {
        height: 63px;
        text-align-last: left;
      }

      option {
        color:  ${p => p.theme.colors.onboardingBackground};
        appearance: auto;
        direction: ltr;
      }

      span.select-wrapper {
        display: flex;
        flex-direction: row;

        select {
          min-width: 764px;
        }

        &:after {
          content: ' ';
          position: relative;
          right: 4em;
          min-width: 4em;
          height: 4em;
          mask-image: url('./icons/chevron-down-thicker.svg');
          mask-repeat: no-repeat;
          mask-position: 50% 50%;
          mask-size: 50% 50%;
          background-color: ${p => p.theme.colors.onboardingText};
        }

        option {
        }

        .chevron-area {
          content: ' ';
          position: relative;
          right: 0;
          width: 61px;
          height: 63px;
          border-bottom: 2px solid ${p => p.theme.colors.onboardingText};

          .chevron {
            color: white;
            content: ' ';
            width: 61px;
            height: 61px;
            background: ${p => p.theme.colors.onboardingText};
            mask-image: url('./icons/chevron-down.svg');
            mask-repeat: no-repeat;
            mask-position: 50% 50%;
          }
        }
      }
    }

    button {
      padding: 0;
      margin: 0;
      font-size: 24px;
      min-width: 764px;
      line-height: 63px;
      border: none;
      border-radius: 0;
      text-transform: uppercase;
      opacity: 1;
      transition: opacity 2000ms, background-color 2000ms, color 1000ms;
      
      &:disabled {
        color: ${p => p.theme.colors.onboardingText};
        background-color: rgba(255,255,255,0.2);
        opacity: 0.4;
        transition: opacity 2000ms, background-color 2000ms, color 1000ms;

      }
    }
  }
`;
