import React from 'react';
import { Route } from 'react-router-dom';

import CustomerCreationPage from '../pages/customer-creation/CustomerCreationPage';
import FeatureListPage from '../pages/feature-list/FeatureListPage';
import ModelSelectionPage from '../pages/model-selection/ModelSelectionPage';
import AnimatedSwitch from '../transitions/AnimatedSwitch';

const AppRoutes: React.FunctionComponent = () => {
  return (
    <>
      <AnimatedSwitch transitionTimeout={750} animationName="new-customer">
        <Route path="/" element={<CustomerCreationPage />}/>
      </AnimatedSwitch>
      <AnimatedSwitch transitionTimeout={1000} animationName="fade-in">
        <Route path="/model-selection/:customerId/:countryVersion/:focusMode" element={ <ModelSelectionPage />}/>
      </AnimatedSwitch>
      <AnimatedSwitch transitionTimeout={1000} animationName="fade-in">
        <Route path="/features/:customerId/:customerConfigurationId/:countryVersion" element={<FeatureListPage />}/>
      </AnimatedSwitch>
    </>
  );
};

export default AppRoutes;
