import { useEffect, useState } from 'react';

const defaults = {
  horizontal: true,
  vertical: false,
  activationDistance: 10,
};

export const useDragScroll = (
  scrollContainer: HTMLElement | null,
  options: {
    horizontal: boolean;
    vertical: boolean;
    activationDistance: number;
  } = defaults
) => {
  const [scrolling, setScrolling] = useState<boolean>(false);

  useEffect(() => {
    let scrollLeft = 0;
    let scrollTop = 0;

    let lastClientX = 0;
    let lastClientY = 0;
    let velocityX = 0;
    let amplitudeX = 0;
    let velocityY = 0;
    let amplitudeY = 0;
    let lastTimestamp = 0;
    let pressed = false;
    let started = false;

    let lastContainer: HTMLElement | null = null;

    function resetVelocity() {
      velocityX = 0;
      amplitudeX = 0;
      velocityY = 0;
      amplitudeY = 0;
      lastTimestamp = Date.now();
    }

    function saveVelocity(newClientX: number, newClientY: number) {
      var now, elapsed, v;

      now = Date.now();
      elapsed = now - lastTimestamp;
      lastTimestamp = now;

      v = (10 * (newClientX - lastClientX)) / (1 + elapsed);
      velocityX = 0.8 * v + 0.2 * velocityX;
      lastClientX = newClientX;

      v = (10 * (newClientY - lastClientY)) / (1 + elapsed);
      velocityY = 0.8 * v + 0.2 * velocityY;
      lastClientY = newClientY;
    }

    function autoScroll() {
      if (pressed) return;

      var elapsed;
      if (amplitudeX || amplitudeY) {
        elapsed = Date.now() - lastTimestamp;
        var deltaX = amplitudeX * Math.exp(-elapsed / 325);
        var deltaY = amplitudeY * Math.exp(-elapsed / 325);

        scroll(deltaX, deltaY);

        if (Math.abs(deltaX) > 0.5 || Math.abs(deltaY) > 0.5) {
          requestAnimationFrame(autoScroll);
        } else {
          setScrolling(false);
        }
      }
    }

    function scroll(deltaX: number, deltaY: number) {
      const { horizontal, vertical } = options;
      const container = lastContainer;
      if (!container) return;

      if (horizontal) {
        container.scrollLeft -= deltaX;
      }
      if (vertical) {
        container.scrollTop -= deltaY;
      }

      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
    }

    function processClick(newClientX: number, newClientY: number) {
      const container = lastContainer;
      if (!container) return;

      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
      lastClientX = newClientX;
      lastClientY = newClientY;
      pressed = true;
      window.addEventListener('mousemove', onMouseMove);
    }

    function processStart() {
      started = true;
      resetVelocity();
    }

    // Process native scroll (scrollbar, mobile scroll)
    function processScroll() {
      if (!started) {
        processStart();
      }
    }

    // Process non-native scroll
    function processMove(newClientX: number, newClientY: number) {
      const { horizontal, vertical, activationDistance } = options;

      const container = lastContainer;
      if (!container) return;

      if (!started) {
        const movedX = Math.abs(newClientX - lastClientX);
        const movedY = Math.abs(newClientY - lastClientY);

        if ((horizontal && movedX > activationDistance) || (vertical && movedY > activationDistance)) {
          lastClientX = newClientX;
          lastClientY = newClientY;
          processStart();
        }
      } else {
        scroll(newClientX - lastClientX, newClientY - lastClientY);
        saveVelocity(newClientX, newClientY);
      }
    }

    function processEnd() {
      pressed = false;
      started = false;
      //auto scroll
      if (Math.abs(velocityX) > 10 || Math.abs(velocityY)) {
        amplitudeX = 0.8 * velocityX;
        amplitudeY = 0.8 * velocityY;
        lastTimestamp = Date.now();
        requestAnimationFrame(autoScroll);
      } else {
        setScrolling(false);
      }
      window.addEventListener('mousemove', onMouseMove);
    }

    const onEndScroll = () => {
      setScrolling(false);
      if (!pressed && started) {
        processEnd();
      }
    };

    const onScroll = (e: Event) => {
      const container = lastContainer;
      if (!container) return;

      // Ignore the internal scrolls
      if ((container && container.scrollLeft !== scrollLeft) || container.scrollTop !== scrollTop) {
        setScrolling(true);
        processScroll();
        onEndScroll();
      }
    };

    const onTouchStart = (e: TouchEvent) => {
      const touch = e.touches[0];
      processClick(touch.clientX, touch.clientY);
      e.stopPropagation();
    };

    const onTouchEnd = (e: TouchEvent) => {
      if (pressed) {
        if (started && !scrolling) {
          processEnd();
        } else {
          pressed = false;
        }
      }
    };

    const onTouchMove = (e: TouchEvent) => {
      if (pressed) {
        const touch = e.touches[0];
        if (touch) {
          processMove(touch.clientX, touch.clientY);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      processClick(e.clientX, e.clientY);
      e.preventDefault();
      e.stopPropagation();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (pressed) {
        processMove(e.clientX, e.clientY);
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (pressed) {
        if (started) {
          processEnd();
        } else {
          pressed = false;
        }
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchmove', onTouchMove, { passive: false });
    window.addEventListener('touchend', onTouchEnd);

    if (scrollContainer) {
      lastContainer = scrollContainer;
      // due to https://github.com/facebook/react/issues/9809#issuecomment-414072263
      lastContainer.addEventListener('touchstart', onTouchStart, { passive: false });
      lastContainer.addEventListener('mousedown', onMouseDown, { passive: false });
      lastContainer.addEventListener('scroll', onScroll, { passive: false });
      processEnd();
    }

    return () => {
      if (lastContainer && lastContainer !== scrollContainer) {
        lastContainer.removeEventListener('touchstart', onTouchStart);
        lastContainer.removeEventListener('mousedown', onMouseDown);
        lastContainer.removeEventListener('scroll', onScroll);
        lastContainer = null;
      }
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [scrollContainer, options, scrolling]);

  return {
    scrolling
  }
};
