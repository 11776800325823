import * as React from 'react';
import styled from 'styled-components';

import { FeatureSetObject } from '../../../../../shared/model/FeatureSet';
import { Feature, ID, Option, CustomerConfiguration } from '../../../../../shared/model/RawModel';
import ActionButton from '../../../../components/ActionButton';
import ActionIconButton from '../../../../components/ActionIconButton';
import ImageViewer from '../../../../components/ImageViewer';
import SidebarDropdown from './SidebarDropdown';
import SidebarToggle from './SidebarToggle';
import { actionExportPDF } from '../../../../components/notifyServer';
import { useParams } from 'react-router';
import GenerateCodeWidget from '../../../../components/GenerateCodeWidget';
import ExportVideoWidget from '../../../../components/ExportVideoWidget';


type Props = {
  className?: string;
  visible: boolean;
  activeFeatureSet?: FeatureSetObject;
  onClose: () => void;
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  onResetFeatureToPreset: (featureId: ID<Feature>) => void;
  hasPreviousFeature?: boolean;
  hasNextFeature?: boolean;
  onSelectPreviousFeature?: () => void;
  onSelectNextFeature?: () => void;
};
const SidepanelLayout: React.FunctionComponent<Props> = props => {
  const {
    visible,
    activeFeatureSet,
    onSelectOption,
    hasPreviousFeature,
    onSelectPreviousFeature,
  } = props;

  React.useEffect(() => {
    if (!visible) {
      setImageViewerVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const [isImageViewerVisible, setImageViewerVisible] = React.useState<boolean>(false);
  const [previewImageUrl, setImagePreviewUrl] = React.useState<string>('');
  const [isSavePdfActive, setSavePdfActive] = React.useState<Boolean>(true);
  const [isGenerateCodeWidgetVisible, setIsGenerateCodeWidgetVisible] = React.useState<boolean>(false);
  const [isExportVideoWidgetVisible, setIsExportVideoWidgetVisible] = React.useState<boolean>(false);

  const additionalFeatureSet = activeFeatureSet;
  const getFeature = (featureId: ID<Feature>) => {
    return additionalFeatureSet ? additionalFeatureSet.featureObjects.find(f => f.id.includes(featureId)) : undefined;
  };
  const openImageViewer = (imageUrl: string) => {
    setImagePreviewUrl(imageUrl);
    setImageViewerVisible(true);
  };
  const closeImageViewer = () => {
    setImageViewerVisible(false);
  };

  const { customerConfigurationId } = useParams() as {customerConfigurationId:ID<CustomerConfiguration>};

  
  const submitForm = () => {
    setSavePdfActive(false);
    actionExportPDF(customerConfigurationId, (isSuccessful:boolean) => {
      setSavePdfActive(true);
    });
  };

  const onOpenGenerateCodeWidget = () =>
  {
    setIsGenerateCodeWidgetVisible(true);
  }

  const onCloseGenerateCodeWidget = () => {
    setIsGenerateCodeWidgetVisible(false);
  }

  const onOpenExportVideoWidget = () =>
  {
    setIsExportVideoWidgetVisible(true);
  }

  const onCloseExportVideoWidget = () => {
    setIsExportVideoWidgetVisible(false);
  }


  const visibleClassName = visible ? 'visible' : 'hidden';
  const savePdfClassName = isSavePdfActive ? '' : 'disabled';
  const movePanelForwardClassName = isGenerateCodeWidgetVisible || isExportVideoWidgetVisible ? 'move-forward': '';

  let isBasePriceVisible = false;
  const basePriceFeature = getFeature('AdditionalOptions_BasePrice');
  if(basePriceFeature && basePriceFeature.optionSetObjects[0].options.length>1)
    isBasePriceVisible = true;

  return (
    <div className={`${props.className} side-panel-screen  ${visibleClassName} ${movePanelForwardClassName}`}>
      {visible && <div className="outside-overlay" onClick={props.onClose} />}
      <div className={`side-panel right-aligned`}>
        <header>
          <ActionIconButton
            className="icon-chevron-left-narrow-thin"
            onClick={onSelectPreviousFeature}
            disabled={!hasPreviousFeature}
          />
          <h2>{activeFeatureSet && activeFeatureSet.title}</h2>
        </header>
        <div className="option-lists">
          <section className="execution-options">
            <h3> Execution Options</h3>
            <ul>
              <SidebarDropdown
                feature={getFeature('AdditionalOptions_ManualLanguage')}
                onSelection={onSelectOption}
              />
              <SidebarDropdown
                feature={getFeature('AdditionalOptions_InstrumentLanguage')}
                onSelection={onSelectOption}
              />
              <SidebarDropdown
                feature={getFeature('AdditionalOptions_FireExtinguisher')}
                onSelection={onSelectOption}
              />
              <SidebarDropdown
                feature={getFeature('AdditionalOptions_StoneShipGuard')}
                onSelection={onSelectOption}
              />
              <SidebarToggle
                feature={getFeature('AdditionalOptions_WetTires')}
                onSelection={onSelectOption}
                onOpenImageViewer={openImageViewer}
              />
              <SidebarToggle
                feature={getFeature('AdditionalOptions_TrackPack')}
                onSelection={onSelectOption}
                onOpenImageViewer={openImageViewer}
              />
              {<SidebarToggle
                feature={getFeature('AdditionalOptions_CollectorsPack')}
                onSelection={onSelectOption}
                onOpenImageViewer={openImageViewer}
              />}
              <SidebarToggle
                feature={getFeature('AdditionalOptions_PersonalAttirePack')}
                onSelection={onSelectOption}
                onOpenImageViewer={openImageViewer}
              />
              <SidebarToggle
                feature={getFeature('AdditionalOptions_RacingSimulator')}
                onSelection={onSelectOption}
                onOpenImageViewer={openImageViewer}
              />
            </ul>
          </section>
          <section className="pricing-and-delivery">
            <h3> Pricing and Delivery</h3>
            <ul>
              <SidebarToggle
                feature={getFeature('AdditionalOptions_ExtendedWarranty')}
                onSelection={onSelectOption}
                onOpenImageViewer={openImageViewer}
              />
              <SidebarDropdown
                feature={getFeature('AdditionalOptions_ExtendedService')}
                onSelection={onSelectOption}
              />
              <SidebarDropdown feature={getFeature('AdditionalOptions_Shipment')} onSelection={onSelectOption} />
              {isBasePriceVisible && 
                <SidebarDropdown feature={getFeature('AdditionalOptions_BasePrice')} onSelection={onSelectOption} />
              }
            </ul>
          </section>
        </div>
        <div className='button-wrapper'>
          <ActionButton onClick={onOpenGenerateCodeWidget}>Generate Code</ActionButton>
          {/*<ActionButton onClick={onOpenExportVideoWidget}>Export Video</ActionButton>*/}
          <ActionButton className={savePdfClassName} onClick={submitForm}>Export PDF</ActionButton>
        </div>
      </div>
      <ImageViewer
        className="image-viewer"
        visible={isImageViewerVisible}
        imageUrl={previewImageUrl}
        onClose={closeImageViewer}
      />
      {isGenerateCodeWidgetVisible && <GenerateCodeWidget onCloseCallback={onCloseGenerateCodeWidget}/>}
      {isExportVideoWidgetVisible && <ExportVideoWidget onCloseCallback={onCloseExportVideoWidget}/>}
    </div>
  );
};
export default styled(SidepanelLayout)`
  height: 100vh;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  /* workaround to hide app menu when generate code or export video widget is visible */
  &.move-forward{
    z-index: 1;
  }

  ${GenerateCodeWidget} {
    z-index: 1;
  }
  
  ${ExportVideoWidget} {
    z-index: 1;
  }

  .side-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 388px;
    padding-bottom: 30px;
    color: ${p => p.theme.colors.onboardingText};
    background-color: ${p => p.theme.colors.darkTransparentBackground};
    backdrop-filter: blur(8px);
    transform-origin: right;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2,
    h3 {
      text-transform: uppercase;
    }

    h2 {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    h3 {
      margin-top: 36px;
      margin-bottom: 0;
      font-size: 11px;
      letter-spacing: 2px;
      line-height: 30px;
      border-bottom: 1px solid ${p => p.theme.colors.onboardingBackgroundBright};
    }

    h2,
    button {
      margin-right: 30px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    button,
    .option-lists {
      margin-left: 30px;
    }

    header {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        margin: 0 -24px 0 12px;
        width: 60px;
        height: 60px;
        background-color: ${p => p.theme.colors.onboardingText};
      }

      h2 {
        flex-grow: 1;
        font-size: 28px;
        font-family: 'ZwoLF SemiBold';
        letter-spacing: 1px;
        text-align: center;
        padding-left: 2px;
      }
    }
    .button-wrapper{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .image-viewer {
    z-index: 2;
  }

  ${ActionButton} {
    opacity: 1;
    transition: opacity 2000ms, background-color 2000ms, color 1000ms;
    
    &.disabled {
      pointer-events:none;
      color: ${p => p.theme.colors.blend};
      background-color: rgba(255,255,255,0.2);
      opacity: 0.4;
      transition: opacity 500ms, background-color 500ms, color 500ms;
    }
  }
`;
