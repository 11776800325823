import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { CarModel, ID, Preset } from '../../../shared/model/RawModel';
import { AppConfiguration } from '../../app/AppConfiguration';
import { useItemFromUrl } from '../../components/useItemsFromServer';
import PresetItem from '../preset-selection/PresetItem';
import { toastOptions } from '../../components/Toast';
import { toast } from 'react-toastify';

type Props = {
  className?: string;
  model: CarModel;
  onPresetSelected: (presetId: ID<Preset>) => void;
};

const CarModelItem: React.FunctionComponent<Props> = props => {
  const model = props.model;
  // const themeSelection = useContext(ThemeSelectionContext);

  const modelId = model.id;

  const [activePreset, setActivePreset] = useState<Preset | null>(null);

  const { item: presets } = useItemFromUrl<ReadonlyArray<Preset>>(
    `${AppConfiguration.baseServerUrl}/api/models/${modelId}/presets`
  );

  useEffect(() => {
    if (!modelId)
      toast.error(`An error occurred: Invalid modelId {modelId}`, toastOptions);

  }, [modelId])

  if (!modelId) return <div />;

  if (activePreset === null && presets && presets.length > 0) {
    setActivePreset(presets[0]);
  }

  //HACK: SurMesur Logo
  let isSurMEsur = modelId==='ChironPurSportGrandPrix';

  return (
    <div className={props.className}>
      <section className="active-preset">
        {
          activePreset && (
            <PresetItem
              preset={activePreset}
              onPresetSelected={props.onPresetSelected}
              isFixedWidth={false}
              isHighResPicture={true}
            />
          )
        }
      </section>
      <div className='model-presets'>
        { isSurMEsur && ( <img src={'images/SurMesureLabelRGB.png'} className={'surmesur'} alt="" /> )}
        <section className='model-description'>
          <h2>{model.title}</h2>
          <p dangerouslySetInnerHTML={{__html: model.description}}>
          </p>
        </section>
        <section className="presets-container">
          <ol>
            {presets &&
              presets.map((preset, index) => (
                <li className={model.id==='ChironSport'?'chsct':''} key={index}>
                  <PresetItem
                    preset={preset}
                    onPresetSelected={presetId =>
                      setActivePreset(preset)}
                    isFixedWidth={true}
                    isHighResPicture={false}
                  />
                </li>
              ))}
          </ol>
        </section>
      </div>
    </div>
  );
};
export default styled(CarModelItem)`
  position: relative;
  
  section.active-preset,
  section.active-preset > section,
  section.active-preset > section > button.car-button,
  section.active-preset > section > button.car-button > .car-render,
  section.active-preset > section > button.car-button > .car-render > span {
    content: " ";
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0;
    right:0;
    left:0;
    bottom:0;
    padding:0;
    overflow: hidden;
  }

  #car-render.full-preview {
    content: " ";
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0;
    right:0;
    left:0;
    bottom:0;
    padding:0;
    overflow: hidden;
  }

  .model-presets {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    img.surmesur {
      position:absolute;
      top:0;
      right:180px;
      width:7%;
    }

    section.model-description {
      align-self: flex-start;
      padding-top: 70px;
      padding-left: 70px;
      width: 850px;
      text-align: left;
      display: flex;
      flex-direction: column;

      > h2 {
        margin: 0;
        font-size: 42px;
        font-family: 'ZwoLF SemiBold';
        text-transform: uppercase;
      }

      > p {
        font-size: 18px;
        letter-spacing: 0.3px;
        line-height: 24px;
        width: 580px;
        margin: 0.5em 0 0 0;
      }
    }

    /* UGLY HACK: create margin for Color&Trim */
    section.presets-container > ol > li.chsct:nth-child(3)
    {
      margin-left: 1.5em;
    }
    

    section.presets-container {
      width: 100vw;
      height: 160px;
      left: 0;
      top: 7vh;
      bottom: 7vh;
      margin: 0;
      padding: 0;
      pointer-events: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      background-color:rgba(220,220,220,0.4);
      
      ol {
        margin: 0 10px;
        padding: 0;
        list-style: none;
        scrollbar-width: none;
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        li {
          padding: 10 !important;
          background: none;
          border: none;
        }
      }
    }
  }

  `;
