import * as React from 'react';
import styled from 'styled-components';
import GarageWidget from '../garage/GarageWidget';
import { ID, CustomerConfiguration, CarModel } from '../../../shared/model/RawModel';
import CarRender from '../../components/CarRender';

type Props = {
    className?: string;
    customerConfigurations: ReadonlyArray<CustomerConfiguration>;
    carModels: ReadonlyArray<CarModel>;
    onActiveConfigurationSelected: (customerConfigurationId: ID<CustomerConfiguration>) => void;
    onConfigurationDelete: (customerConfigurationId: ID<CustomerConfiguration>) => void;
};

const GarageItem: React.FunctionComponent<Props> = props => {
    const { className, customerConfigurations, carModels, onActiveConfigurationSelected, onConfigurationDelete } = props;
    const [activeConfiguration, setActiveConfiguration] = React.useState<CustomerConfiguration | undefined>(undefined);
    const [activeCarModelName, setActiveCarModelName] = React.useState<string>('');
    const [lastModifiedAtText, setLastModifiedAtText] = React.useState<string>('');

    const onConfigurationSelected = (customerConfigurationId: ID<CustomerConfiguration>) => {
        const newActiveConfiguration = customerConfigurations.find(cc => cc.id === customerConfigurationId);
        setActiveConfiguration(newActiveConfiguration);
    };



    React.useEffect(() => {
        if (customerConfigurations.length === 0)
            return;
        setActiveConfiguration(customerConfigurations[0]);
    }, [customerConfigurations]);

    React.useEffect(() => {
        if (activeConfiguration === undefined)
            return;

        const activeCarModel = carModels.find(cm => cm.id === activeConfiguration.carModelId);
        const newName = activeCarModel ? activeCarModel.title.toUpperCase() : '';
        setActiveCarModelName(newName);

        const lastModifiedAtDate = new Date(activeConfiguration.lastModifiedAt);
        const modifiedHourAndMinute = lastModifiedAtDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
        const elapsedTimeSinceLastModified = new Date(Date.now() - activeConfiguration.lastModifiedAt);
        const elapsedDaysSinceLastModified = elapsedTimeSinceLastModified.getDate() - 1;
        const elapsedMonthsSinceLastModified = elapsedTimeSinceLastModified.getMonth();
        const elapsedYearsSinceLastModified = elapsedTimeSinceLastModified.getFullYear() - 1970;

        let elapsedTimeText = 'today';
        if (elapsedYearsSinceLastModified > 0) {
            const pluralText = elapsedYearsSinceLastModified > 1 ? 's' : '';
            elapsedTimeText = `${elapsedYearsSinceLastModified} year${pluralText} ago`;
        }
        else if (elapsedMonthsSinceLastModified > 0) {
            const pluralText = elapsedMonthsSinceLastModified > 1 ? 's' : '';
            elapsedTimeText = `${elapsedMonthsSinceLastModified} month${pluralText} ago`;
        }
        else if (elapsedDaysSinceLastModified > 0) {
            const pluralText = elapsedDaysSinceLastModified > 1 ? 's' : '';
            elapsedTimeText = elapsedDaysSinceLastModified === 1 ? 'yesterday' : `${elapsedDaysSinceLastModified} day${pluralText} ago`;
        }

        setLastModifiedAtText(`created ${elapsedTimeText} at ${modifiedHourAndMinute}`);
    }, [activeConfiguration, carModels]);



    return (
        <div className={`${className} garage-item`}>
            <section className="active-configuration">
                {activeConfiguration &&
                    <button className="configuration-button" onClick={() => onActiveConfigurationSelected(activeConfiguration.id)}>
                        <CarRender
                            className="full-preview"
                            imageType="HighResStatic"
                            viewID="front"
                            customerConfigurationId={activeConfiguration.id}
                        />
                    </button>
                }
            </section>
            <div className='configurations'>
                {activeConfiguration &&
                    <section className='configuration-description'>
                        <h2>configuration {activeConfiguration.garageSortIndex}</h2>
                        <p> {activeCarModelName} -  {lastModifiedAtText}</p>
                    </section>
                }
                <GarageWidget
                    visible={true}
                    isConfigurationSaveable={false}
                    hideSaveButton={true}
                    isClosable={false}
                    customerConfigurations={customerConfigurations}
                    onConfigurationSelected={onConfigurationSelected}
                    onConfigurationDelete={onConfigurationDelete}
                    onConfigurationSave={() => { }}
                />
            </div>
        </div>);
};

export default styled(GarageItem)`
  position: relative;

  section.active-configuration,
  section.active-configuration > .configuration-button,
  section.active-configuration > .configuration-button > .full-preview
  {
    content: " ";
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0;
    right:0;
    left:0;
    bottom:0;
    padding:0;
    overflow: auto;
    border: none;
    cursor: pointer;
  }

  .configurations {
    position: relative;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    section.configuration-description {
      align-self: flex-start;
      padding-top: 70px;
      padding-left: 70px;
      width: 580px;
      text-align: left;
      display: flex;
      flex-direction: column;

      > h2 {
        margin: 0;
        font-size: 42px;
        font-family: 'ZwoLF SemiBold';
        text-transform: uppercase;
      }

      > p {
        font-size: 18px;
        letter-spacing: 0.3px;
        line-height: 24px;
        margin: 0.5em 0 0 0;
      }
    }

    > ${GarageWidget} {
        pointer-events: auto;
    }
  }
`;
