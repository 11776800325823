import * as React from 'react';
import { ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { FeatureObject } from '../../../../../shared/model/FeatureSet';
import { Feature, ID, Option } from '../../../../../shared/model/RawModel';
import ActionIconButton from '../../../../components/ActionIconButton';

type Props = {
  className?: string;
  feature?: FeatureObject;
  onSelection: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  onOpenImageViewer: (imageUrl: string) => void;
};
const SidebarToggle: React.FunctionComponent<Props> = props => {
  const { feature, onSelection, onOpenImageViewer } = props;
  if (!feature) return <li />;

  //no need to filter options since it is a special case
  const options = feature.optionSetObjects.flatMap(o => o.options);

  const selectedValueCandidate = options.find(
    o => o.status === 'selected-by-user' || o.status === 'selected-from-preset'
  );

  if (options.length !== 2) return <li />;

  const selectedValue = selectedValueCandidate ? selectedValueCandidate.optionId : options[0].optionId;

  const isChecked = selectedValue === options[1].optionId;
  
  const featureId = feature.id;

  const defaultImageUrl = './images/black-spot-dimmed.png';
  const optionWithAnImage = options.find(o => o.texture !== undefined);
  const hasPreviewImage = optionWithAnImage !== undefined;
  const previewImageUrl = optionWithAnImage
    ? optionWithAnImage.texture
      ? optionWithAnImage.texture
      : defaultImageUrl
    : defaultImageUrl;

  const inputChanged: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.checked) {
      onSelection(featureId, options[1].optionId);
    } else {
      onSelection(featureId, options[0].optionId);
    }
  };

  return (
    <li className={props.className}>
      <span className="toggle-area">
        <label className="checkbox-container">
          <p>
            {feature.title}
            {hasPreviewImage && (
              <ActionIconButton className="icon-info" onClick={() => onOpenImageViewer(previewImageUrl)} />
            )}
          </p>

          <input type="checkbox" checked={isChecked} onChange={inputChanged} />
          <span className="checkmark"></span>
        </label>
      </span>
    </li>
  );
};
export default styled(SidebarToggle)`
  border-bottom: 1px solid ${p => p.theme.colors.onboardingBackgroundBright};

  span.toggle-area {
    padding-right: 19px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .checkbox-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-content: center;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    label {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      /* justify-content: center; */
    }

    /* Hide the browser's default checkbox */
    .checkbox-container input {
      position: absolute;
      right: 0;
      opacity: 0;
      cursor: pointer;
      height: 49px;
      width: 120px;
    }

    .checkbox-container p {
      flex-grow: 1;
      margin: 0;
      line-height: 49px;
      font-size: 18px;
      font-family: 'ZwoLF SemiBold';
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;

      button.icon-info {
        margin: 0px 8px;
        line-height: 49px;
        background-color: ${p => p.theme.colors.onboardingText}
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      align-self: flex-end;
      top: 0;
      left: 0;
      width: 71px;
      height: 49px;
      background-image: url('./images/switch-off@2x.png');
      background-size: 100% 100%;
    }

    /* Show the active switch image when checked */
    .checkbox-container input:checked ~ .checkmark {
      background-image: url('./images/switch-blue-on@2x.png');
      background-size: 100% 100%;
    }
  }
`;
