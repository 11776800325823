import React from 'react';
import styled from 'styled-components';

import { ID, Preset } from '../../../shared/model/RawModel';
import CarRender from '../../components/CarRender';
//import ActionButton from '../../components/ActionButton';

type Props = {
  className?: string;
  preset: Preset;
  isFixedWidth: boolean;
  isHighResPicture: boolean;
  onPresetSelected: (presetId: ID<Preset>) => void;
};

const PresetItem: React.FunctionComponent<Props> = props => {
  const { preset, onPresetSelected, isFixedWidth, isHighResPicture } = props;
  const renderProps = isFixedWidth ? { className: 'preview', fixedWidth: 200, fixedHeight: 200 } : { className: 'full-preview' };
  const classNames = [props.className, 'car-button'].join(" ");

  return (
    <button className={classNames} onClick={() => onPresetSelected(preset.id)}>
      <CarRender
        viewID={isFixedWidth ? 'preset' : 'front'}
        imageType={isHighResPicture ? "HighResStatic" : "LowResStatic"}
        presetId={preset.id}
        {...renderProps} />
    </button>
  );
};
export default styled(PresetItem)`
  cursor: pointer;
  width: 220px;
  height: 160px;  
  background: none;
  border: none;
  display: flex;

  .preview {
    height: 136px !important;
  }

`;
