import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import ActionIconButton from './ActionIconButton';
import AppMenuEntry from './AppMenuEntry';
import WithBlendedUnderlay from './WithBlendedUnderlay';
import { AppConfiguration } from '../app/AppConfiguration';
import { notifyServer, actionExportPDF } from './notifyServer';
import { useItemFromUrl } from './useItemsFromServer';
import { VersionResponse } from '../../shared/model/ActionResponse';
import { CustomerConfiguration, ID } from '../../shared/model/RawModel';

type Props = {
  className?: string;
  detailedMenu?: boolean;
  onOpenEnterCodeWidget?: () => void;
  onOpenGenerateCodeWidget?: () => void;
  onOpenExportVideoWidget?: () => void;
};

const AppMenu: React.FunctionComponent<Props> = props => {
  const {className, detailedMenu, onOpenEnterCodeWidget, onOpenGenerateCodeWidget, onOpenExportVideoWidget} = props;

  const [isOpen, setOpen] = React.useState<Boolean>(false);
  const [isSavePdfActive, setSavePdfActive] = React.useState<Boolean>(true);
  const history = useNavigate();

  const { item: versionResponse } = useItemFromUrl<VersionResponse>(
    `${AppConfiguration.baseServerUrl}/api/version` );

  const onQuit = () => {
    if (AppConfiguration.isElectronApp) {
      const notificationUrl = `${AppConfiguration.baseServerUrl}/api/quit`;
      notifyServer(notificationUrl, (isSuccessful:boolean)=>
      {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('quit', '');
      });
    }
  }
  
  const { customerId, customerConfigurationId, countryVersion } = useParams() as {customerId:string, customerConfigurationId:ID<CustomerConfiguration>,countryVersion:string};

  const onSavePdf = () => {
    setSavePdfActive(false);
    actionExportPDF(customerConfigurationId, (isSuccessful:boolean) => {
      setSavePdfActive(true);
    });
  }

  const url_prices = AppConfiguration.baseServerUrl+'/features/'+customerId+'/'+customerConfigurationId+'/'+countryVersion;

  const savePdfClassName = isSavePdfActive ? '' : 'disabled';

  const onNewConfiguration = () => {
    history(`/model-selection/${customerId}/${countryVersion}/Preset`);
  }

  const onGenerateCode = () => {
    setOpen(false);
    onOpenGenerateCodeWidget && onOpenGenerateCodeWidget();
  }

  const onEnterCode = () => {
    setOpen(false);
    onOpenEnterCodeWidget && onOpenEnterCodeWidget();
  }

  const onExportVideo = () => {
    setOpen(false);
    onOpenExportVideoWidget && onOpenExportVideoWidget();
  }


  return (
    <div className={className}>
      <WithBlendedUnderlay className="app-menu-open-action">
        <ActionIconButton
          className="icon-hamburger"
          onClick={() => {
            setOpen(true);
          }}
        />
      </WithBlendedUnderlay>
      <div
        className={`menu-holder ${isOpen ? 'is-open' : 'is-closed'}`}
        onClick={() => {
          setOpen(false);
        }}
      >
        <section className="app-menu-slider" onClick={e => e.stopPropagation()}>
          <ActionIconButton
            className="app-menu-close-action icon-close"
            onClick={() => {
              setOpen(false);
            }}
          />
          <span className="app-menu-version">{versionResponse?.version}</span>
          <ul>
            {detailedMenu && <AppMenuEntry onClick={onNewConfiguration}>New Configuration</AppMenuEntry>}
            {detailedMenu && <AppMenuEntry onClick={onGenerateCode}>Generate Code</AppMenuEntry>}
            <AppMenuEntry onClick={onEnterCode}>Enter Code</AppMenuEntry>
            {detailedMenu && <AppMenuEntry className={savePdfClassName} onClick={onSavePdf}>Export PDF</AppMenuEntry>}
            {/*detailedMenu && <AppMenuEntry onClick={onExportVideo}>Export Video</AppMenuEntry>*/}

            {AppConfiguration.isElectronApp && (
              <AppMenuEntry onClick={() => history('/', {replace: true})}>Restart</AppMenuEntry>
            )}
            {AppConfiguration.isDebug && !AppConfiguration.isWebVersion && (
              <AppMenuEntry onClick={()=>{navigator.clipboard.writeText(url_prices)}}>Debug: Prices</AppMenuEntry>
            )}
            {AppConfiguration.isElectronApp && (
              <AppMenuEntry onClick={onQuit}>Quit</AppMenuEntry>
            )}
          </ul>
          <ActionIconButton className="icon-bugatti" />
        </section>
      </div>
    </div>
  );
};

export default styled(AppMenu)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
  /* z-index: 2000; */

  .app-menu-open-action {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 5px;
    pointer-events: all;
    height: 45px;  /* without defining size here mix-blend mode do not get any size to work with. That also prevents back filter blur from working in app menu */
    width: 45px;

    ${ActionIconButton} {
      height: 35px;
      width: 35px;
    }
  }

  .app-menu-close-action,
  .app-menu-open-settings {
    position: absolute;
    top: 13px;
    left: 13px;

    height: 35px;
    width: 35px;
  }

  .menu-holder {
    position: absolute;
    perspective: 800px;
    top: 0px;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    transition: background-color 250ms ease-in-out;

    .app-menu-slider {
      position: absolute;
      background-color: ${p => p.theme.colors.background};
      color: ${p => p.theme.colors.foreground};
      top: 0px;
      bottom: 0px;
      width: 384px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      transform-origin: left;
      transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

      .app-menu-version {
        position: absolute;
        top: 17px;
        right: 17px;
        font-size: 12px;
        opacity: 0.3;
      }

      .app-menu-open-settings {
        top: unset;
        bottom: 13px;
      }

      .icon-bugatti {
        width: 41px;
        height: 41px;
        pointer-events: none;
      }

      > ul {
        padding: 150px 0px 0px;
        align-self: center;
        width: 100%;
        display: flex;
        flex-direction: column;

        > ${AppMenuEntry} {
          &:not(:first-child) {
            border-top: 1px solid ${p => p.theme.colors.neutralTransparent};
          }
        }
      }
    }

    &.is-closed {
      pointer-events: none;
      background-color: transparent;
      backdrop-filter: blur(0px);

      .app-menu-slider {
        opacity: 0;
        transform: translateX(-300px);
      }
    }

    &.is-open {
      pointer-events: all;
      background-color: ${p => p.theme.colors.darkTransparentBackground};
      backdrop-filter: blur(8px);
      z-index: 2000;

      .app-menu-slider {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }

  ${AppMenuEntry} {
    opacity: 1;
    transition: opacity 2000ms, background-color 2000ms, color 1000ms;
    
    &.disabled {
      pointer-events:none;
      color: ${p => p.theme.colors.blend};
      background-color: rgba(0,0,0,0.2);
      opacity: 0.4;
      transition: opacity 500ms, background-color 500ms, color 500ms;
    }
  }
`;
