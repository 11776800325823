import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../../shared/model/FeatureSet';
import { ID, Option } from '../../../../../../shared/model/RawModel';

type Props = {
  className?: string;
  feature: FeatureObject;
  options: ReadonlyArray<OptionObject>;
  onSelect: (optionId: ID<Option>) => void;
  onResetToPreset: () => void;
};
const FeatureSettingsDropdown: React.FunctionComponent<Props> = props => {
  const { options, onSelect } = props;
  if (options.length === 0) return <section />;

  const selectedValueCandidate = options.find(
    o => o.status === 'selected-by-user' || o.status === 'selected-from-preset'
  );
  const selectedValue = selectedValueCandidate ? selectedValueCandidate.optionId : options[0].optionId;

  //BUGFIX: don't show chevron when there is only one option
  if(options.length===1)
  {
    return (
      <span>
        {options[0].title}
      </span>
    );
  }

  return (
    <span className={`${props.className} select-wrapper`}>
      <select value={selectedValue} onChange={e => onSelect(e.target.value)}>
        {options.map((o, i) => (
          <option value={o.optionId} key={i}>
            {o.title}
          </option>
        ))}
      </select>
    </span>
  );
  
};
export default styled(FeatureSettingsDropdown)`
  position: relative;
  line-height: 25px;
  padding-right: 20px;
  
  select {
    border: none;
    direction: rtl;
    display: flex;
    flex-direction: row;
  }

  &:after{
    content: 'X';
    position: absolute;
    top: 0px;
    right: 3px;
    width: 25px;
    height: 25px;
    mask-image: url('./icons/chevron-down-thicker.svg');
    mask-repeat: no-repeat;
    mask-position: 50% 50%;
    mask-size: 50% 50%;
    background-color: ${p => p.theme.colors.foreground};
  }

`;
