import * as React from 'react';
import styled from 'styled-components';
import CameraController from './CameraController';
import EnvironmentSwitch from './EnvironmentSwitch';
import { ModificationState } from '../../../components/modifyItem';
import { ID, Feature, Option, CustomerConfiguration } from '../../../../shared/model/RawModel';

type Props = {
    className?: string;
    isShowRoomContext: boolean;
    isCameraControllerVisible: boolean;
    modificationState: ModificationState;
    onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
    onClose: ()=>void;
    reloadImages: ()=>void;
    customerConfigurationId: ID<CustomerConfiguration>;
};

const PlayModeWidget: React.FunctionComponent<Props> = props => {
    const {
        className,
        isShowRoomContext,
        isCameraControllerVisible,
        modificationState,
        onSelectOption,
        onClose,
        reloadImages,
        customerConfigurationId
    } = props;

  const visibleClassName = isCameraControllerVisible ? 'visible' : 'hidden';
  const classNames = `${className} ${visibleClassName}`;

  return (
    <div className={classNames}>
      {
        isShowRoomContext && 
        <CameraController
          className="camera-controller"
          visible = {isCameraControllerVisible}
          modificationState={modificationState}
          onSelectOption={onSelectOption}
          onClose={onClose}
          reloadImages={reloadImages}
          customerConfigurationId={customerConfigurationId}
        />
      }

      {!isShowRoomContext && (
        <EnvironmentSwitch
          className="environment-switch"
          modificationState={modificationState}
          onSelectOption={onSelectOption}
        />
      )}
    </div>
  );
};

export default styled(PlayModeWidget)`
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 500ms ease-in-out, transform 50ms ease-in-out;

  &.hidden {
    transform: translateY(5000px);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0px);
    opacity: 1;
  }

  ${CameraController} {
    position: absolute;
    pointer-events: all;
  }

  .environment-switch {
    float: right;
    margin: 60px 36px;
    pointer-events: all;
  }
`;
