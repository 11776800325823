import * as React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  isActive: boolean;
  isSticky: boolean;
  onNewConfigurationAdded: () => void
};

const NewConfigurationItem: React.FunctionComponent<Props> = props => {
  const { className, isActive, isSticky, onNewConfigurationAdded } = props;
  const activeState = isActive ? 'active' : 'disabled';
  const stickyState = isSticky ? 'is-sticky' : '';
  const classNames = `${className} state-${activeState} ${stickyState}`;

  function onClicked(event: React.MouseEvent) {
    event.stopPropagation();
    onNewConfigurationAdded();
  }

  return (
    <li className={classNames} onClick={onClicked}>
      <div className="bubble-icon" onClick={onClicked}>
        <div className="add-icon"></div>
      </div>
    </li>
  );
};
export default styled(NewConfigurationItem)`
  cursor: pointer;
  width: 220px;
  padding-bottom: 10px;
  border-right: 1px solid rgba(0,0,0,0.15);
  display: flex;
  justify-content: space-around;
  align-items: center;
  pointer-events: all;

  .bubble-icon {
    width: 36px;
    height: 36px;
    border-radius: 1000px;
    background-color: ${p => p.theme.colors.foreground};
    display: flex;
    justify-content: space-around;
    align-items: center;
    pointer-events: all;

    .add-icon {
      content: ' ';
      width: 30px;
      height: 30px;
      mask-image: url('./icons/plus.svg');
      mask-repeat: no-repeat;
      mask-position: 50% 50%;
      mask-size: 100% 100%;
      background-color: ${p => p.theme.colors.background};
    }
  }

  &:hover {

    

    .bubble-icon {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  &.state-active {

    .bubble-icon {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  &.state-disabled {
    pointer-events: none;

    .bubble-icon {
      opacity: 0.2;
      transition: opacity 0.5s;
    }
  } 

  &.is-sticky {
    position: absolute;
    right: 0;
    width: 100px;
    border-right: none;
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;

    .bubble-icon {
      margin-right: 10px;
    }
  }
  
`;
