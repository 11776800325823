import * as React from 'react';
import styled from 'styled-components';
import AppMenu from './AppMenu';
import { ViewObject } from '../../shared/model/FeatureSet';
import FeatureViewHeader from '../pages/feature-list/FeatureViewHeader';
import WithBlendedUnderlay from './WithBlendedUnderlay';
import ActionIconButton from './ActionIconButton';
import EnterCodeWidget from './EnterCodeWidget';
import GenerateCodeWidget from './GenerateCodeWidget';
import ExportVideoWidget from './ExportVideoWidget';


type Props = {
  className?: string;
  isFeatureViewHeaderVisible: boolean;
  views: ReadonlyArray<ViewObject>;
  isShowRoomContext: boolean;
  areIconsVisible: boolean;
  isAppMenuVisible: boolean;
  isCameraControllerVisible: boolean;

  onFeatureViewSelected: (view: ViewObject) => void;
  onGarageOpened: () => void;
  onCameraControllerToggled: () => void;
};

const FeatureListPageHeader: React.FunctionComponent<Props> = props => {
  const { 
    className,
    isFeatureViewHeaderVisible,
    views,
    isShowRoomContext,
    areIconsVisible,
    isAppMenuVisible,
    isCameraControllerVisible,
    onFeatureViewSelected,
    onGarageOpened,
    onCameraControllerToggled } = props;

  const [isEnterCodeWidgetVisible, setIsEnterCodeWidgetVisible] = React.useState<boolean>(false);
  const [isGenerateCodeWidgetVisible, setIsGenerateCodeWidgetVisible] = React.useState<boolean>(false);
  const [isExportVideoWidgetVisible, setIsExportVideoWidgetVisible] = React.useState<boolean>(false);

  const iconVisibilityClassName = areIconsVisible ? 'visible' : 'hidden';

  const onOpenEnterCodeWidget = () =>
  {
    setIsEnterCodeWidgetVisible(true);
  }

  const onCloseEnterCodeWidget = () => {
    setIsEnterCodeWidgetVisible(false);
  }

  const onOpenGenerateCodeWidget = () =>
  {
    setIsGenerateCodeWidgetVisible(true);
  }

  const onCloseGenerateCodeWidget = () => {
    setIsGenerateCodeWidgetVisible(false);
  }

  const onOpenExportVideoWidget = () =>
  {
    setIsExportVideoWidgetVisible(true);
  }

  const onCloseExportVideoWidget = () => {
    setIsExportVideoWidgetVisible(false);
  }

  // work around styling for widget blur background
  const isAnyWidgetVisible = isEnterCodeWidgetVisible || isGenerateCodeWidgetVisible || isExportVideoWidgetVisible;
  const iconRemoveClassName = isAnyWidgetVisible ? 'remove' : '';
  const activeCameraControllerClassName = isCameraControllerVisible ? 'active' : 'inactive';
  const cameraControllerIcon = isShowRoomContext ? 'icon-camera-controller-popup' : 'icon-camera-controller-tablet';

  return (
    <section className={className}>

      <FeatureViewHeader
        visible={isFeatureViewHeaderVisible}
        views={views}
        onSelect={onFeatureViewSelected}
      />

      <WithBlendedUnderlay className={`garage-mode-toggle-action ${iconVisibilityClassName} ${iconRemoveClassName}`}>
        <ActionIconButton className="icon-garage" onClick={onGarageOpened} />
      </WithBlendedUnderlay>

      <WithBlendedUnderlay className={`camera-controller-toggle-action ${activeCameraControllerClassName} ${iconVisibilityClassName} ${iconRemoveClassName}`} >
        <ActionIconButton className={cameraControllerIcon} onClick={onCameraControllerToggled} />
      </WithBlendedUnderlay>

      {isEnterCodeWidgetVisible && <EnterCodeWidget onCloseCallback={onCloseEnterCodeWidget}/>}
      {isGenerateCodeWidgetVisible && <GenerateCodeWidget onCloseCallback={onCloseGenerateCodeWidget}/>}
      {isExportVideoWidgetVisible && <ExportVideoWidget onCloseCallback={onCloseExportVideoWidget}/>}
      
      {isAppMenuVisible && 
      <AppMenu
        className= "app-menu"
        detailedMenu={true}
        onOpenEnterCodeWidget={onOpenEnterCodeWidget}
        onOpenGenerateCodeWidget={onOpenGenerateCodeWidget}
        onOpenExportVideoWidget={onOpenExportVideoWidget}
        />
      }
  </section >
  );
};

export default styled(FeatureListPageHeader)`
  z-index: 1;

  ${FeatureViewHeader} {
    position: absolute;
    width: 100%;
    height: 60px;

    &.offset {
      top: 60px;
      }
  }

  .garage-mode-toggle-action,
  .camera-controller-toggle-action {
    position: absolute;
    top: 8px;
    padding: 5px;

    ${ActionIconButton} {
      height: 35px;
      width: 35px;
    }
    
    transition: top 300ms ease-in-out;
    
    &.visible {
      top: 8px;
      
      &.offset{
        top: 68px;
      }
    }

    &.hidden {
      top: -60px;
    }

    &.remove{
      display: none;
    }
  }

  .garage-mode-toggle-action {
    right: 8px;
  }
  .camera-controller-toggle-action {
    right: 60px;
    transition: background-color 300ms ease-in-out, 
                top 300ms ease-in-out;

    &.offset {
      span.blended-underlay {
        position: absolute;
        width: 45px;
        height: 45px;
        top: 0px;
        background-color: black;
        mix-blend-mode: normal;
        transition: background-color 300ms ease-in-out;
      }

      button {
        background-color: white;
      }

      &:after{
        content: ' ';
        position: absolute;
        top: -8px;
        width: 12px;
        height: 6px
        background-color: black;
        mask-image: url('./images/nose-down.svg');
        mask-position: center center;
        mask-size: cover;
        transition: background-color 300ms ease-in-out;
      }
    }

    &.active > .blended-underlay {
        background-color: ${p => p.theme.colors.background};
    }
  }

  ${EnterCodeWidget} {
    z-index: 2;
  }

  ${GenerateCodeWidget} {
    z-index: 2;
  }

  ${ExportVideoWidget}{
    z-index: 2;
  }
  

  .app-menu {
    transition: top 300ms ease-in-out;
    &.offset {
      top: 60px;
    }
  }
`;