import React from 'react';
import styled from 'styled-components';

import { CustomerConfiguration, ID } from '../../../shared/model/RawModel';
import CarRender from '../../components/CarRender';
import ActionIconButton from '../../components/ActionIconButton';

type Props = {
  className?: string;
  customerConfiguration: CustomerConfiguration;
  onConfigurationSelected: (customerConfigurationId: ID<CustomerConfiguration>) => void;
  onConfigurationDelete: (customerConfigurationId: ID<CustomerConfiguration>) => void;
};

const CustomerConfigurationItem = (props: Props) => {
  const { className, customerConfiguration, onConfigurationSelected, onConfigurationDelete } = props;
  const classNames = `${className} state-inactive`;
  return (
    <li
      className={classNames}
      onClick={() => {
        onConfigurationSelected(customerConfiguration.id);
      }}
    >
      <CarRender
        customerConfigurationId={customerConfiguration.id}
        imageType="LowResStatic"
        fixedWidth={220}
        fixedHeight={160}
        viewID="front"
      />
      <p>{customerConfiguration.garageSortIndex}</p>
      <div className="bubble-icon">
        <ActionIconButton className="icon-close" onClick={(event) =>{
          event.stopPropagation();
          onConfigurationDelete(customerConfiguration.id);
        }}/>
      </div>
    </li>

  );
};

export default styled(CustomerConfigurationItem)`
  list-style: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  margin-right: 1px;

  p,
  button.icon-close {
    position: absolute;
  }

  p {
    left: 18px;
    bottom: -6px;
    font-size: 18px;
    font-family: 'ZwoLF SemiBold';
    font-weight: bold;
    color: ${p => p.theme.colors.background};
  }

  .bubble-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-color: ${p => p.theme.colors.background};
    border-radius: 1000px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button.icon-close {
      width: 20px;
      height: 20px;
    }
  }

`;
