import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FeatureSetResponse, ViewObject } from '../../../shared/model/FeatureSet';
import { Customer, CustomerConfiguration, FeatureSet, HiddenViewTitle, ID, View } from '../../../shared/model/RawModel';
import { AppConfiguration } from '../../app/AppConfiguration';
import { SelectThemeFunction, ThemeSelectionContext } from '../../app/AppTheme';
import { ModificationState } from '../../components/modifyItem';
import { updateView } from '../../components/updateConfiguration';
import { useItemFromUrl } from '../../components/useItemsFromServer';
import { FeatureSetObject } from './../../../shared/model/FeatureSet';

type SelectFeatureMode = 'select-first' | 'select-last' | 'await-results';

type Data = {
  selectFeatureMode: SelectFeatureMode | null;
  modificationState: ModificationState;
  customerId: ID<Customer>;
  customerConfigurationId: ID<CustomerConfiguration>;
  isLoading: boolean;
  featureSetResponse: FeatureSetResponse | undefined;
  loadError: Error | null;
  activeFeatureSetId: ID<FeatureSet> | null;
  activeFeatureSetViewIndex: number;
};

const loopViewsInPresentationMode = false;
const loopFeatureViews = true;
const emptyArray: never[] = [];

class FeatureViewSelectionLogic {
  private readonly setSelectFeatureMode: (v: SelectFeatureMode | null) => void;
  public readonly setModificationState: (v: ModificationState) => void;
  private readonly setactiveFeatureSetId: (v: ID<FeatureSet> | null) => void;
  private readonly setactiveFeatureSetViewIndex: (v: number) => void;
  private readonly selectTheme: SelectThemeFunction;

  public selectFeatureMode: SelectFeatureMode | null = null;
  public customerId: ID<Customer> = '';
  public customerConfigurationId: ID<CustomerConfiguration> = '';
  public isLoading: boolean = false;
  public featureSetResponse: FeatureSetResponse | undefined = undefined;
  public activeFeatureSetId: ID<FeatureSet> | null = null;
  public loadError: Error | null = null;
  public modificationState: ModificationState = { reloadTrigger: false, error: null };

  public views: Array<ViewObject> = emptyArray;
  public featureSetsForActiveView: Array<FeatureSetObject> = emptyArray;
  private featureViews: Array<ViewObject> = emptyArray;

  private activeViewIndex: number = -1;
  private activeFeatureSetIndex: number = -1;
  public activeFeatureSetViewIndex: number = -1;

  public activeFeatureSet: FeatureSetObject | undefined;
  public activeView: ViewObject | undefined;
  public activeCameraView: ID<View> | undefined;

  public hasPreviousFeature: boolean = false;
  public hasNextFeature: boolean = false;
  public hasPreviousFeatureInView: boolean = false;
  public hasNextFeatureInView: boolean = false;
  public hasNextView: boolean = false;
  public hasPreviousView: boolean = false;
  public hasNextFeatureView: boolean = false;
  public hasPreviousFeatureView: boolean = false;

  public highlightNextView: boolean = false;
  public highlightPreviousView: boolean = false;

  public progress: number = 0;

  public modificationUrl: string = '';

  constructor(
    setSelectFeatureMode: (v: SelectFeatureMode | null) => void,
    setModificationState: (v: ModificationState) => void,
    setactiveFeatureSetId: (v: ID<FeatureSet> | null) => void,
    setactiveFeatureSetViewIndex: (v: number) => void,
    selectTheme: SelectThemeFunction
  ) {
    this.setSelectFeatureMode = setSelectFeatureMode;
    this.setModificationState = setModificationState;
    this.setactiveFeatureSetId = setactiveFeatureSetId;
    this.setactiveFeatureSetViewIndex = setactiveFeatureSetViewIndex;
    this.selectTheme = selectTheme;
  }

  update(data: Data) {
    this.selectFeatureMode = data.selectFeatureMode;
    this.modificationState = data.modificationState;
    this.customerId = data.customerId;
    this.customerConfigurationId = data.customerConfigurationId;
    this.isLoading = data.isLoading;
    this.featureSetResponse = data.featureSetResponse;
    this.loadError = data.loadError;
    this.activeFeatureSetId = data.activeFeatureSetId;
    this.activeFeatureSetViewIndex = data.activeFeatureSetViewIndex;

    this.views = this.featureSetResponse ? this.featureSetResponse.views : emptyArray;
    this.views = this.views.filter(v => v.title !== HiddenViewTitle);
    this.featureSetsForActiveView = this.featureSetResponse ? this.featureSetResponse.featureSetsForActiveView : emptyArray;

    this.activeFeatureSetIndex = this.featureSetsForActiveView.findIndex(f => f.id === this.activeFeatureSetId);
    this.activeFeatureSet = this.featureSetsForActiveView.find(f => f.id === this.activeFeatureSetId);
    this.activeViewIndex = this.views.findIndex(view => view.isActive);

    this.activeView = this.views[this.activeViewIndex];

    if (this.activeFeatureSet && this.activeFeatureSet.viewIDOverride) {
      this.activeCameraView = this.activeFeatureSet.viewIDOverride;
    } else if (this.activeView) this.activeCameraView = this.activeView.viewId;
    else this.activeCameraView = undefined;

    this.hasPreviousFeatureInView = this.activeFeatureSetIndex > 0;
    this.hasNextFeatureInView =
      this.activeFeatureSetIndex !== -1 && this.activeFeatureSetIndex < this.featureSetsForActiveView.length - 1;

    this.hasNextView =
      this.views.length > 1 && (loopViewsInPresentationMode || this.activeViewIndex < this.views.length - 1);
    this.hasPreviousView = this.views.length > 1 && (loopViewsInPresentationMode || this.activeViewIndex > 0);

    this.hasNextFeatureView =
      this.featureViews.length > 1 &&
      (loopFeatureViews || this.activeFeatureSetViewIndex < this.featureViews.length - 1);
    this.hasPreviousFeatureView =
      this.featureViews.length > 1 && (loopFeatureViews || this.activeFeatureSetViewIndex > 0);

    this.modificationUrl = `${AppConfiguration.baseServerUrl}/api/customer-configurations/${this.customerConfigurationId}`;

    this.hasPreviousFeature = this.hasPreviousFeatureInView || this.hasPreviousView;
    this.hasNextFeature = this.hasNextFeatureInView || this.hasNextView;

    // Highlight next feature that is not configured yet
    const firstNotConfiguredViewIndex = this.views.findIndex(v => !v.isFullyConfigured);
    this.highlightNextView = firstNotConfiguredViewIndex > 0 && this.activeViewIndex < firstNotConfiguredViewIndex;
    this.highlightPreviousView = firstNotConfiguredViewIndex > 0 && this.activeViewIndex > firstNotConfiguredViewIndex;
    if (firstNotConfiguredViewIndex === this.activeViewIndex) {
      for (let i = 0; i < this.featureSetsForActiveView.length; i++) {
        if (!this.featureSetsForActiveView[i].isConfigured) {
          this.featureSetsForActiveView[i].isNextFeatureSet = true;
          break;
        }
      }
    }

    //calculate progress
    const viewCompletionCount = this.views.reduce((prev, entry) => prev + (entry.isFullyConfigured ? 1 : 0), 0);
    this.progress = (this.views.length > 0 ? viewCompletionCount / this.views.length : 0) * 100;

    if (!this.isLoading && this.selectFeatureMode && this.featureSetsForActiveView.length !== 0) {
      if (
        this.selectFeatureMode === 'select-first' &&
        this.activeFeatureSetId !== this.featureSetsForActiveView[0].id
      ) {
        this.setactiveFeatureSetViewIndex(0);
        this.setactiveFeatureSetId(this.featureSetsForActiveView[0].id);
      } else if (
        this.selectFeatureMode === 'select-last' &&
        this.activeFeatureSetId !== this.featureSetsForActiveView[this.featureSetsForActiveView.length - 1].id
      ) {
        this.setactiveFeatureSetViewIndex(0);
        this.setactiveFeatureSetId(this.featureSetsForActiveView[this.featureSetsForActiveView.length - 1].id);
      }
    }
  }

  public readonly selectFeatureInPreviousView = () => {
    this.setSelectFeatureMode('await-results');
    const previousIndex = (this.activeViewIndex + this.views.length - 1) % this.views.length;
    return updateView(this.modificationUrl, this.views[previousIndex].viewId, this.setModificationState).then(() =>
      this.setSelectFeatureMode('select-last')
    );
  };

  public readonly selectFeatureInNextView = () => {
    this.setSelectFeatureMode('await-results');
    const nextIndex = (this.activeViewIndex + 1) % this.views.length;
    return updateView(this.modificationUrl, this.views[nextIndex].viewId, this.setModificationState).then(() =>
      this.setSelectFeatureMode('select-first')
    );
  };

  public readonly selectPreviousView = () => {
    const previousIndex = (this.activeViewIndex + this.views.length - 1) % this.views.length;
    return updateView(this.modificationUrl, this.views[previousIndex].viewId, this.setModificationState);
  };

  public readonly selectNextView = () => {
    const nextIndex = (this.activeViewIndex + 1) % this.views.length;
    return updateView(this.modificationUrl, this.views[nextIndex].viewId, this.setModificationState);
  };

  public readonly selectPreviousFeatureView = () => {
    const previousIndex = (this.activeFeatureSetViewIndex + this.featureViews.length - 1) % this.featureViews.length;
    this.setactiveFeatureSetViewIndex(previousIndex);
  };

  public readonly selectNextFeatureView = () => {
    const nextIndex = (this.activeFeatureSetViewIndex + 1) % this.featureViews.length;
    this.setactiveFeatureSetViewIndex(nextIndex);
  };

  public readonly selectView = (viewId: ID<View>) => {
    this.setSelectFeatureMode(null);
    this.setactiveFeatureSetId(null);
    return updateView(this.modificationUrl, viewId, this.setModificationState);
  };

  public readonly selectPreviousFeature = () => {
    this.setSelectFeatureMode(null);
    if (this.hasPreviousFeatureInView) {
      this.setactiveFeatureSetId(this.featureSetsForActiveView[this.activeFeatureSetIndex - 1].id);
    } else if (this.hasPreviousView) {
      return this.selectFeatureInPreviousView();
    }
  };

  public readonly selectNextFeature = () => {
    this.setSelectFeatureMode(null);
    if (this.hasNextFeatureInView) {
      this.setactiveFeatureSetId(this.featureSetsForActiveView[this.activeFeatureSetIndex + 1].id);
    } else if (this.hasNextView) {
      return this.selectFeatureInNextView();
    }
  };

  public readonly selectFeature = (featureId: ID<FeatureSet>) => {
    this.setSelectFeatureMode(null);
    if (this.activeFeatureSetId === featureId) {
      this.setactiveFeatureSetViewIndex(0);
      this.setactiveFeatureSetId(null);
    } else {
      this.setactiveFeatureSetViewIndex(0);
      this.setactiveFeatureSetId(featureId);
    }
  };

  public readonly unselectFeature = () => {
    const lastViewId = this.activeFeatureSet ? this.activeFeatureSet.primaryViewId : null;
    this.setactiveFeatureSetViewIndex(0);
    this.setSelectFeatureMode(null);
    this.setactiveFeatureSetId(null);
    if (lastViewId) {
      return updateView(this.modificationUrl, lastViewId, this.setModificationState);
    }
  };
}

export const useViewSelectionLogic = () => {
  const [selectFeatureMode, setSelectFeatureMode] = useState<SelectFeatureMode | null>(null);
  const [modificationState, setModificationState] = useState<ModificationState>({ error: null, reloadTrigger: false });
  const { customerId, customerConfigurationId } = useParams() as {customerId:ID<Customer>,customerConfigurationId:ID<CustomerConfiguration>};
  const { isLoading, item: featureSetResponse, error: loadError } = useItemFromUrl<FeatureSetResponse>(
    `${AppConfiguration.baseServerUrl}/api/feature-set/${customerConfigurationId}`,
    modificationState.reloadTrigger
  );
  const [activeFeatureSetId, setactiveFeatureSetId] = useState<ID<FeatureSet> | null>(null);
  const [activeFeatureSetViewIndex, setactiveFeatureSetViewIndex] = useState<number>(0);
  const themeSelection = useContext(ThemeSelectionContext);

  if (featureSetResponse) {
    themeSelection(featureSetResponse.theme);
  }

  const logicInstance = useMemo<FeatureViewSelectionLogic>(
    () =>
      new FeatureViewSelectionLogic(
        setSelectFeatureMode,
        setModificationState,
        setactiveFeatureSetId,
        setactiveFeatureSetViewIndex,
        themeSelection
      ),
    [setSelectFeatureMode, setModificationState, setactiveFeatureSetId, setactiveFeatureSetViewIndex, themeSelection]
  );

  logicInstance.update({
    selectFeatureMode,
    modificationState,
    customerId: customerId as ID<Customer>,
    customerConfigurationId: customerConfigurationId as ID<CustomerConfiguration>,
    isLoading,
    featureSetResponse,
    loadError,
    activeFeatureSetId,
    activeFeatureSetViewIndex,
  });

  return logicInstance;
};
