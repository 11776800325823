import { useEffect, useState } from 'react';

type Vector2 =
  {
    x: number,
    y: number
  }

type ContainerProperties = {
  radius: number,
  center: Vector2,
  origin: Vector2
}

function clamp(input: number, min: number, max: number): number {
  return input < min ? min : input > max ? max : input;
}

function CalculateContainerProperties(container: HTMLElement): ContainerProperties {
  const boundRect = container.getBoundingClientRect();
  const radius = boundRect.width / 2;
  const center = {
    x: boundRect.x + radius,
    y: boundRect.y + radius
  };

  return {
    radius: radius,
    center: center,
    origin: { x: boundRect.x, y: boundRect.y }
  }
}

export const useDragJoystick = (
  dragContainer: HTMLElement | null,
  knobContainer: HTMLElement | null,
  canMoveInX: boolean,
  canMoveInY: boolean,
) => {
  const [delta, setDelta] = useState<Vector2>({ x: 0, y: 0 });

  useEffect(() => {
    if (!dragContainer || !knobContainer)
      return;

    const { radius, center, origin } = CalculateContainerProperties(dragContainer);
    const deltaInCoordinates = {x: delta.x, y:delta.y * -1};

    const knobRadius = knobContainer.clientWidth / 2;
    const angle = Math.atan2(deltaInCoordinates.y, deltaInCoordinates.x);

    const deltaMagnitude = Math.hypot(deltaInCoordinates.x, deltaInCoordinates.y);

    const knobPosition = {
      x: (center.x + Math.cos(angle) * radius * deltaMagnitude) - origin.x - knobRadius,
      y: (center.y + Math.sin(angle) * radius * deltaMagnitude) - origin.y - knobRadius
    };

    knobContainer.style.left = `${knobPosition.x}px`;
    knobContainer.style.top = `${knobPosition.y}px`;

  }, [delta, dragContainer, knobContainer]);

  useEffect(() => {
    let isDragging = false;

    const startDrag = () => {
      isDragging = true;
    }

    const updateDrag = (x: number, y: number) => {
      if (!isDragging || !dragContainer || !knobContainer)
        return;

      const { radius, center } = CalculateContainerProperties(dragContainer);
      const direction = {
        x: (x - center.x),
        y: (y - center.y)
      }

      const directionAngle = Math.atan2(direction.y, direction.x);
      const magnitude = Math.hypot(direction.x, direction.y);
      const clampedMagnitude = clamp(magnitude, 0, radius);
      const normalizedMagnitude = clampedMagnitude / radius;

      const deltaValue = {
        x: canMoveInX ? Math.cos(directionAngle) * normalizedMagnitude : 0,
        y: canMoveInY ? Math.sin(directionAngle) * normalizedMagnitude * -1 : 0
      }

      setDelta(deltaValue)
    }

    const endDrag = () => {
      if (!isDragging)
        return;

      setDelta({ x: 0, y: 0 });
      isDragging = false;
    }

    const onTouchStart = () => {
      startDrag();
    }

    const onMouseDown = () => {
      startDrag();
    }

    const onTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0];
      if (!touch)
        return;

      updateDrag(touch.clientX, touch.clientY);

    }

    const onMouseMove = (e: MouseEvent) => {
      updateDrag(e.clientX, e.clientY);
    }

    const onMouseUp = () => {
      endDrag();
    }

    const onTouchEnd = () => {
      endDrag();
    }

    window.addEventListener('touchmove', onTouchMove, { passive: false });
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchend', onTouchEnd);

    if (dragContainer) {
      dragContainer.addEventListener('touchstart', onTouchStart, { passive: false });
      dragContainer.addEventListener('mousedown', onMouseDown, { passive: false });
    }

    return () => {
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchend', onTouchEnd);

      if (dragContainer) {
        dragContainer.removeEventListener('touchstart', onTouchStart);
        dragContainer.removeEventListener('mousedown', onMouseDown);
      }
    }

  }, [dragContainer, knobContainer, canMoveInX, canMoveInY])

  return delta;
}