import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};

const WithBlendedUnderlay: React.FunctionComponent<Props> = props => {
  const { className, children, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <span className="blended-underlay" />
      {children}
    </div>
  );
};

export default styled(WithBlendedUnderlay)`
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  

  .blended-underlay {
    position: absolute; /* absolute position is required to expand the span size to its parent's size. Without this size is 0 , it seems to cause artefact to backdrop-filter*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;

    background-color: ${p => p.theme.colors.blend};
    mix-blend-mode: ${p => p.theme.mixBlendMode};
  }
`;
