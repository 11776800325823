import * as React from 'react';
import styled from 'styled-components';

import { CustomerConfiguration, ID } from '../../../shared/model/RawModel';
import ActionIconButton from '../../components/ActionIconButton';
import { useDragScroll } from '../../components/useDragScroll';
import CustomerConfigurationItem from './CustomerConfigurationItem';
import NewConfigurationItem from './NewConfigurationItem';
import WithBlendedUnderlay from '../../components/WithBlendedUnderlay';

type Props = {
  className?: string;
  visible: boolean;
  isConfigurationSaveable: boolean;
  hideSaveButton: boolean;
  isClosable: boolean;
  customerConfigurations: ReadonlyArray<CustomerConfiguration>;
  onConfigurationSelected: (customerConfigurationId: ID<CustomerConfiguration>) => void;
  onConfigurationDelete: (customerConfigurationId: ID<CustomerConfiguration>) => void;
  onConfigurationSave: () => void;
  onOpenWidget?: () => void;
  onCloseWidget?: () => void;
};
const GarageWidget: React.FunctionComponent<Props> = props => {
  const {
    className,
    customerConfigurations,
    visible,
    isConfigurationSaveable,
    hideSaveButton,
    isClosable,
    onConfigurationSelected,
    onConfigurationDelete,
    onCloseWidget,
    onConfigurationSave,
  } = props;

  const visibleTag = visible ? 'visible' : 'hidden';

  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const { scrolling } = useDragScroll(contentRef.current);

  const [hasScrollbar, setHasScrollBar] = React.useState<boolean>(false);
  const [isScrollToEndNextUpdate, SetIsScrollToEndNextUpdate] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (contentRef.current === null)
      return;

    const element = contentRef.current;
    const isScrollable = element.scrollWidth > element.clientWidth;
    setHasScrollBar(isScrollable);
    if (isScrollToEndNextUpdate) {
      scrollToEnd(element);
      SetIsScrollToEndNextUpdate(false);
    }
  }, [contentRef, customerConfigurations, isScrollToEndNextUpdate]);

  const onNewConfigurationAdded = () => {
    //configuration has to be added before scrolling to the end
    SetIsScrollToEndNextUpdate(true);
    onConfigurationSave();
  }


  const selectConfiguration = (customerConfigurationId: ID<CustomerConfiguration>) => {
    if (scrolling)
      return;

    onConfigurationSelected(customerConfigurationId);
  }

  const deleteConfiguration = (customerConfigurationId: ID<CustomerConfiguration>) => {
    if (scrolling)
      return;

    onConfigurationDelete(customerConfigurationId);
  }

  function scrollToEnd(scrollContainer: HTMLElement | null) {
    if (scrollContainer === null)
      return;

    scrollContainer.scrollLeft = scrollContainer.scrollWidth;
  }

  return (
    <div className={`${className} ${visibleTag}`}>
      {isClosable &&
        <WithBlendedUnderlay className={`garage-toggle-action`} onClick={onCloseWidget}>
          {onCloseWidget &&
            <ActionIconButton
              className={`icon-chevron-up-thicker close-action ${visibleTag}`}
              onClick={onCloseWidget}
            />}
        </WithBlendedUnderlay>
      }
      <section className="garage-widget" ref={contentRef}>
        <ol className="configuration-list" id="garage">
          {customerConfigurations.map(c => (
            <CustomerConfigurationItem
              key={c.id}
              customerConfiguration={c}
              onConfigurationSelected={selectConfiguration}
              onConfigurationDelete={deleteConfiguration}
            />
          ))}
          {!hideSaveButton &&
            <NewConfigurationItem isActive={isConfigurationSaveable} isSticky={hasScrollbar} onNewConfigurationAdded={onNewConfigurationAdded} />
          }
        </ol>
      </section>
      {visible && <div className={`outside-overlay`} onClick={onCloseWidget} />}
    </div>
  );
};
export default styled(GarageWidget)`

  > .garage-toggle-action {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 12px;
    right: 10px;
    z-index: 10;
    cursor: pointer;
    
    span.blended-underlay {
      position: absolute;
      width: 35px;
      height: 35px;
      background-color: black;
      mix-blend-mode: unset;
    }

    button.close-action {
      width: 17px;
      height: 17px;
      background-color: white;
    }
  }
  
  .garage-widget {
    height: 160px;
    background: rgba(220,220,220,0.6);
    
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: 2px;
    -webkit-scrollbar-x-position: top;
    
    &::-webkit-scrollbar {
      height: 4px !important;
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: white;    /* color of the scroll thumb */
      border-radius: 10px;       /* roundness of the scroll thumb */
      border: 0px solid transparent;  /* creates padding around scroll thumb */
    }
    
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex: 1;
      
      > li {
        flex-shrink: 0;
        width: 220px;
        height: 160px;
        
        &:not(:first-child) {
          margin-left: 0px;
        }
      }
    }
  }
    
    &.hidden {
    transform: translateY(-260px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

    button.close-action.hidden {
      transform: translateY(0px);
      transform 300ms ease-in-out;
    }
  }

  &.visible {
    transform: translateY(0px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

    button.close-action.visible {
      transform: translateY(0px);
      transform 300ms ease-in-out;
    }
  }

`;
