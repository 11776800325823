import { AppConfiguration } from "../app/AppConfiguration";
import { CustomerConfiguration, ID } from "../../shared/model/RawModel";
import { toast } from "react-toastify";
import { toastOptions } from "./Toast";

export async function notifyServer(
    url : string,
    callback? : (isSuccessful:boolean)=>void
  ) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const jsonBody = await response.json();
      if(callback) callback(response.ok);
      if (!response.ok) {
        throw new Error(jsonBody.message);
      }

    } catch (e) {
      console.error(e);
    }
  }

  export function actionExportPDF(id:ID<CustomerConfiguration>, callback? : (isSuccessful:boolean)=>void)
  {
    toast("Exporting PDF. This may take several minutes.", toastOptions);

    if(AppConfiguration.isWebVersion)
    {
      exportPDFForWeb(id, callback);
    }
    else
    {
      exportPDFForElectron(id, callback);
    }
  }
  function exportPDFCallback(isSuccessful:boolean,  callback? : (isSuccessful:boolean)=>void)
  {
    const message = isSuccessful ? 'PDF is exported' : 'PDF export failed';

    if(isSuccessful)
      toast(message, toastOptions);
    else 
      toast.error(message, toastOptions);

    if(callback) callback(isSuccessful);
  }
  function exportPDFForElectron(id:ID<CustomerConfiguration>, callback? : (isSuccessful:boolean)=>void)
  {
    //create command url
    const url = `${AppConfiguration.baseServerUrl}/api/pdf?configurationID=${id}`;
    
    //send to server
    notifyServer(url, (isSuccessful:boolean)=>
    {
      exportPDFCallback(isSuccessful, callback);
    });
  }

  function exportPDFForWeb(id:ID<CustomerConfiguration>, callback? : (isSuccessful:boolean)=>void)
  {
     const url = `${AppConfiguration.baseServerUrl}/api/pdfweb?configurationID=${id}`;
     window.open(url);
     exportPDFCallback(true, callback);
  }
