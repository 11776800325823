import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../../shared/model/FeatureSet';
import { Feature, ID, Option } from '../../../../../../shared/model/RawModel';
import FeatureSettingsDropdown from './FeatureSettingsDropdown';
import FeatureSettingsToggle from './FeatureSettingsToggle';

type Props = {
  className?: string;
  features: ReadonlyArray<FeatureObject>;
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  onResetFeatureToPreset: (featureId: ID<Feature>) => void;
  filterOptions: (feature: FeatureObject) => OptionObject[];
};

const FeatureSettingsRegionArea: React.FunctionComponent<Props> = props => {
  const { features, onSelectOption, onResetFeatureToPreset, filterOptions } = props;
  const switchWidget = (feature: FeatureObject, index: number) => {
    const options = filterOptions(feature);
    feature.uiComponent = options.length === 2 ? 'optionToggle' : feature.uiComponent;
    const widgetProps = {
      key: index,
      feature: feature,
      options: options,
      onSelect: (optionId: ID<Option>) => {
        onSelectOption(feature.id, optionId);
      },
      onResetToPreset: () => {
        onResetFeatureToPreset(feature.id);
      },
    };
    switch (feature.uiComponent) {
      case 'optionDropdown':
        return <FeatureSettingsDropdown {...widgetProps} />;
      case 'optionToggle':
        return <FeatureSettingsToggle {...widgetProps} />;
      default:
        return <section />;
    }
  };
  return <span className={props.className}>{features.map((f, i) => switchWidget(f, i))}</span>;
};
export default styled(FeatureSettingsRegionArea)``;
