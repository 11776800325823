import { debounce } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { CustomerWishObject, CustomerWishSetResponse } from '../../../../../../shared/model/CustomerWishSet';
import { FeatureSet, ID } from '../../../../../../shared/model/RawModel';
import { AppConfiguration } from '../../../../../app/AppConfiguration';
import { ModificationState, modifyItem } from '../../../../../components/modifyItem';
import { useItemFromUrl } from '../../../../../components/useItemsFromServer';
import CustomerWishAddItem from './CustomerWishAddItem';
import CustomerWishItem from './CustomerWishItem';

type Props = {
  className?: string;
  isVisible: boolean;
  filterContent: boolean;
  filterFeatureSetId?: ID<FeatureSet>;
  featureSetTitle?: string;
  onCloseWishView?: () => void;
  onWishCountChanged?: (wishCount: number) => void;
};

const updateCustomerWishes = async (
  url: string,
  value: CustomerWishObject[] | undefined,
  setModificationState: (state: ModificationState) => void
) => {
  if (!value) return;

  const body = JSON.stringify({ customerWishes: value });
  modifyItem(url, 'PATCH', body, setModificationState);
};
const debouncedUpdateCustomerWishes = debounce(updateCustomerWishes, 1000);

const CustomerWishesView: React.FunctionComponent<Props> = props => {
  const { isVisible, filterContent, filterFeatureSetId, featureSetTitle: featureTitle, onWishCountChanged } = props;
  const { customerConfigurationId } = useParams();
  const [modificationState, setModificationState] = React.useState<ModificationState>({
    error: null,
    reloadTrigger: false,
  });

  const { item: customerWishesSetResponse, error: loadError, isLoading } = useItemFromUrl<CustomerWishSetResponse>(
    `${AppConfiguration.baseServerUrl}/api/customer-wish-set/${customerConfigurationId}`,
    modificationState.reloadTrigger,
    isVisible
  );
  const modificationUrl = `${AppConfiguration.baseServerUrl}/api/customer-configurations/${customerConfigurationId}`;

  const [wishes, setWishes] = React.useState<CustomerWishObject[] | undefined>(undefined);
  const [wishCount, setWishCount] = React.useState<number | undefined>(undefined);
  const [previousVisibility, setPreviousVisibility] = React.useState<boolean>(false);

  React.useEffect(() => {
    wishCount !== undefined && onWishCountChanged && onWishCountChanged(wishCount);
    if (isVisible && !previousVisibility) {
      setWishes(undefined);
    }
    setPreviousVisibility(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishCount, filterFeatureSetId, isVisible]);

  if (loadError) {
    return (
      <div/>
    );
  }

  if (!isLoading && wishes === undefined && customerWishesSetResponse && customerWishesSetResponse.customerWishes) {
    setWishes(customerWishesSetResponse.customerWishes);
  }

  const wishChanged = (index: number, value: string) => {
    if (!wishes) return;

    const clonedWishes = Object.assign([], wishes) as CustomerWishObject[];
    const i = clonedWishes.findIndex(w => w.index === index);
    if (i < 0) return;

    clonedWishes[i].wish = value;
    setWishes(clonedWishes);
    debouncedUpdateCustomerWishes(modificationUrl, clonedWishes, setModificationState);
  };

  const wishAdded = () => {
    if (!wishes) return;

    let clonedWishes = Object.assign([], wishes) as CustomerWishObject[];
    const newWish = {
      wish: '',
      title: featureTitle ? featureTitle : '',
      //give random index to prevent duplicates
      index: Math.round(Math.random() * 255),
      featureSetId: filterFeatureSetId ? filterFeatureSetId : undefined,
    };

    clonedWishes.push(newWish);
    setWishes(clonedWishes);
    wishCount !== undefined && setWishCount(wishCount + 1);
    debouncedUpdateCustomerWishes(modificationUrl, clonedWishes, setModificationState);
  };

  const wishDeleted = (index: number) => {
    if (!wishes) return;

    const clonedWishes = Object.assign([], wishes) as CustomerWishObject[];
    const i = clonedWishes.findIndex(w => w.index === index);
    if (i < 0) return;

    clonedWishes.splice(i, 1);
    setWishes(clonedWishes);
    wishCount !== undefined && setWishCount(wishCount - 1);
    debouncedUpdateCustomerWishes(modificationUrl, clonedWishes, setModificationState);
  };

  const viewHeader = filterContent ? 'Add a special customer wish' : 'special customer wishes';
  const filteredWishes = wishes
    ? filterContent
      ? wishes.filter(w => w.featureSetId === filterFeatureSetId)
      : wishes
    : undefined;
  if (wishCount === undefined && filteredWishes) setWishCount(filteredWishes.length);

  return (
    <div className={props.className}>
      <div className="wish-list-container">
        <h2 className="wish-list-header">{viewHeader}</h2>
        <ol>
          {filteredWishes &&
            filteredWishes.map((cw, index) => (
              <CustomerWishItem
                customerWish={cw}
                key={cw.index}
                onValueChanged={v => wishChanged(cw.index, v)}
                onDeleted={() => wishDeleted(cw.index)}
                isFocusedOnStart={index === filteredWishes.length - 1}
              />
            ))}
          <CustomerWishAddItem onAdd={wishAdded} />
        </ol>
      </div>
    </div>
  );
};

export default styled(CustomerWishesView)`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    height: 6px; /* height of the entire scrollbar */
    width: 6px; /* width of the entire scrollbar */
    background: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent !important;
    -webkit-appearance: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.colors.scrollbarOnDark}; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 0px solid transparent; /* creates padding around scroll thumb */
  }


  .wish-list-container {
    margin: 20px 78px;

    h2.wish-list-header,
    li {
      margin-bottom: 2px;
    }

    ol {
      margin: 0;
      padding: 0;
    }

    li {
      display: flex;
      background: ${p => p.theme.colors.onboardingBackgroundBright};
    }

    li:first-child {
      border-radius: 12px 12px 0 0;
    }

    li:last-child {
      border-bottom: none;
      border-radius: 0 0 12px 12px;
      margin-bottom: 100px;
    }

    h2.wish-list-header {
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      line-height: 65px;
      color: ${p => p.theme.colors.onboardingText};
    }


    .text {
      flex-shrink: 0;
      flex-grow: 1;
      display: flex;
      background-color: none;
      border: none;
      color: ${p => p.theme.colors.onboardingText};
    }
  }
`;
