import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject } from '../../../../../shared/model/FeatureSet';
import { Feature, ID, Option } from '../../../../../shared/model/RawModel';

type Props = {
  className?: string;
  onSelection: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  feature?: FeatureObject;
};
const SidebarDropdown: React.FunctionComponent<Props> = props => {
  const { feature, onSelection } = props;

  if (!feature) return <li></li>;
  //no need to filter options since it is a special case
  const options = feature.optionSetObjects.flatMap(o => o.options);

  const selectedValueCandidate = options.find(
    o => o.status === 'selected-by-user' || o.status === 'selected-from-preset'
  );
  const selectedValue = selectedValueCandidate ? selectedValueCandidate.optionId : options[0].optionId;

  return (
    <li className={props.className}>
      <label>{feature.title}</label>
      <span className="select-wrapper">
        <select value={selectedValue} onChange={e => onSelection(feature.id, e.target.value)}>
          {options.map((o, i) => (
            <option value={o.optionId} key={i}>
              {o.title}
            </option>
          ))}
        </select>
      </span>
    </li>
  );
};
export default styled(SidebarDropdown)`
  padding-top: 2px;
  padding-right: 20px;
  border-bottom: 1px solid ${p => p.theme.colors.onboardingBackgroundBright};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  label {
    line-height: 47px;
    font-size: 18px;
    font-family: 'ZwoLF SemiBold';
    text-transform: uppercase;
  }

  select {
    height: 47px;
  }
  
  span.select-wrapper {
    display: flex;
    flex-direction: row;
    
    select {
      padding-left: 4px;
      padding-right: 4px;
      border: none;
      border-radius: 0;
      color: ${p => p.theme.colors.onboardingText};
      font-size: 18px;
      text-transform: uppercase;
      background: none;

      option {
        color:  ${p => p.theme.colors.onboardingBackground};
        appearance: auto;
        direction: ltr;
        padding: 4px;
      }
    }

    &:after {
      content: ' ';
      position: relative;
      right: 6px;
      top: 0.3em;
      min-width: 2em;
      height: 2em;
      mask-image: url('./icons/chevron-down-thicker.svg');
      mask-repeat: no-repeat;
      mask-position: 50% 50%;
      mask-size: 50% 50%;
      background-color: ${p => p.theme.colors.onboardingText};
    }

  }
`;
