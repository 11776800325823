/**
 * nominal type to disallow assigning mismatching ID types
 * this is a compile time only type and has no effect at runtime
 */
export type ID<T> = string & {
  __internal?: T;
};

export type ThemeNameEnum = 'bright' | 'dark';

/**
 * A specific car model, eg. the Chiron R or Chiron Sport
 */
export type CarModel = {
  /// unique id
  id: ID<CarModel>;
  /// the display name
  title: string;
  /// the model description
  description: string;

  /// the list of presets available for this car model
  presetIds: ID<Preset>[]; // NOTE: relation is stored twice, here and in `Preset`
  /// the default preset id as to be displayed when picking the car model
  defaultPresetId: ID<Preset>;
  /// the view ids in display order, as to be displayed on the feature page
  flowViewIds: ID<View>[];
  /// tbd
  carGeometry: string;
  /// the name of the ui theme
  theme: ThemeNameEnum;

  prefix: string;

  actions : Action[];
};

/**
 * A dictionary connecting `Feature`s with their selected `Option`s.
 * Used in `CustomerConfiguration` and `Preset`.
 * Note: A ecma script object can only have string, symbol and number keys, the typing
 *       is not completely safe with our nominal ID type
 */
export type OptionChoice = { [key: string /*ID<Feature>*/]: ID<Option> };

/**
 * A customer wish as a free text.
 * It might be connected to a certain feature
 */

export type CustomerWish = {
  /// the saved wish text
  wish: string;
  /// related feature set Id
  featureSetId: ID<FeatureSet>;
};

/**
 * A preset of a car model, adding the predefined `OptionChoice`s
 */
export type Preset = {
  /// unique id
  id: ID<Preset>;
  /// the display name
  title: string;
  /// the selected choices
  choices: OptionChoice;
};

export interface CustomerResponse 
{
  customer               : Customer;
  configurationCountries : ID<Option>[];
  countryVersion         : string;
};

/**
 * A customer for whom configuration can be saved
 */
export type Customer = {
  /// unique id
  id: ID<Customer>;
  /// the display name
  name: string;
  /// the customer configurations of this user
  configurationIds: ID<CustomerConfiguration>[]; // NOTE: relation is stored twice, here and in `CustomerConfiguration`
};

/**
 * A car configuration made by a customer
 */
export type CustomerConfiguration = {
  /// unique id
  id: ID<CustomerConfiguration>;
  /// the car model
  carModelId: ID<CarModel>; // NOTE: relation is stored twice, here and in `Preset` when following `basePresetId`
  /// the preset this configuration takes its defaults from
  basePresetId: ID<Preset>;
  /// the last view the customer was in
  currentViewId: ID<View>;
  /// the selected choices, will be applied on top of the preset choices
  choices: OptionChoice;
  /// the confirmed feature sets, that are shown as configured in the front end.
  confirmedFeatureSets: ID<FeatureSet>[];
  /// the last time the document was modified - UTC timestamp in milliseconds
  lastModifiedAt: number;
  /// the sort index for the garage page
  garageSortIndex: number;
  /// the list of customer wishes
  customerWishes: CustomerWish[];
  /// tbd
  inscription: string;

  /// reloadtrigger
  reloadTrigger: number,
};

/**
 * Allowed UI container types
 */
export type FeatureUiContainerEnum =
  | 'optionsArea'
  | 'featureSettingsRegion'
  | 'hiddenFeaturePanel'
  | 'onOffToggleInFeatureSettingsRegion'
  | 'fullscreen';

/**
 * Allowed UI component types
 */
export type FeatureUiComponentEnum =
  | 'scrollableListPicker'
  | 'optionToggle'
  | 'optionDropdown'
  | 'dedication-plate'
  | 'customer-wishes'
  | 'additional-options'
  | 'environmentDropdown';

/**
 * the allowed layout types
 */
export type FeatureSetLayouts = 'featureAtBottom' | 'sidePanel' | 'fullscreen';

/**
 * A feature that can be configured by the user
 */
export type FeatureSet = {
  /// unique id
  id: ID<FeatureSet>;
  /// the display name
  title: string;
  /// the sort index for the feature list page
  sortIndex: number;
  /// the layout to use for this feature group
  layout: FeatureSetLayouts;
  /// the child features
  featureIds: ID<Feature>[];
  /// optional, allowed camera view that the feature can also be displayed in orthogonal to the features that are listed in a view
  viewIDOverride?: ID<View>;
};

/**
 * A feature that can be configured by the user
 */
export type Feature = {
  /// unique id
  id: ID<Feature>;
  /// the display name
  title: string;
  /// the sort index for the feature list page
  sortIndex: number;
  /// tbd
  uiContainer: FeatureUiContainerEnum;
  /// tbd
  uiComponent: FeatureUiComponentEnum;
  /// the available options for this feature,that can be filtered by other features in the same main feature
  optionSets: OptionSet[];
};

/**
 * An option set that can be filtered by other features
 */
export type OptionSet = {
  /// the filter feature that contains filter option 
  filterFeatureId: ID<Feature> ;
  /// the filter option that matches options
  filterOptionId: ID<Option> ;
  /// list of options that matches
  options: ID<Option>[];
};


/**
 * An option that can be chosen for a feature
 */
export type Option = {
  /// unique id
  id: ID<Option>;
  /// the display name
  title: string;
  /// tbd
  texture?: string;
  /// the sort order of the option within the feature view
  sortIndex: number;
  /// override show label logic
  hideLabel?: boolean;
};

export const HiddenViewTitle = 'hidden';

/**
 * A view from which the car can be inspected. Contains a list of features to be configured in this view.
 */
export type View = {
  /// unique id
  id: ID<View>;
  /// the display name
  title: string;
  // cameraID
  cameraID: string;
  // light prnummer
  light: string;
  // is visible int camera control
  showInTVMenu: boolean,
  //cameraPoints
  cameraPoints: CameraPoint[],
  /// the features that are listed within this view, orthogonal to the views that a feature can be shown in
  featureSetIds: ID<FeatureSet>[];
};

export type CameraPoint = {
  /// unique id
  id: ID<View>;
  /// the display name
  title: string;
  /// position on the path
  position: number;
};

/**
 * The whole database, as defined in mock/data.json
 */
export type CarConfiguratorDataBase = {
  /// the current revision of the database
  revision                : number;
  carModels               : CarModel[];
  presets                 : Preset[];
  customers               : Customer[];
  customerConfigurations  : CustomerConfiguration[];
  featureSets             : FeatureSet[];
  features                : Feature[];
  options                 : Option[];
  views                   : View[];
  hashLen                 : number;
  hashSalt                : string;
  longOptionToshortOption : {[key:string]:string};
};

export type ActionStateData =
{
	state              : number,
	additionalFeatures : string[],
}

export type Action = {
	id                : ID<Action>,
	title             : string,
	state             : number,
	stateData         : ActionStateData[],
};
