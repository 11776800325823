import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../shared/model/FeatureSet';
import { ID, Option } from '../../../../../shared/model/RawModel';
import ActionButton from '../../../../components/ActionButton';

type Props = {
  className?: string;
  feature: FeatureObject;
  options: ReadonlyArray<OptionObject>;
  onSelect: (optionId: ID<Option>) => void;
  onResetToPreset: () => void;
};

const ToggleWidget: React.FunctionComponent<Props> = props => {
  const { feature, options, onSelect, onResetToPreset, className } = props;

  if (options.length !== 2) {
    throw new Error('Options for a toggle must be exactly two');
  }

  const contentRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const contentNode = contentRef.current;
    if (contentNode === null) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef]);

  const first = options[0];
  const second = options[1];
  const isDisabled = first.status === 'disabled' || second.status === 'disabled';
  const disabledReason = first.reasonForDisabled || second.reasonForDisabled;
  const isSecondSelected = second.status === 'selected-from-preset' || second.status === 'selected-by-user';

  const inputChanged: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.checked) {
      onSelect(second.optionId);
    } else {
      onSelect(first.optionId);
    }
  };

  const classNames = [className, isDisabled && 'disabled'].filter(Boolean).join(' ');

  return (
    <section className={classNames}>
      <h3>{feature.title}</h3>
      <span onClick={() => onSelect(first.optionId)} className={isSecondSelected ? 'unselected' : 'selected'}>
        {first.title}
      </span>
      <input type="checkbox" disabled={isDisabled} checked={isSecondSelected} onChange={inputChanged} />
      <span onClick={() => onSelect(second.optionId)} className={isSecondSelected ? 'selected' : 'unselected'}>
        {second.title}
      </span>
      {isDisabled && <div>{disabledReason}</div>}
      <ActionButton onClick={onResetToPreset}>Reset</ActionButton>
    </section>
  );
};

export default styled(ToggleWidget)`
  > h3 {
    display: inline-block;
    margin-right: 10px;
  }

  /*  disable clicking on spans */
  > .disabled {
    > span {
      pointer-events: none;
    }
  }

  > span {
    cursor: pointer;
  }

  > span.unselected {
    font-weight: 200;
  }

  > span.selected {
    font-weight: 500;
  }
`;
