import * as React from 'react';
import styled from 'styled-components';
import ItemSwitcher from '../../components/ItemSwitcher';
import { useSwipeAndClick, SwipeDirectionEnum } from '../../components/useSwipeAndClick';
import { useEffect } from 'react';

type Props = {
    className?: string;
    areChevronsVisible: boolean;
    activatable: boolean;
    onToggleChevrons: () => void;
    hasNextView: boolean;
    hasPreviousView: boolean;
    hasNextFeatureView: boolean;
    hasPreviousFeatureView: boolean;
    onSelectNextView: () => void;
    onSelectPreviousView: () => void;
    onSelectNextFeatureView: () => void;
    onSelectPreviousFeatureView: () => void;
};

const ViewNavigator: React.FunctionComponent<Props> = props => {
    const {
        className,
        areChevronsVisible,
        activatable,
        onToggleChevrons,
        hasNextView,
        hasPreviousView,
        hasNextFeatureView,
        hasPreviousFeatureView,
        onSelectNextView,
        onSelectPreviousView,
        onSelectNextFeatureView,
        onSelectPreviousFeatureView,
    } = props;
    const swipeAreaRef = React.useRef<HTMLDivElement | null>(null);
    const [swipe, setSwipe] = React.useState<SwipeDirectionEnum | null>(null);

    useEffect(() => {
        switch (swipe) {
            case 'left':
                hasNextView && onSelectNextView();
                break;

            case 'right':
                hasPreviousView && onSelectPreviousView();
                break;
        }
        setSwipe(null);
    }, [hasNextView, hasPreviousView, onSelectNextView, onSelectPreviousView, swipe]);

    const onClicked = () => {
        onToggleChevrons();
    }
    React.useRef(useSwipeAndClick(swipeAreaRef.current, setSwipe, onClicked));

    const interactionAreaClassName = `${activatable ? 'active' : 'inactive'} interaction-area`;

    return (
        <div className={className}>
            <div
                className={interactionAreaClassName}
                ref={swipeAreaRef}
            />

            <ItemSwitcher
                visible={areChevronsVisible && (hasNextView || hasPreviousView)}
                hasPrevious={hasPreviousView}
                hasNext={hasNextView}
                onSelectPrevious={onSelectPreviousView}
                onSelectNext={onSelectNextView}
            />
            <ItemSwitcher
                visible={Boolean(
                    !areChevronsVisible &&
                    (hasNextFeatureView || hasPreviousFeatureView)
                )}
                hasPrevious={hasPreviousFeatureView}
                hasNext={hasNextFeatureView}
                onSelectPrevious={onSelectPreviousFeatureView}
                onSelectNext={onSelectNextFeatureView}
            />
        </div >
    );
};

export default styled(ViewNavigator)`
.interaction-area {
    position: absolute;
    margin-top: 15vh;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 10vh;
    width: 80vw;
    height: 80vh;
    z-index: 1;

    &.active {
        pointer-events: auto;
    }

    &.inactive {
        pointer-events: none;
    }
}
`;