import * as React from 'react';
import styled from 'styled-components';
import { useDragJoystick } from '../../../components/useDragJoystik';

type Props = {
  className?: string;
  canMoveInX?: boolean;
  canMoveInY?: boolean;
  onOrbitChanged?: (dX: number, dY:number) => void,
};

function easeInQuint(x: number): number {
  return x * x * x * x * x;
}

const CameraJoystick: React.FunctionComponent<Props> = props => {
  const { className, canMoveInX, canMoveInY, onOrbitChanged } = props;

  const dragContainerRef = React.useRef<HTMLDivElement | null>(null);
  const knobContainerRef = React.useRef<HTMLDivElement | null>(null);

  const isVisible = canMoveInX || canMoveInY;
  const visibleClassName = isVisible ? 'show' : 'hide';

  const canMove = {
    x: canMoveInX ? canMoveInX : false,
    y: canMoveInY ? canMoveInY : false
  };

  const onDeltaChanged = (dX: number, dY: number) => {
    if (onOrbitChanged)
      onOrbitChanged(easeInQuint(dX), easeInQuint(dY));
  }

  let delta = useDragJoystick(dragContainerRef.current, knobContainerRef.current, canMove.x, canMove.y);

  if (Math.abs(delta.x) > 0.01 || Math.abs(delta.y) > 0.01)
  {
    onDeltaChanged(delta.x, delta.y);
  }

  return (
    <div className={`${className} joystick ${visibleClassName}`}>
      <div className="wrapper">
        <div className="background" ref={dragContainerRef}>
          <div className="background-image"></div>
        </div>
        <div className="knob" ref={knobContainerRef} />
      </div>
    </div>
  );
};

export default styled(CameraJoystick)`
  width: 150px;
  height: 150px;
  transition: opacity 300ms;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }

  .wrapper {
    position: absolute;
    left: 30px;
    bottom: -10px;
    width: 130px;
    height: 130px;

    .background {
      position: relative;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 1px dotted rgba(255, 255, 255, 1);

      > .background-image {
        position: relative;
        content: '';
        width: 130px;
        height: 130px;
        top: -20px;
        right: -20px;
        bottom: -20px;
        left: -20px;
        opacity: 0.5;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    .knob {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: white;
      opacity: 1;
      pointer-events: none;
    }
  }
`;
