import * as React from 'react';
import styled from 'styled-components';

type Props = {
    className?: string;
    visible: boolean;
    progress: number;
};

const LoadingOverlay: React.FunctionComponent<Props> = props => {
  const {className, visible, progress} = props;
  const processedProgress = visible ? progress : 100;
  const radius = 250;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - processedProgress / 100 * circumference;

  const classNames = `state-${visible?'visible':'hidden'} ${className}`;
  
  return (
      <div className={classNames}>
          <svg
              className="progress-ring"
              width="100vw"
              height="100vh">
              <circle
                  className="progress-ring__circle"
                  stroke="white"
                  strokeWidth="2"
                  fill="transparent"
                  r={radius}
                  cx="50.5vw"
                  cy="50vh"
                  strokeDasharray={`${circumference}px ${circumference}px`}
                  strokeDashoffset={offset} />
          </svg>
          <div className="label-container">
            <p className="label">
                one moment please
            </p>
          </div>
      </div>);
};


export default styled(LoadingOverlay)`

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  background-color: rgb(0, 0, 0);
  transition: opacity 1s;

&.state-visible{
  opacity: 0.6;
}

&.state-hidden{
  opacity: 0;
  pointer-events: none;
}

.label-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    color: white;
    padding: 17px;
    font-size: 28px;
    text-transform: uppercase;
    font-family: 'ZwoLF Bold', sans-serif;
  }
}



.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  
}         

`;