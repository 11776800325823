import * as React from 'react';
import styled from 'styled-components';

import { FeatureSetObject } from '../../../../../shared/model/FeatureSet';
import { Feature, ID, Option } from '../../../../../shared/model/RawModel';
import CustomerWishesWidget from './CustomerWish/CustomerWishesWidget';
import DedicationPlateWidget from './DedicationPlateWidget';

type Props = {
  className?: string;
  visible: boolean;
  activeFeatureSet?: FeatureSetObject;
  onClose: () => void;
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  onResetFeatureToPreset: (featureId: ID<Feature>) => void;
  hasPreviousFeature?: boolean;
  hasNextFeature?: boolean;
  onSelectPreviousFeature?: () => void;
  onSelectNextFeature?: () => void;
};
const FullscreenLayout: React.FunctionComponent<Props> = props => {
  const { className, visible, activeFeatureSet, onClose } = props;
  const fullscreenAreaFeature = activeFeatureSet
    ? activeFeatureSet.featureObjects.find(f => f.uiContainer === 'fullscreen')
    : undefined;
  const uiComponent = fullscreenAreaFeature ? fullscreenAreaFeature.uiComponent : '';
  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');

  return (
    <div className={classNames}>
      <DedicationPlateWidget isVisible={visible && uiComponent === 'dedication-plate'} {...props} />
      <CustomerWishesWidget
        isVisible={visible && uiComponent === 'customer-wishes'}
        filterContent={false}
        onCloseWishView={onClose}
        overrideHeader={activeFeatureSet?.title}
        {...props}
      />
    </div>
  );
};
export default styled(FullscreenLayout)``;
