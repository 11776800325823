import { useState, useEffect } from "react"
import { ID, CustomerConfiguration, Customer } from "../../../shared/model/RawModel";
import { loadConfigurationToActiveSlot, saveConfiguration, deleteConfiguration } from "../../components/activeSlot";
import { useItemFromUrl } from "../../components/useItemsFromServer";
import { AppConfiguration } from "../../app/AppConfiguration";
import { ModificationState } from "../../components/modifyItem";


export const useGarageLogic = (customerId: ID<Customer>, setModificationState?: (state: ModificationState) => void, setActiveSlotLastLoadedTimestamp?: (timestamp: number) => void) => {
    const [garageItemCount, setGarageItemCount] = useState<number>(0);
    const [listedGarageConfigurations, setListedGarageConfigurations] = useState<ReadonlyArray<CustomerConfiguration> | undefined>(undefined);

    const { item: configurationList } = useItemFromUrl<ReadonlyArray<CustomerConfiguration>>(
        `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/configurations` 
        ,garageItemCount
      );

    useEffect(()=>{
        if(!configurationList)
          return;
        
        const configurationListLength = configurationList.length;
        if(configurationListLength > 0)
        {
            const configurationListWithoutZeroIndex = configurationList.slice(1);
            setListedGarageConfigurations(configurationListWithoutZeroIndex);
        }
        setGarageItemCount(configurationList.length-1);
        
    },[configurationList])

    const onConfigurationSelected = (customerConfigurationId: ID<CustomerConfiguration>) => {
        const modificationStateSetter = setModificationState? setModificationState : (state: ModificationState) =>{};
        loadConfigurationToActiveSlot(customerId, customerConfigurationId, modificationStateSetter, id => {
            if(setActiveSlotLastLoadedTimestamp) 
                setActiveSlotLastLoadedTimestamp(Date.now());
            });
    };
    const onConfigurationSave = () => {
        saveConfiguration(customerId, (isSuccessful => {
            if(isSuccessful) setGarageItemCount(garageItemCount + 1)}
            ));
    };

    const onConfigurationDelete = (customerConfigurationId: ID<CustomerConfiguration>) => {
        deleteConfiguration(customerId, customerConfigurationId, (isSuccessful => {
            if (isSuccessful) setGarageItemCount(garageItemCount - 1)}
        ));
    }

    return {
        listedGarageConfigurations,
        onConfigurationSelected,
        onConfigurationDelete,
        onConfigurationSave,
    }
}