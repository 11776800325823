import * as React           from 'react';
import styled               from 'styled-components';
import { AppConfiguration } from '../../../app/AppConfiguration';
import { OptionObject     } from '../../../../shared/model/FeatureSet';
import { ID, Option       } from '../../../../shared/model/RawModel';

type Props = {
    className?: string;
    option: OptionObject;
    onSelect: (optionId: ID<Option>) => void;
}

const defaultOptionImageUrl = './images/placeHolderOption.png';

const EnvironmentSwitchItem: React.FunctionComponent<Props> = props => {
    const { className, option, onSelect } = props;
    const textureUrl = `${AppConfiguration.baseServerUrl}${option.texture}`;
    
    const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);
    const [optionImageUrl, setOptionImageUrl] = React.useState<string>(textureUrl);


    const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setIsImageLoaded(true);
    }
    
    const onImageError= (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setOptionImageUrl(defaultOptionImageUrl);
      setIsImageLoaded(true);
    }

    const isSelected = option.status === 'selected-by-user' || option.status === 'selected-from-preset';
    const selectedClassName = isSelected ? 'selected' : '';
    const classNames = `${className} ${selectedClassName}`;

    const imageClassName = `option-image ${isImageLoaded ? 'fade-in' : ''}`;

      
    return (
    <div className={classNames}>
      <img 
        className={imageClassName}
        src={optionImageUrl}
        onClick={() => onSelect(option.optionId)}
        onLoad={onImageLoaded}
        onError={onImageError}
        alt=""/>
    </div>);
}

export default styled(EnvironmentSwitchItem)`
    position: relative;
    height: 72px;
    width: 72px;
    flex: none;
    order: 2;
    flex-grow: 0;
  
  img {
    position: absolute;
    height: 56px;
    width: 56px;
    left: 11.11%;
    right: 11.11%;
    top: 11.11%;
    bottom: 11.11%;
    border-radius: 50%;
    opacity: 0.5;
    cursor: pointer;
  }

  &.selected {
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
    > img {
      width: 72px;
      height: 72px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }
  }

  
  .option-image {
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: all 0s ease-out;
    
    &.background {
      background-color: black;
    }

    &.fade-in {
      transition: all 100ms ease-out;
    }
  }
`;