import React from 'react';
import { useLocation } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { Routes } from 'react-router-dom';

type Props = {
  className?: string;
  children?: React.ReactNode;
  transitionTimeout: number | { appear?: number; enter?: number; exit?: number };
  animationName: string;
};

const AnimatedSwitch: React.FunctionComponent<Props> = props => {
  const { className, children, transitionTimeout, animationName } = props;
  const location = useLocation();

  return (
    <div className={className}>
      <TransitionGroup component={null} enter={true} exit={true}>
        <CSSTransition key={location.pathname} timeout={transitionTimeout} classNames={`${animationName}`}>
          <Routes location={location}>{children}</Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default styled(AnimatedSwitch)`
  overflow: hidden;

  /* ------------- FADE IN ------------- */

  .fade-in-enter {
    opacity: 0;
  }

  .fade-in-enter-active {
    opacity: 1;
    transition: opacity
      ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 1500 : p.transitionTimeout)}ms
      ease-in-out;
  }

  .fade-in-exit {
    opacity: 1;
  }

  .fade-in-exit-active {
    opacity: 0;
    transition: opacity
      ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 500 : p.transitionTimeout)}ms
      ease-in-out;
  }

  /* ------------- SLIDE IN (FROM RIGHT) ------------- */

  .slide-in-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-in-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform
        ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 500 : p.transitionTimeout)}ms
        ease-in-out,
      opacity
        ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 500 : p.transitionTimeout)}ms
        ease-in-out;
  }

  .slide-in-exit {
    opacity: 1;
    transform: translateX(0);
  }

  .slide-in-exit-active {
    transform: translateX(-100%);
    opacity: 1;
    transition: transform
        ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.exit || 500 : p.transitionTimeout)}ms
        ease-in-out,
      opacity
        ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 500 : p.transitionTimeout)}ms
        ease-in-out;
  }

  /* ------------- NEW CUSTOMER: SLIDE IN FROM BOTTOM > FADE OUT------------- */

  .new-customer-enter {
    transform: translateY(100%);
    opacity: 0;
  }

  .new-customer-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform
        ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 500 : p.transitionTimeout)}ms
        ease-in-out,
      opacity
        ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 500 : p.transitionTimeout)}ms
        ease-in-out;
  }

  .new-customer-exit {
    transform: translateY(0);
    opacity: 1;
  }

  .new-customer-exit-active {
    opacity: 0;
    transition: opacity
      ${p => (typeof p.transitionTimeout === 'object' ? p.transitionTimeout.enter || 1000 : p.transitionTimeout)}ms
      ease-in-out;
  }
`;
