import { FeatureSetObject } from '../../shared/model/FeatureSet';
import { Feature, ID, Option, View } from '../../shared/model/RawModel';
import { ModificationState, modifyItem } from './modifyItem';

export const updateSelection = async (
  url: string,
  featureId: ID<Feature> | any,
  optionId: ID<Option> | null,
  setModificationState: (state: ModificationState) => void
) => {
  const body = JSON.stringify({ choices: { [featureId]: optionId } });
  return modifyItem(url, 'PATCH', body, setModificationState);
};


export const setFeatureSetAsConfigured = async (
  url: string,
  featureSetObject: FeatureSetObject,
  setModificationState: (state: ModificationState) => void
) => {
  const body = JSON.stringify({ confirmedFeatureSet: featureSetObject.id });
  return modifyItem(url, 'PATCH', body, setModificationState);
};

export const updateView = async (
  url: string,
  viewId: ID<View>,
  setModificationState: (state: ModificationState) => void
) => {
  const body = JSON.stringify({ currentConfigurationStep: viewId });
  return modifyItem(url, 'PATCH', body, setModificationState);
};