import React from 'react';
import styled from 'styled-components';

import { ViewObject } from '../../../shared/model/FeatureSet';
import ViewItem from './ViewItem';

type Props = {
  className?: string;
  visible: boolean;
  views: ReadonlyArray<ViewObject>;
  onSelect: (view: ViewObject) => void;
};

const FeatureViewHeader = (props: Props) => {
  const { className, views, visible, onSelect } = props;

  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');

  return (
    <ol className={classNames}>
      {views.map(v => (
        <ViewItem key={v.viewId} view={v} onViewSelected={view => onSelect(view)} />
      ))}
    </ol>
  );
};

export default styled(FeatureViewHeader)`
  top: 0px;
  margin: 0;
  padding: 0;
  padding-right: 46px;
  display: flex;
  align-items: center;
  justify-content: center;

  > li {
    &:not(:first-child) {
      margin-left: 1px;
    }
  }

  /* this is a very horrible animation, thou shalt not perform this!
   ... but blend-mode :/ */
  transition: top 300ms ease-in-out;

  &.hidden {
    top: -60px;
  }

  &.visible {
    top: 0px;
  }
`;
