import * as React from 'react';
import styled from 'styled-components';
import ActionIconButton from './ActionIconButton';
import ActionButton from './ActionButton';
import { loadConfigurationCodeToActiveSlot } from './activeSlot';
import { useParams, useNavigate, useLocation } from 'react-router';
import { ID, CustomerConfiguration } from '../../shared/model/RawModel';
import CodeWidgetErrorMessage from './CodeWidgetErrorMessage';


type Props = {
  className?: string;
  onCloseCallback: ()=>void;
}

const EnterCodeWidget: React.FunctionComponent<Props> = props => {
  const { className, onCloseCallback } = props;
  const { customerId, countryVersion } = useParams() as {customerId:string, countryVersion:string};
  const history = useNavigate();
  const location = useLocation();

  const codeInputRef = React.useRef<HTMLInputElement | null>(null);
  const [isFormValid, setFormValid] = React.useState<boolean>(false);
  const [invalidReason, setInvalidReason] = React.useState<string>("");
  const [inputCode, setInputCode] = React.useState<string>('');
  const [isWaitingForResponse, setIsWaitingForResponse] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = React.useState<string>('');

  const maxCodeLength = 8;

  React.useEffect(()=>{
    const inputField = codeInputRef.current;
    if(!inputField)
      return;
    function handlePasteEvent(event:ClipboardEvent) {
      let paste = event.clipboardData?.getData('text');
      if(!paste) 
        return;
      onCodeChange(paste);
    }
    inputField.addEventListener('paste', handlePasteEvent);
    return ()=>{
      inputField.removeEventListener('paste', handlePasteEvent);
    }
  },[]);

  const onClose = () => {
    onCloseCallback();
  }

  const onCodeChange = (code: string) => {
    const trimmedCode = TrimCode(code, maxCodeLength);
    const regex = new RegExp('[a-zA-Z]{8}');
    const isValid = regex.test(trimmedCode);
    const reason = isValid ? '' : 'incomplete code';
    setInvalidReason(reason);
    setFormValid(isValid);
    setInputCode(trimmedCode);
    setShowErrorMessage(false);
  }

  async function pasteFromClipboard() {
    const clipText = await navigator.clipboard.readText()
    onCodeChange(clipText);
  }

  function TrimCode(code: string, codeLength: number): string {
    return code.replace(/^[Bb][Uu]-/, '').replace(/\W|\d/, '').substring(0, codeLength);
  }

  const forwardToFeatureListPage = (configurationId: ID<CustomerConfiguration>) => {
    setIsWaitingForResponse(false);
    setShowErrorMessage(false);
    const url = `/features/${customerId}/${configurationId}/${countryVersion}`;
    if(location.pathname === url)
    {
      window.location.reload();
    }
    else
    {
      history(`/features/${customerId}/${configurationId}/${countryVersion}`);
    }
  }

  const displayErrorMessage = (errorMessage: string, status?:number) =>{
    const isItUsersFault = status === 400;
    const isFormValid = !isItUsersFault;

    setInvalidReason('bad code');
    setIsWaitingForResponse(false);
    setFormValid(isFormValid);
    setShowErrorMessage(!isItUsersFault);
    setResponseErrorMessage(`${status} ${errorMessage}`);
  }

  const submitForm = () => {  
    setIsWaitingForResponse(true);
    const configurationCode = `BU-${inputCode.toUpperCase()}`;
    loadConfigurationCodeToActiveSlot(customerId, configurationCode, forwardToFeatureListPage, displayErrorMessage);
  }

  const isPastingPossible = typeof navigator.clipboard.readText === 'function';
  const inputClassName = `input-with-prefix  ${isFormValid ? 'valid' : 'invalid'}`;

  return (
    <div className={className}>
      <header className="dark-navigation">
        <h1>
          Enter code to load configuration
          <div className="button-on-circle">
            <ActionIconButton className="icon-close" onClick={onClose} />
          </div>
        </h1>
      </header>
      <div className="form-wrapper">
        <form>
        <div className={inputClassName}>
          <span className="prefix">BU-</span>
          <input ref={codeInputRef} type="text" value={inputCode} onChange={(e)=>{onCodeChange(e.target.value)}} placeholder="XXXXXXXX"/>
          {!isFormValid && <span className="error">{invalidReason}</span>}
        </div>
        </form>
        <div className="button-wrapper">
          {isPastingPossible && <ActionButton className="paste-button" onClick={pasteFromClipboard}>Paste from clipboard</ActionButton>}
          <ActionButton disabled={!isFormValid || isWaitingForResponse} onClick={submitForm}>Proceed</ActionButton>
        </div>
      </div>
      {showErrorMessage && <CodeWidgetErrorMessage message={responseErrorMessage}/>}
    </div>
  )
}

export default styled(EnterCodeWidget)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${p => p.theme.colors.darkTransparentBackground};
  backdrop-filter: blur(8px);

  header.dark-navigation {
    h1 {
      color: ${p => p.theme.colors.onboardingText};
      position: absolute;
      margin: 0;
      left: 0;
      right: 0;
      top: 0;
      font-family: 'ZwoLF Bold';
      font-size: 28px;
      line-height: 69px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;

      .button-on-circle {
        position: fixed;
        top:8px;
        right: 8px;
        width: 45px;
        height: 45px;
        margin-left: 10px;
        border-radius: 100px;
        background-color: ${p => p.theme.colors.onboardingText};
        display: flex;
        justify-content: center;
        align-items: center;
        z-index:1;
        
        .close-button {
          width: 35px;
          height: 35px;
          background-color: ${p => p.theme.colors.onboardingBackground};
        }
      }
    }

    div {
      width: 540px;
      height: 64px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      img {
        top: -6px;
        position: relative;
        width: 48px;
        height: 48px;
      }
    }
  }

  .form-wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${p => p.theme.colors.onboardingText};
 
    form {
      max-width: 764px;
      display: flex;
      flex-direction: column;

      .input-with-prefix
      {
        display: flex;
        align-items: center;
        min-width: 764px;

        &.valid {
          border-bottom: 2px solid ${p => p.theme.colors.onboardingText};
        }
        &.invalid {
          border-bottom: 2px solid ${p => p.theme.colors.bugattiRed};
        }
      }

      input{
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        text-transform: uppercase;
        flex-grow:1;
      }

      input,
      .prefix,
      .error {
        line-height: 63px;
        color: ${p => p.theme.colors.onboardingText};
        font-family: 'ZwoLF Bold';
        font-size: 32px;
        letter-spacing: 1px;
        
        background: none;
      }
      .prefix {
        opacity: 0.5;
        line-height: 63px;
      }

      .error {
        font-size: 18px;
        width: 237px;
        text-align: center;
        background-color: ${p => p.theme.colors.bugattiRed};
        mask-image: url('./icons/error-code-background.svg');
        mask-repeat: no-repeat;
        mask-position-y: center;
      }

      label {
        padding: 0 8px;
        font-family: 'ZwoLF Bold';
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        opacity: 0.5;
      }
    }
    
    .button-wrapper
    {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 43px;
      gap: 43px;

      button {
      padding: 0;
      margin: 0;
      font-size: 24px;
      min-width: 374px;
      line-height: 63px;
    
      border: none;
      border-radius: 0;
      text-transform: uppercase;
      opacity: 1;
      transition: opacity 2000ms, background-color 2000ms, color 1000ms;
      cursor: pointer;

        &:disabled {
          color: ${p => p.theme.colors.onboardingText};
          background-color: rgba(255,255,255,0.2);
          opacity: 0.4;
          transition: opacity 2000ms, background-color 2000ms, color 1000ms;
          cursor: auto;
        }
      }

      .paste-button
      {
        background-color: #B0B0B0;
      }
    }
    

  }

  ${CodeWidgetErrorMessage} {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;