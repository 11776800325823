import React, { useRef, useState } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { ThemeNameEnum } from '../../shared/model/RawModel';

const brightTheme: DefaultTheme = {
  colors: {
    foreground: 'rgba(0, 0, 0, 1)',
    foregroundDimmed: 'rgba(0, 0, 0, 0.4)',
    background: 'rgba(255, 255, 255, 1)',
    backgroundPreset: 'rgba(200, 200, 200, 1)',
    blend: '#7b7b7b',
    neutralTransparent: 'rgba(151, 151, 151, 0.39)',

    darkTransparentBackground: 'rgba(0,0,0,0.7)',
    onboardingText: 'rgba(255,255,255,1)',
    onboardingBackground: 'rgba(0,0,0,1)',
    onboardingBackgroundGlow: 'rgba(30,30,30,1)',
    onboardingBackgroundBright: 'rgba(255,255,255,0.2)',
    sidePanelText: 'rgba(0,0,0,1)',
    sidePanelBackground: 'rgba(255,255,255,1)',
    bugattiRed: 'rgba(255,6,0,1)',
    scrollbarOnBright: 'rgba(0,0,0,0.4)',
    scrollbarOnDark: 'rgba(255,255,255,0.4)',
  },
  mixBlendMode: 'lighten',
};

const darkTheme: DefaultTheme = {
  colors: {
    foreground: 'rgba(255, 255, 255, 1)',
    foregroundDimmed: 'rgba(255, 255, 255, 0.4)',
    background: 'rgba(0, 0, 0, 1)',
    backgroundPreset: 'rgba(55, 55, 55, 1)',
    blend: '#7b7b7b',
    neutralTransparent: 'rgba(151, 151, 151, 0.39)',

    darkTransparentBackground: 'rgba(0,0,0,0.7)',
    onboardingText: 'rgba(255,255,255,1)',
    onboardingBackground: 'rgba(0,0,0,1)',
    onboardingBackgroundGlow: 'rgba(30,30,30,1)',
    onboardingBackgroundBright: 'rgba(255,255,255,0.1)',
    sidePanelText: 'rgba(0,0,0,1)',
    sidePanelBackground: 'rgba(255,255,255,1)',
    bugattiRed: 'rgba(255,6,0,1)',
    scrollbarOnBright: 'rgba(0,0,0,0.4)',
    scrollbarOnDark: 'rgba(255,255,255,0.4)',
  },
  mixBlendMode: 'darken',
};

const Themes: { [key in ThemeNameEnum]: DefaultTheme } = {
  dark: darkTheme,
  bright: brightTheme,
};

export type SelectThemeFunction = (name: ThemeNameEnum) => void;

const defaultSelectionFunction = () => {};
export const ThemeSelectionContext = React.createContext<SelectThemeFunction>(defaultSelectionFunction);

type Props = {
  children: React.ReactNode;
};

const AppTheme: React.FunctionComponent<Props> = props => {
  const [activeTheme, setActiveTheme] = useState<DefaultTheme>(brightTheme);

  const selectThemeByName = useRef<SelectThemeFunction>();
  // initialize only once
  if (!selectThemeByName.current) {
    selectThemeByName.current = (name: ThemeNameEnum) => {
      const theme = Themes[name];
      setActiveTheme(theme);
    };
  }

  return (
    <ThemeSelectionContext.Provider value={selectThemeByName.current || defaultSelectionFunction}>
      <ThemeProvider theme={activeTheme}>{props.children}</ThemeProvider>
    </ThemeSelectionContext.Provider>
  );
};

export default AppTheme;
