// import { Surface } from 'gl-react-dom';
// import MaskHighlight from '../highlights/MaskHighlight';
// import GLTransition from '../transitions/GLTransition';
// import { shaderImageScale } from '../transitions/shaderImageScale';
// import { shaderImageTransitions } from '../transitions/shaderImageTransitions';

import * as React                            from 'react';
import { useEffect, useState }               from 'react';
import styled                                from 'styled-components';
import { CarModel, Option, View  } from '../../shared/model/RawModel';
import { CustomerConfiguration, ID, Preset } from '../../shared/model/RawModel';
import { AppConfiguration                  } from '../app/AppConfiguration';
import { useCarImage                       } from './useCarImage';
import useNodeSize                           from './useNodeSize';
import { ImageType } from '../../shared/model/RenderResponse';

type Props = {
  className?: string;
  imageType: ImageType;
  carModelId?: ID<CarModel>;
  presetId?: ID<Preset>;
  customerConfigurationId?: ID<CustomerConfiguration>;
  dynamicCameraView?: boolean;
  viewID?: ID<View>;
  fixedWidth?: number;
  fixedHeight?: number;
  highlightFeatureId?: string;
  selectedOptions?: ReadonlyArray<ID<Option>>;
};

const defaultBackground = './images/bg-default-bright.png';
//const renderTargetAttributes = { alpha: true, premultipliedAlpha: false };

const CarRender: React.FunctionComponent<Props> = props => {
  const {
    carModelId,
    presetId,
    customerConfigurationId,
    dynamicCameraView,
    viewID,
    fixedWidth,
    fixedHeight,
    //highlightFeatureId,
    selectedOptions,
    imageType,
  } = props;

  const givenIdsCount = [carModelId, presetId, customerConfigurationId].reduce((prev, value) => {
    return prev + (value ? 1 : 0);
  }, 0);

  if (givenIdsCount !== 1) {
    throw new Error('CarRender needs one (and exactly one) of `presetId`, `customerConfigurationId` or `carModelId`');
  }

  const { setRef, rect } = useNodeSize();
  const width  = fixedWidth  ? fixedWidth  : rect ? Math.floor(rect.width)  : 128;
  const height = fixedHeight ? fixedHeight : rect ? Math.floor(rect.height) : 128;

  const { item } = useCarImage({
    width,
    height,
    viewID,
    carModelId,
    presetId,
    customerConfigurationId,
    // do not fetch data when no rect is fetched yet
    delayLoading: (!(fixedWidth && fixedHeight) && !rect ) || (dynamicCameraView && !viewID),
    selectedOptions,
    imageType: imageType,
  });

  const imageUrl = item ? `${AppConfiguration.baseServerUrl}${item.url}` : defaultBackground;

  const sizeStyle =
    fixedWidth && fixedHeight ? { width: fixedWidth, height: fixedHeight } : ({} as React.CSSProperties);
  
  const [isChangingImage, setIsChangingImage] = useState<boolean>(false);
  const [previousImageUrl, setPreviousImageUrl] = useState<string>(defaultBackground);
  const [currentImageUrl, setCurrentImageUrl] = useState<string>(defaultBackground);
  const [lastLoadedImageUrl, setLastLoadedImageUrl] = useState<string>('');

  useEffect(()=>{
    setIsChangingImage(true);
    
    if(lastLoadedImageUrl === currentImageUrl)
      setPreviousImageUrl(currentImageUrl);

    setCurrentImageUrl(imageUrl);
    // eslint-disable-next-line
  }, [imageUrl]);

  const previousImageClassName = isChangingImage ? '' : 'fade-out';

  const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setIsChangingImage(false);
    setLastLoadedImageUrl(e.currentTarget.src);
  }

  return (
    <div id="car-render" className={props.className} ref={setRef} style={{ ...sizeStyle }}>
       <img src={currentImageUrl}  onLoad={onImageLoaded} alt=""/>
       <img src={previousImageUrl} className={previousImageClassName} alt=""/>
    </div>
  );
};

export default styled(CarRender)`

  position: relative;
  overflow: hidden;
  background-image: url('./images/black-spot-dimmed.png');
  z-index: 0;

  img {
    position:absolute;
    left:0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity:1; 
    transition: all 0s ease-out;
    
    &.fade-out {
      transition: all 500ms ease-out;
      opacity: 0;
    }
  }

  // Surface
  > span {
    position: absolute !important;
    top: 0;
    left: 0;
  }
`;
