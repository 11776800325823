import * as React from 'react';
import styled from 'styled-components';
import { OptionObject } from '../../../../../../shared/model/FeatureSet';
import CustomGridRow from './CustomGridRow';
import { ID, Option } from '../../../../../../shared/model/RawModel';

type Props = {
    className?: string;
    options: ReadonlyArray<OptionObject>;
    minCellSize: number;
    headerWidth: number;
    onOptionItemSelected: (optionId: ID<Option>) => void;
};

const CustomGrid: React.FunctionComponent<Props> = props => {
    const { minCellSize, headerWidth, options, onOptionItemSelected } = props;
    const contentRef = React.useRef<HTMLDivElement | null>(null);
    const [contentWidth, setContentWidth] = React.useState<number>(minCellSize);

    const [firstRowItems, setFirstRowItems] = React.useState<ReadonlyArray<OptionObject>>([]);
    const [midRowItems, setMidRowItems] = React.useState<ReadonlyArray<OptionObject>>([]);
    const [lastRowItems, setLastRowItems] = React.useState<ReadonlyArray<OptionObject>>([]);
    const [cellWidth, setCellWidth] = React.useState<number>(minCellSize);

    React.useEffect(() => {
        const columnCount = Math.floor(contentWidth / minCellSize);

        const currentCellWidth = contentWidth / columnCount;
        setCellWidth(currentCellWidth);
        const lastRowWidth = contentWidth - headerWidth;

        const itemCount = options.length;
        const lastRowItemCount = Math.round(lastRowWidth / currentCellWidth);
        const firstRowItemCount = (itemCount - lastRowItemCount) % columnCount;

        setFirstRowItems(options.slice(0, firstRowItemCount));
        setMidRowItems(options.slice(firstRowItemCount, itemCount - lastRowItemCount));
        setLastRowItems(options.slice(itemCount - lastRowItemCount));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentWidth, minCellSize, options]);

    const calculateWidth = () => {
        const width = contentRef.current ? contentRef.current.clientWidth : minCellSize;
        setContentWidth(width);
    }

    React.useEffect(() => {
        calculateWidth();

        const resizeListener = () => {
            calculateWidth();
        }

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentRef.current, minCellSize]);

    const renderRows = () => {
        return <>
            <CustomGridRow options={firstRowItems} cellWidth={cellWidth} cellHeight={minCellSize} onOptionItemSelected={onOptionItemSelected} />
            <CustomGridRow options={midRowItems} cellWidth={cellWidth} cellHeight={minCellSize} onOptionItemSelected={onOptionItemSelected} />
            <CustomGridRow options={lastRowItems} cellWidth={cellWidth} cellHeight={minCellSize} onOptionItemSelected={onOptionItemSelected} />
        </>
    }

    return (<div className={props.className} ref={contentRef} >
        {contentWidth > minCellSize && renderRows()}
    </div >
    );
};

export default styled(CustomGrid)`
    width: 100%
`;