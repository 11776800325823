import styled from 'styled-components';

export default styled.li`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 17px;
  font-size: 28px;
  line-height: 34px;

  font-weight: 700;
  text-transform: uppercase;
  font-family: 'ZwoLF Bold', sans-serif;

  cursor: pointer;

  transition: background-color 200ms ease-in-out;
  user-select: none;

  &:hover {
    background-color: ${p => p.theme.colors.neutralTransparent};
  }
`;
