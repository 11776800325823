import './client/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from './client/app/App';
import * as serviceWorker from './client/serviceWorker';
import { AppConfiguration } from './client/app/AppConfiguration';

// determine if renderer runs in electron context or not
const userAgent = navigator.userAgent.toLowerCase();
AppConfiguration.isElectronApp = userAgent.indexOf(' electron/') > -1;
AppConfiguration.isWebVersion  = !AppConfiguration.isElectronApp && window.location.hostname.toLowerCase()!=='localhost';

AppConfiguration.isDebug = AppConfiguration.isElectronApp && window.require('@electron/remote').process.env['DEBUG']!==undefined;

// if electron app assign server
if (AppConfiguration.isElectronApp) 
{
  const args = window.require('@electron/remote').process.argv as string[];

  if(args !== undefined)
  {
    const serverIndex = args.findIndex(s => s === '--server');
    if(serverIndex !== -1)
    {
      const serverUrlIndex = serverIndex+1;
      if(serverUrlIndex < args.length)
      {
        AppConfiguration.baseServerUrl = args[serverUrlIndex];
      }
    }
  }
}

//is webversion?
if(AppConfiguration.isWebVersion)
{
  //redirect to webserver
  AppConfiguration.baseServerUrl = window.location.origin;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HashRouter>
     <App />
  </HashRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
