import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import EnvironmentSwitchItem from './EnvironmentSwitchItem';
import { AppConfiguration } from '../../../app/AppConfiguration';
import { ModificationState } from '../../../components/modifyItem';
import { useItemFromUrl } from '../../../components/useItemsFromServer';
import { HiddenViewFeatureSetResponse } from '../../../../shared/model/FeatureSet';
import { ID, Feature, Option } from '../../../../shared/model/RawModel';

type Props = {
  className?: string;
  modificationState: ModificationState;
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
};

const EnvironmentSwitch: React.FunctionComponent<Props> = props => {
  const { className, modificationState, onSelectOption } = props;

  const { customerConfigurationId } = useParams();
  const { item: noViewFeatureSetResponse } = useItemFromUrl<HiddenViewFeatureSetResponse>(
    `${AppConfiguration.baseServerUrl}/api/feature-set/hidden/${customerConfigurationId}`,
    modificationState.reloadTrigger
  );

  const feature =
    noViewFeatureSetResponse &&
    noViewFeatureSetResponse.featureSets.flatMap(fso =>
      fso.featureObjects.filter(fo => fo.uiComponent === 'environmentDropdown')
    );

  const featureId = feature?.[0]?.id;
  const options = feature?.[0]?.optionSetObjects?.[0].options;

  return (
    <div className={className}>
      {options &&
        options.map(o => (
          <EnvironmentSwitchItem
            key={o.optionId}
            option={o}
            onSelect={o => {
              featureId && onSelectOption(featureId, o);
            }}
          />
        ))}
    </div>
  );
};

export default styled(EnvironmentSwitch)`
  width: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 11px;
`;
