import * as React from 'react';
import styled from 'styled-components';

import { FeatureSet, ID } from '../../../../../../shared/model/RawModel';
import ActionIconButton from '../../../../../components/ActionIconButton';
import ItemSwitcher from '../../../../../components/ItemSwitcher';
import CustomerWishesView from './CustomerWishesView';

type Props = {
  className?: string;
  isVisible: boolean;
  filterContent: boolean;
  hasPreviousFeature?: boolean;
  hasNextFeature?: boolean;
  onSelectPreviousFeature?: () => void;
  onSelectNextFeature?: () => void;

  filterFeatureSetId?: ID<FeatureSet>;
  featureTitle?: string;
  onCloseWishView?: () => void;
  onWishCountChanged?: (wishCount: number) => void;
  overrideHeader?: string;
};
const CustomerWishesWidget: React.FunctionComponent<Props> = props => {
  const {
    className,
    isVisible,
    hasPreviousFeature,
    hasNextFeature,
    onSelectPreviousFeature,
    onSelectNextFeature,
    filterContent,
    onCloseWishView,
    overrideHeader
  } = props;
  const header = filterContent ? 'Add a special customer wish' : 'special customer wishes';
  const viewHeader = overrideHeader ? overrideHeader : header;

  const classNames = [className, isVisible ? 'visible' : 'hidden'].filter(Boolean).join(' ');

  return (
    <div className={classNames}>
      <header className="dark-navigation">
        <h1>
          {viewHeader}
          <div className="button-on-circle">
            <ActionIconButton className="icon-close" onClick={onCloseWishView} />
          </div>
        </h1>
        <ItemSwitcher
          visible={!filterContent}
          hasPrevious={hasPreviousFeature ? hasPreviousFeature : false}
          hasNext={hasNextFeature ? hasNextFeature : false}
          onSelectPrevious={onSelectPreviousFeature}
          onSelectNext={onSelectNextFeature}
        />
      </header>
      <CustomerWishesView
        isVisible={isVisible}
        filterContent={filterContent}
        filterFeatureSetId={props.filterFeatureSetId}
        featureSetTitle={props.featureTitle}
        onCloseWishView={props.onCloseWishView}
        onWishCountChanged={props.onWishCountChanged}
      />
    </div>
  );
};
export default styled(CustomerWishesWidget)`
  background-color: ${p => p.theme.colors.darkTransparentBackground};
  backdrop-filter: blur(8px);
  height: 100vh;
  /* transform: translateY(0px);
  transition: transform 500ms;  to do: set up proper transitions*/
  z-index: 5;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  header.dark-navigation {
    h1 {
      color: ${p => p.theme.colors.onboardingText};
      position: absolute;
      margin: 0;
      left: 0;
      right: 0;
      top: 0;
      font-family: 'ZwoLF Bold';
      font-size: 28px;
      line-height: 69px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;

      .button-on-circle {
        position: fixed;
        top:8px;
        right: 8px;
        width: 45px;
        height: 45px;
        margin-left: 10px;
        border-radius: 100px;
        background-color: ${p => p.theme.colors.onboardingText};
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .close-button {
          width: 35px;
          height: 35px;
          background-color: ${p => p.theme.colors.onboardingBackground};
        }
      }
    }

    div {
      width: 540px;
      height: 64px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      img {
        top: -6px;
        position: relative;
        width: 48px;
        height: 48px;
      }
    }
  }

  > div {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .wish-list-container {
      min-width: 60vw;
      margin: 0;

      h2 {
        display: none;
      }
    }
  }
`;
