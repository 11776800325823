import * as React from 'react'; import styled from 'styled-components';
import { OptionObject } from '../../../../../../shared/model/FeatureSet';
import OptionItem from './OptionItem';
import { ID, Option } from '../../../../../../shared/model/RawModel';

type Props = {
    className?: string;
    options: ReadonlyArray<OptionObject>;
    cellWidth: number;
    cellHeight: number;
    onOptionItemSelected: (optionId: ID<Option>) => void;
};

const CustomGridRow: React.FunctionComponent<Props> = props => {
    const { options, cellWidth, cellHeight, onOptionItemSelected } = props;

    const cellStyle = { width: cellWidth, height: cellHeight } as React.CSSProperties;

    return (
        <div className={props.className}>
            {options.map(o => {
                if (o.status === 'hidden') {
                    return null;
                }
                return (
                    <OptionItem
                        className="option"
                        key={o.optionId}
                        option={o}
                        onSelect={onOptionItemSelected}
                        showLabel={false}
                        fixedSize={cellStyle}
                    />
                );
            })}
        </div>
    );
};

export default styled(CustomGridRow)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;