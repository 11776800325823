import React from "react";
import { throttle } from "lodash";
import { ID, CustomerConfiguration } from "../../shared/model/RawModel";
import { ExportVideoStatusResponse } from "../../shared/model/VideoResponse";
import { AppConfiguration } from "../app/AppConfiguration";

type ExportVideoState = {
    isExporting: boolean,
    isExported: boolean,
    eta_min: number,
}

async function getExportStatus( exportId: string, exportState: ExportVideoState, setExportStatus: (state: ExportVideoState) => void) {
    if(!exportState.isExporting)
        return;

    const url = `${AppConfiguration.baseServerUrl}/api/exportVideo/status?id=${exportId}`;
    const response = await fetch(url);
    const content = await response.json();

    if (!response.ok) {
        throw new Error(content.message || 'Unknown Error');
    }

    const status = content as ExportVideoStatusResponse;
    setExportStatus({
        isExporting   : !status.isLoaded,
        isExported    :  status.isLoaded,
        eta_min       :  status.eta_min
    });
}

export function useExportVideo(configurationId: ID<CustomerConfiguration>) {
    const [exportVideoState, setExportVideoState] = React.useState<ExportVideoState>({
        isExporting : true,
        isExported  : false,
        eta_min     : 0
    });

    const throttledGetExportStatus = React.useRef(throttle(getExportStatus, 5000)).current;

    React.useEffect(()=>{
        const url = `${AppConfiguration.baseServerUrl}/api/exportVideo/customerConfiguration?id=${configurationId}`;
        fetch(url);
        setExportVideoState({
            isExporting   : true,
            isExported    : false,
            eta_min     : 0
        });

        return function cleanup() {
            throttledGetExportStatus.cancel();
        };
    }, [configurationId, throttledGetExportStatus]);

    if(exportVideoState.isExporting && configurationId){
        throttledGetExportStatus(configurationId, exportVideoState, setExportVideoState);
    }

    const cancelExport = () => {
        if(!exportVideoState.isExporting)
            return;

        const url = `${AppConfiguration.baseServerUrl}/api/exportVideo/cancelExportVideo?id=${configurationId}`;
        fetch(url);
        setExportVideoState({
            isExporting : false,
            isExported  : false,
            eta_min     : 0
        });
    }

    return {
        exportVideoState,
        cancelExport
    };
}