import * as React                                     from 'react';
import styled                                         from 'styled-components';
import { useParams }                                  from 'react-router-dom';
import { throttle }                                   from 'lodash';
import CameraSlider                                   from './CameraSlider';
import CameraToggle                                   from './CameraToggle';
import CameraActionToggle                             from './CameraActionToggle';
import CameraButton                                   from './CameraButton';
import ZoomSlider                                     from './ZoomSlider';
import EnvirontmentSwitch                             from './EnvironmentSwitch';
import CameraJoystick                                 from './CameraJoystick';
import { AppConfiguration }                           from '../../../app/AppConfiguration';
import { modifyItem, ModificationState }              from '../../../components/modifyItem';
import ActionIconButton                               from '../../../components/ActionIconButton';
import { useItemFromUrl }                             from '../../../components/useItemsFromServer';
import { ID, CustomerConfiguration, Feature, Option, Action, View } from '../../../../shared/model/RawModel';
import { CameraControllerStatus, ViewInfoUI }         from '../../../../shared/model/CameraControllerResponse';


type Props = {
    className?: string;
    visible: boolean;
    modificationState: ModificationState;
    onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
    onClose: ()=>void;
    reloadImages: ()=>void;
    customerConfigurationId: ID<CustomerConfiguration>;
};

const updateCameraController = async (
    viewID: ID<View>,
    progress: number,
    customerConfigurationId: ID<CustomerConfiguration>,
    setModificationState: (state: ModificationState) => void
):Promise<void> => {
    const url = `${AppConfiguration.baseServerUrl}/api/realtime/camera/set?viewID=${encodeURIComponent(viewID)}&campos=${progress}&configurationID=${customerConfigurationId}`;
    modifyItem(url, 'POST', "", setModificationState).catch(e => { });
}

const updateZoom = async (
    value: number,
    customerConfigurationId: ID<CustomerConfiguration>,
    setModificationState: (state: ModificationState) => void
) => {
    const url = `${AppConfiguration.baseServerUrl}/api/realtime/camera/zoom?amount=${value}&configurationID=${customerConfigurationId}`;
    modifyItem(url, 'POST', "", setModificationState);
}

const updateOrbit = async (
    dX: number,
    dY: number,
    customerConfigurationId: ID<CustomerConfiguration>,
    setModificationState: (state: ModificationState) => void
) => {
    const url = `${AppConfiguration.baseServerUrl}/api/realtime/camera/move?xAmount=${dX}&yAmount=${dY}&configurationID=${customerConfigurationId}`;
    modifyItem(url, 'POST', "", setModificationState);
}

const toggleModificationState = async(
  modificationState: ModificationState,
  setModificationState: (state: ModificationState) => void
) => {
  const newModificationState = {error: null, reloadTrigger: !modificationState.reloadTrigger};
  setModificationState(newModificationState);
}

const CameraController: React.FunctionComponent<Props> = props => {
    const { 
        className, 
        visible, 
        modificationState: featureModificationState,
        onSelectOption,
        onClose,
        reloadImages,
        customerConfigurationId } = props;

    const [cameraControlModificationState, setModificationState] = React.useState<ModificationState>({ error: null, reloadTrigger: false });

    //const { customerConfigurationId } = useParams() as any;

    const {item: cameraControllerStatus} = useItemFromUrl<CameraControllerStatus>(
        `${AppConfiguration.baseServerUrl}/api/cameraControl/status?configurationID=${customerConfigurationId}`,
        cameraControlModificationState.reloadTrigger, visible);

    const throttledUpdateCameraController = React.useRef(throttle(updateCameraController, 500, {trailing:true})).current;
    const throttledUpdateZoom = React.useRef(throttle(updateZoom, 100)).current;
    const throttledUpdateOrbit = React.useRef(throttle(updateOrbit, 100)).current;
    const throttledToggleModificationState = React.useRef(throttle(toggleModificationState, 100)).current;

    const isPlaying  = cameraControllerStatus ? cameraControllerStatus.isPlaying : false;
    const activePath = cameraControllerStatus ? cameraControllerStatus.views.find(p => p.id===cameraControllerStatus.currentViewID) : undefined;

    const isSynced   = cameraControllerStatus ? cameraControllerStatus.isSynced : false;

    if(isPlaying)
       throttledToggleModificationState(cameraControlModificationState, setModificationState);

    const onCameraSliderValueChanged = (viewID: string, progress: number) => {
      throttledUpdateCameraController(viewID, progress, customerConfigurationId, setModificationState);
    }

    const onCameraButtonClicked = (viewID: ID<View>) => {
      throttledUpdateCameraController(viewID, 0, customerConfigurationId, setModificationState);
    }

    const onPlayToggled = () => 
    {
        //find the first non-static/animated path
        const path = cameraControllerStatus?.views.find(n=>n.cameraPoints.length>0);
        if(!path) return;

        //construct play/pause request url
        let url = '';
        if(isPlaying)
            url = `${AppConfiguration.baseServerUrl}/api/realtime/camera/pause?id=${path.id}`;
        else
            url = `${AppConfiguration.baseServerUrl}/api/realtime/camera/play?id=${encodeURIComponent(path.id)}&configurationID=${customerConfigurationId}`;

        //send play/pause request to engine
        modifyItem(url, 'POST', "", setModificationState);
    }

    const onCameraActionToggleClicked = (id:ID<Action>) => {
        const url = `${AppConfiguration.baseServerUrl}/api/action/toggle?actionID=${id}&configurationID=${customerConfigurationId}`;
        modifyItem(url, 'POST', "", setModificationState);
    };

    const onZoomChange = (value: number) => {
        throttledUpdateZoom(value, customerConfigurationId, setModificationState);
    }

    const onOrbitChange = (dX: number, dY: number) => {
        throttledUpdateOrbit(dX, dY, customerConfigurationId, setModificationState);
    }

    const createViewWidget = (view: ViewInfoUI) => {
        //is slider widget needed (view has camera points)
        if(view.cameraPoints.length>0)
        {
          return <CameraSlider
              className={isPlaying ? 'myhidden_' : ''}
              key={view.id}
              view={view}
              position={cameraControllerStatus?.position}
              isActive={view.id === cameraControllerStatus?.currentViewID}
              isAnimated={isPlaying}
              onValueChanged={(progress) => { onCameraSliderValueChanged(view.id, progress) }}
          />
        } else {
          return <CameraButton
            key={view.id}
            view={view}
            isActive={view.id === cameraControllerStatus?.currentViewID}
            onClicked={() => { onCameraButtonClicked(view.id) }}
          />
        }
    }

    const onToggleSync = () => {
        //construct play/pause request url
        let url = `${AppConfiguration.baseServerUrl}/api/realtime/camera/sync?set=${!isSynced}&configurationID=${customerConfigurationId}`;

        //send play/pause request to engine
        modifyItem(url, 'POST', '', setModificationState);

        reloadImages();
    }
  
    const classNames = `${className} ${visible ? '' : 'hidden'}`;

    return (
    <div className={classNames}>
      <header>
        <div className="cam-controls">
          <CameraToggle
            isOn={isPlaying}
            onIconName="icon-pause"
            offIconName="icon-play"
            onLabel="pause"
            offLabel="play"
            onToggled={onPlayToggled}
          />
          <section>{cameraControllerStatus?.views.map(p => createViewWidget(p))}</section>
        </div>
        <div className="button-on-circle">
        <CameraToggle
            isOn={isSynced}
            onIconName="icon-lock"
            offIconName="icon-unlock"
            onLabel=""
            offLabel=""
            onToggled={onToggleSync}
          />
        </div>
        <div className="button-on-circle">
          <ActionIconButton className="icon-close" onClick={onClose} />
        </div>
      </header>
      <main>
        <EnvirontmentSwitch
          className="environment-switch-widget"
          modificationState={featureModificationState}
          onSelectOption={onSelectOption}
        />
        {/* <ZoomSlider 
          zoomAmount={cameraControllerStatus ? cameraControllerStatus.zoomAmount : 0}
          onZoomChange={onZoomChange} 
        /> */}
      </main>
      <footer>
        <CameraJoystick 
          canMoveInX={activePath?.canOrbitX}
          canMoveInY={activePath?.canOrbitY}
          onOrbitChanged={onOrbitChange}/>

        <section className="button-area align-right">
          {cameraControllerStatus?.actions.map(a => {
            return <CameraActionToggle key={a.id} action={a} onClick={()=>onCameraActionToggleClicked(a.id)} />
          })}
        </section>

        <span className="placeholder"></span>
      </footer>
    </div>
  );
};

export default styled(CameraController)`
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: radial-gradient(ellipse, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
  opacity: 1;
  transition: top 300ms ease-in-out, opacity 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  &.hidden {
    top: -60px;
  }

  .myhidden > input
  {
    display:none;
  }

  header,
  main,
  footer {
    display: flex;
    justify-content: space-between;
  }

  header {
    width: 100%;
    height: 93px;
    margin-top: 8px;

    .cam-controls {
      flex: 1;
      margin-top: 14px;
      margin-left: 24px;
      display: flex;

      > section {
        min-width: 96%;
        display: flex;

        ${CameraSlider} {
          width: inherit;
        }

        section.filler {
          flex: 1;
          height: 1px;
          width: 60px;

          margin-top: 31px;
          margin-left: -20px;
          margin-right: -20px;

          opacity: 30%;
        }
      }
    }

    .button-on-circle {
      width: 35px;
      height: 35px;
      margin: 20px;
      border-radius: 100px;
      background-color: ${p => p.theme.colors.onboardingText};
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      .close-button {
        width: 35px;
        height: 35px;
        background-color: ${p => p.theme.colors.onboardingBackground};
      }
    }
  }

  main {
    align-items: center;

    .environment-switch-widget {
      pointer-events: all;
    }
  }

  footer {
    align-items: flex-end;

    .button-area {
      display: flex;
      flex-direction: row;
      margin: 0 4px 20px 4px;

      &.align-left {
        margin-left: 12px;
        margin-right: 12px;
        justify-content: flex-start;
      }

      &.align-right {
        justify-content: flex-end;
        margin-right: 12px;
        margin-left: 40px;
      }
    }

    .placeholder {
      /* centers button area */
      content: '';
      width: 150px;
    }
  }
`;
