import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  visible: boolean;
};

const AnimatedCheckmark = (props: Props) => {
  const { className, visible } = props;

  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');

  return <div className={classNames}></div>;
};

export default styled(AnimatedCheckmark)`
  content: ' ';
  width: 10px;
  height: 10px;
  color: white;
  background: ${p => p.theme.colors.foreground};
  mask-image: url('./icons/checkmark.svg');
  mask-repeat: no-repeat;
  mask-position: 38% 60%;

  &.hidden {
    opacity: 0;
    transition: opacity 1s;
  }

  &.visible {
    opacity: 1;
    transition: opacity 1s;
  }
`;
