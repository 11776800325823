import * as React from 'react';
import styled from 'styled-components';

import ActionButton from '../../../../../components/ActionButton';

type Props = {
  className?: string;
  onAdd: () => void;
};

const CustomerWishAddItem: React.FunctionComponent<Props> = props => {
  return (
    <li className={props.className}>
      <span>
        <img className="icon" src="./icons/plus.svg" alt="" />
        <ActionButton className="text dimmed" onClick={props.onAdd}>
          Add wish
        </ActionButton>
      </span>
    </li>
  );
};
export default styled(CustomerWishAddItem)`
  display: flex;

  .dimmed {
    opacity: 0.5;
  }

  span {
    flex-grow: 1;
    display: flex;

    img {
      margin: 0 5px;
      width: 30px;
      height: 49px;
      opacity: 0.4;
    }

    button {
      padding-left: 0;
      flex-grow: 1;
      line-height: 49px;
      font-size: 28px;
      color: ${p => p.theme.colors.onboardingText};
      background: transparent;
    }
  }
`;
