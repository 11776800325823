import React, { useState, useEffect } from 'react';
import { useParams                                  } from 'react-router';
import styled                                         from 'styled-components';
import FeatureOptionChooser                           from './FeatureOptionComponents/FeatureOptionChooser';
import FeatureSelector                                from './FeatureSelector';
import ProgressBar                                    from './ProgressBar';
import { useViewSelectionLogic                      } from './useViewSelectionLogic';
import ViewNavigator                                  from './ViewNavigator';
import { useGarageLogic                             } from './useGarageLogic';
import { useViewModeController, ViewMode            } from './useViewModeController';
import PlayModeWidget                                 from './PlayModeComponents/PlayModeWidget';
import GarageWidget                                   from '../garage/GarageWidget';
import { AppConfiguration                           } from '../../app/AppConfiguration';
import { useCarLoadingWithConfigId                  } from '../../components/useCarLoading';
import FeatureListPageHeader                          from '../../components/FeatureListPageHeader';
import LoadingOverlay                                 from '../../components/LoadingOverlay';
import { setFeatureSetAsConfigured, updateSelection } from '../../components/updateConfiguration';
import CarRender                                      from '../../components/CarRender';
import { Page                                       } from '../../components/Page';
import { useItemFromUrl                             } from '../../components/useItemsFromServer';
import { Customer, Feature, ID, Option                        } from '../../../shared/model/RawModel';
import { RealtimeClientStatus                       } from '../../../shared/model/CameraControllerResponse';


type Props = {
  className?: string;
};

const FeatureListPage: React.FunctionComponent<Props> = props => {
  let { customerId } = useParams() as {customerId:ID<Customer>};
  const viewSelectionLogic = useViewSelectionLogic();

  const [isConfigurationSaveable, setIsConfigurationSaveable] = useState<boolean>(false);
  const [activeSlotLastLoadedTimestamp, setActiveSlotLastLoadedTimestamp] = useState<number>(0);

  const [viewMode, setViewMode] = useState<ViewMode>('feature-list');

  const viewState = useViewModeController(viewMode);

  const { listedGarageConfigurations: configurationList,
    onConfigurationSelected,
    onConfigurationDelete,
    onConfigurationSave } = useGarageLogic(customerId, viewSelectionLogic.setModificationState, setActiveSlotLastLoadedTimestamp);

  const carLoadingState = useCarLoadingWithConfigId(viewSelectionLogic.customerConfigurationId, activeSlotLastLoadedTimestamp);

  const { item: realtimeClientStatus } = useItemFromUrl<RealtimeClientStatus>(`${AppConfiguration.baseServerUrl}/api/realtimeClientAvailable`);

  const hasRealtimeClient = realtimeClientStatus !== undefined && realtimeClientStatus.isAvailable;

  const openGarage = ()=>{
    if(viewSelectionLogic)
      viewSelectionLogic.unselectFeature();

    setViewMode('garage');
  }

  const closeGarage = ()=>{
    setViewMode('feature-list');
  }

  const togglePresentationMode = ()=>{
    setViewMode(previousValue => {
      if(previousValue === 'presentation') return 'feature-list';
      else return 'presentation';
  });
  }

  const toggleCameraController = () =>{
    const targetPlayMode = hasRealtimeClient ? 'play-showroom' : 'play-tablet';

    setViewMode(previousView => { 
      return previousView === 'feature-list' ? targetPlayMode : 'feature-list'; 
    });
  }

  const closeCameraController = () => {
    setViewMode('feature-list')
  }

  useEffect(()=>{
    if(viewSelectionLogic.activeFeatureSet)
    {
      setViewMode('active-feature');
    }
    else
    {
      setViewMode('feature-list');
    }
  },[viewSelectionLogic.activeFeatureSet])

  useEffect(() => {
    if (carLoadingState.isLoading) {
      setViewMode('feature-list');
    }
  }, [carLoadingState])

  if (viewSelectionLogic.loadError) {
    return (
      <Page className={props.className}>
        <div className={props.className}>
          Failed loading
          {viewSelectionLogic.loadError ? viewSelectionLogic.loadError.name : 'Unknown'}
          {viewSelectionLogic.loadError ? viewSelectionLogic.loadError.message : 'Unknown Message'}
        </div>
      </Page>
    );
  }

  const onSelectOption = (featureId: ID<Feature>, optionId: ID<Option>) => {
    setIsConfigurationSaveable(true);
    updateSelection(viewSelectionLogic.modificationUrl, featureId, optionId, viewSelectionLogic.setModificationState);
  };

  const reloadImages = () => {
    // viewSelectionLogic.modificationState
    // viewSelectionLogic.modificationUrl,
    // viewSelectionLogic.setModificationState
    const newModificationState = {error: null, reloadTrigger: !viewSelectionLogic.modificationState.reloadTrigger};
    viewSelectionLogic.setModificationState(newModificationState);
  };

  return (
    <Page className={props.className}>
      {carLoadingState.isLoaded &&
      <CarRender
        customerConfigurationId={viewSelectionLogic.customerConfigurationId}
        dynamicCameraView={true}
        viewID={viewSelectionLogic.activeCameraView && viewSelectionLogic.activeCameraView}
        highlightFeatureId={viewSelectionLogic.activeFeatureSet && viewSelectionLogic.activeFeatureSet.id}
        //selectedOptions={viewSelectionLogic.featureSetsForActiveView.flatMap(fo => fo.featureObjects.flatMap(o => o.selectedOptionId))}
        selectedOptions={viewSelectionLogic.featureSetResponse?.configHash}
        imageType="Dynamic"
      />
      }

      <GarageWidget
        visible={viewState.isGarageVisible}
        isConfigurationSaveable={isConfigurationSaveable}
        hideSaveButton={false}
        isClosable={true}
        customerConfigurations={configurationList ? configurationList : []}
        onConfigurationSelected={onConfigurationSelected}
        onOpenWidget={openGarage}
        onCloseWidget={closeGarage}
        onConfigurationSave={() => { setIsConfigurationSaveable(false); onConfigurationSave(); }}
        onConfigurationDelete={onConfigurationDelete}
      />

      <ViewNavigator
        areChevronsVisible={viewState.areViewChevronsVisible}
        activatable={viewState.isViewNavigatorActivatable}
        onToggleChevrons={togglePresentationMode}
        hasNextView={viewSelectionLogic.hasNextView}
        hasPreviousView={viewSelectionLogic.hasPreviousView}
        hasNextFeatureView={viewSelectionLogic.hasNextFeatureView}
        hasPreviousFeatureView={viewSelectionLogic.hasPreviousFeatureView}
        onSelectNextView={viewSelectionLogic.selectNextView}
        onSelectPreviousView={viewSelectionLogic.selectPreviousView}
        onSelectNextFeatureView={viewSelectionLogic.selectNextFeatureView}
        onSelectPreviousFeatureView={viewSelectionLogic.selectPreviousFeatureView}
      />

      <FeatureSelector
        visible={viewState.isFeatureSelectorVisible}
        features={viewSelectionLogic.featureSetsForActiveView}
        onSelect={feature => viewSelectionLogic.selectFeature(feature.id)}
        highlightNextView={viewSelectionLogic.highlightNextView}
        highlightPreviousView={viewSelectionLogic.highlightPreviousView}
        activeFeatureSetId={viewSelectionLogic.activeFeatureSetId}
        hasPreviousView={viewSelectionLogic.hasPreviousView}
        hasNextView={viewSelectionLogic.hasNextView}
        onSelectPreviousView={viewSelectionLogic.selectPreviousView}
        onSelectNextView={viewSelectionLogic.selectNextView}
      />

      <FeatureOptionChooser
        visible={viewState.isFeatureOptionChooserVisible}
        activeFeatureSet={viewSelectionLogic.activeFeatureSet}
        onResetFeatureToPreset={featureId =>
          updateSelection(viewSelectionLogic.modificationUrl, featureId, null, viewSelectionLogic.setModificationState)
        }
        onSelectOption={onSelectOption}
        onSetMainFeatureAsConfigured={featureSetObject =>
          setFeatureSetAsConfigured(
            viewSelectionLogic.modificationUrl,
            featureSetObject,
            viewSelectionLogic.setModificationState
          )
        }
        onClose={() => viewSelectionLogic.unselectFeature()}
        onSelectPreviousFeature={viewSelectionLogic.selectPreviousFeature}
        onSelectNextFeature={viewSelectionLogic.selectNextFeature}
        hasPreviousFeature={viewSelectionLogic.hasPreviousFeature}
        hasNextFeature={viewSelectionLogic.hasNextFeature}
      />



      {viewSelectionLogic.isLoading && <div>Loading…</div>}
      {viewSelectionLogic.modificationState.error && <div>Had Error: {viewSelectionLogic.modificationState.error}</div>}

      <FeatureListPageHeader
        isFeatureViewHeaderVisible={viewState.isFeatureViewHeaderVisible}
        views={viewSelectionLogic.views}
        isShowRoomContext={hasRealtimeClient}
        areIconsVisible={ viewState.areHeaderIconsVisible}
        isAppMenuVisible = {viewState.isAppMenuVisible}
        isCameraControllerVisible = {viewState.isCameraControllerVisible}
        onFeatureViewSelected={view => viewSelectionLogic.selectView(view.viewId)}
        onGarageOpened={openGarage}
        onCameraControllerToggled={toggleCameraController}
      />

      <PlayModeWidget
        isShowRoomContext={hasRealtimeClient}
        isCameraControllerVisible= {viewState.isCameraControllerVisible}
        modificationState={viewSelectionLogic.modificationState}
        onSelectOption={onSelectOption}
        onClose={closeCameraController}
        reloadImages={reloadImages}
        customerConfigurationId={viewSelectionLogic.customerConfigurationId}
      />

      <ProgressBar visible={true} progress={viewSelectionLogic.progress} />

      <LoadingOverlay visible={carLoadingState.isLoading} progress={carLoadingState.progress} />
    </Page>
  );
};

export default styled(FeatureListPage)`
  > ${CarRender} {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  ${FeatureSelector} {
    position: absolute;
    width: 100%;
    bottom: 64px;
  }

  ${FeatureOptionChooser} {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }

  ${GarageWidget} {
    position: absolute;
    top: 0px;
    width: 100%;
  }
  
  ${LoadingOverlay} {
    z-index: 2;
  }

  ${PlayModeWidget} {
    position: absolute;
  }

  .additional-configuration {
    position: absolute;
    top: 70%;
    right: 17px;
  }


  .outside-overlay {
    margin-top: 15vh;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 10vh;
    width: 80vw;
    height: 80vh;
    z-index: 1;
  }
`;
