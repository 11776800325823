type HttpMethod = 'PATCH' | 'PUT' | 'POST';

export type ModificationState = {
  error: string | null;
  reloadTrigger: boolean;
};

export async function modifyItem(
  url: string,
  method: HttpMethod,
  body: string,
  setModificationState: (state: ModificationState) => void,
  callback? : (isSuccessful:boolean, jsonBody?:any, responseStatus?:number) => void
  ) {
  try {
    setModificationState({ error: null, reloadTrigger: false });

    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    });
    const jsonBody = await response.json();
    if(callback) callback(response.ok, jsonBody, response.status);
    if (!response.ok) {
      throw new Error(jsonBody.message);
    }
    setModificationState({ error: null, reloadTrigger: true });
  } catch (e) {
    setModificationState({ error: (e as Error).message, reloadTrigger: true });
  }
}
