import React from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../../shared/model/FeatureSet';
import { ID, Option, Feature } from '../../../../../../shared/model/RawModel';
import { useDragScroll } from '../../../../../components/useDragScroll';
import OptionItem from './OptionItem';
import CustomGrid from './CustomGrid';

type Props = {
  className?: string;
  feature: FeatureObject;
  options: ReadonlyArray<OptionObject>;
  headerRef: React.MutableRefObject<HTMLElement | null>;
  onSelect: (optionId: ID<Option>) => void;
  onResetToPreset: () => void;
};

const minCellWidth = 86;

const OptionListWidget: React.FunctionComponent<Props> = props => {
  const { className, options, onSelect, headerRef, feature } = props;

  const [canExpand, setCanExpand] = React.useState<boolean>(false);
  const [isExpanded, setExpanded] = React.useState<boolean>(false);
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const [headerWidth, setHeaderWidth] = React.useState<number>(456);
  const [previousFeatureId, setPreviousFeatureId] = React.useState<ID<Feature> | undefined>(undefined);

  if (!canExpand && isExpanded) {
    setExpanded(false);
  }
  const optionCount = options.length;

  React.useEffect(() => {
    const contentNode = contentRef.current;
    const headerNode = headerRef.current;
    const parentContainerNode = contentNode ? (contentNode.parentNode as HTMLElement) : null;

    if (!contentNode || !headerNode || !parentContainerNode) {
      setCanExpand(false);
      setExpanded(false);
      return;
    }

    setHeaderWidth(headerNode.clientWidth);

    const availableWidthWithoutHeader = parentContainerNode.clientWidth - headerNode.clientWidth;
    const maxElementsThatFitInContent = Math.floor(availableWidthWithoutHeader / minCellWidth);
    const needsScroll = optionCount > maxElementsThatFitInContent;

    setCanExpand(needsScroll);

    //if feature is changed scroll to the selected option
    if (previousFeatureId !== feature.id) {
      setPreviousFeatureId(feature.id);
      const selectedOptionIndex = options.findIndex(o => o.status === 'selected-by-user' || o.status === 'selected-from-preset');
      if (selectedOptionIndex !== -1) {
        contentNode.scrollLeft = selectedOptionIndex * minCellWidth - maxElementsThatFitInContent / 2 * minCellWidth;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCount, contentRef, headerRef, isExpanded, feature.id]);

  const { scrolling } = useDragScroll(contentRef.current);

  const onOptionItemSelected = (optionId: ID<Option>) => {
    if (scrolling)
      return;
    onSelect(optionId);
  };

  const showLabel = optionCount < 6;
  const expandedClassName = isExpanded ? 'expanded' : 'collapsed';

  return (
    <section className={className}>
      {canExpand && (
        <img
          className={`expand-action ${expandedClassName}`}
          src={isExpanded ? './icons/chevron-down-thicker.svg' : './icons/chevron-up-thicker.svg'}
          onClick={() => setExpanded(!isExpanded)}
          alt=""
        />
      )}
      {
        <section
          className={`scroll-area ${expandedClassName}`}
          ref={contentRef}
          style={{ marginRight: headerWidth }}
        >
          {options.map(o => {
            if (o.status === 'hidden') {
              return null;
            }
            return (
              <OptionItem
                className="option"
                key={o.optionId}
                option={o}
                onSelect={onOptionItemSelected}
                showLabel={showLabel}
              />
            );
          })}
        </section>
      }
      {
        isExpanded &&
        <CustomGrid
          options={options}
          minCellSize={minCellWidth}
          headerWidth={headerWidth}
          onOptionItemSelected={onOptionItemSelected} />
      }
    </section>
  );
};

export default styled(OptionListWidget)`

  > .expand-action {
    height: 25px;
    bottom: 100px;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.75));
    cursor: pointer;
    
    transition: width 250ms;

    &.collapsed {
        width: 100%;
    }
    
    &.expanded {
        width: 25px;
        margin-left: 30.5px;
    }
  }

  > .scroll-area {
    overflow: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none !important;
      height: 0 !important;
      width: 0 !important;
      background: transparent !important;
      -webkit-appearance: none !important;
    }

    ${OptionItem} {
      flex: 1;
    }

    ${OptionItem}:not (:last-of-type) {
      border-right: 1px solid black;
    }

    &.collapsed {
      display: flex;
      flex-wrap: nowrap;
      height: 86px;

      li.option.state-selected-by-user,
      li.option.state-selected-from-preset {
        &:before {
          height: 74px;
        }

        &:after {
          height: 76px;
        }
      }
    }

    &.expanded {
     display: none;
    }
  }

`;
