import * as React from 'react';
import styled from 'styled-components';
import useNodeSize from '../../../components/useNodeSize';
import { ViewInfoUI } from '../../../../shared/model/CameraControllerResponse';
import { throttle } from 'lodash';

type Props = 
{
    className?: string;
    view: ViewInfoUI;
    isActive: boolean;
    isAnimated: boolean;
    position?: number;
    onValueChanged: (progress: number) => void;
};

const moveTowardsNewValue = async(
  currentValue: number,
  targetValue: number,
  setValue: (value: number) => void
) => {
  let newValue = (targetValue * 0.5 + currentValue * 0.5);
  setValue(newValue);
}

const CameraSlider: React.FunctionComponent<Props> = props => 
{
    const { className, view: camera, isActive, isAnimated, position: pathPosition, onValueChanged } = props;

    const [isInteracting, setIsInteracting] = React.useState<boolean>(false);
    const [animatedValue, setValue] = React.useState<number>(0);

    const steps = 1000;
    const pathDuration = Math.max(1, camera.duration);
    let targetValue =  0;
    if(pathPosition)  targetValue = pathPosition / pathDuration * steps;
  
    const throttledMoveTowardsNewValue = React.useRef(throttle(moveTowardsNewValue, 1000/60)).current;
    //const throttledMoveTowardsNewValue = React.useRef(moveTowardsNewValue).current;

    if(isAnimated && Math.abs(targetValue - animatedValue) > .1)
    {
      throttledMoveTowardsNewValue(animatedValue, targetValue, setValue)
    }

    const { setRef, rect } = useNodeSize();

    const isThumbVisible = isActive || isInteracting || isAnimated;
    const thumbVisible   = isThumbVisible ? 'visible' : 'hidden';
    const cameraPoints   = camera.cameraPoints;

    const setProgress = (progress: number) => 
    {
        onValueChanged(progress / steps);
    }

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => 
    {
        const rawValue = parseInt(ev.target.value);
        setProgress(rawValue);
    }

    const onMouseDown = (ev: React.MouseEvent<HTMLInputElement>) => {
        setIsInteracting(true);
    }

    const onMouseUp = (ev: React.MouseEvent<HTMLInputElement>) => {
        setIsInteracting(false);
    }

    const sliderWidth = rect ? rect.width : 1;
    const thumbWidth = 48;

    const offsetInPercentage = (thumbWidth / 2) / sliderWidth;
    const anchorWidthInPercentage = (sliderWidth - thumbWidth) / sliderWidth;

    // slider value and margin does not completely match.
    // remapping the percentage to sliders actual value area.
    // |()-----------------------| slider range. |:border, ():thumb
    //  ()---------------------()  anchor range.  ():border anchor

    const calculateMargin = (progress: number): number => {
        return progress * 100 * anchorWidthInPercentage + offsetInPercentage;
    }

    const value = isAnimated ? animatedValue : targetValue;

  return (
    <div className={className} ref={setRef}>
      <span className="track" style={{ width: `${anchorWidthInPercentage * 100}%` }} />

      <span className="hotspots">
        {cameraPoints.map(h => (
          <span key={h.id} className="hotspot" style={{ marginLeft: `${calculateMargin(h.position)}%` }}>
            {h.title}
          </span>
        ))}
      </span>
      <input className={thumbVisible} type="range" min={0} max={steps} value={value} onChange={onChange} onMouseUp={onMouseUp} onMouseDown={onMouseDown} />
    </div>
  );
};

export default styled(CameraSlider)`
  flex: 1;
  position: relative;
  width: 100%;
  margin-left: 25px;
  margin-right: 40px;

  /* styling for all browsers*/
  input[type='range'] {
    position: absolute;
    -webkit-appearance: none;
    width: 100%; /* Specific width is required for Firefox. */
    margin-top: 21px;
    margin-left: 15px;
    padding-right: 12px;
    background: transparent; /* otherwise white in Chrome */

    &.visible {
      opacity: 100%;
    }

    &.hidden {
      opacity: 0%;
    }
  }

  /* styling for chrome only*/
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: url('./icons/video-record.svg') #ffffff;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    cursor: pointer;
  }

  /* styling for firefox only*/
  input[type='range']::-moz-range-thumb {
    background: url('./icons/video-record.svg') #ffffff;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    cursor: pointer;
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
  }

  .track {
    position: absolute;
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: white;
    margin-top: 25px;
    margin-left: 30px;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  .hotspots {
    position: absolute;
    top: -22px;
    width: 100%;
    margin-left: 2px;

    .hotspot {
      position: absolute;
      height: 45px;
      width: 60px;
      padding-bottom: 17px;
      color: white;
      line-height: 40px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;

      &:after  {
        /* circle */
        content: ' ';
        position: absolute;
        bottom: -6px;
        left: 27px;
        width: 6px;
        height: 6px;
        background: white;
        border-radius: 100%;
      }
    }
  }
`;
