import { debounce } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { FeatureObject, FeatureSetObject } from '../../../../../shared/model/FeatureSet';

import { CustomerConfiguration } from '../../../../../shared/model/RawModel';
import { AppConfiguration } from '../../../../app/AppConfiguration';
import ActionIconButton from '../../../../components/ActionIconButton';
import ItemSwitcher from '../../../../components/ItemSwitcher';
import { ModificationState, modifyItem } from '../../../../components/modifyItem';
import { useItemFromUrl } from '../../../../components/useItemsFromServer';

type Props = {
  className?: string;
  activeFeatureSet?: FeatureSetObject;
  onClose: () => void;
  isVisible: boolean;
  hasPreviousFeature?: boolean;
  hasNextFeature?: boolean;
  onSelectPreviousFeature?: () => void;
  onSelectNextFeature?: () => void;
};

const updateInscription = async (
  url: string,
  value: string,
  setModificationState: (state: ModificationState) => void
) => {
  const body = JSON.stringify({ inscription: value });
  modifyItem(url, 'PATCH', body, setModificationState);
};
function findSelectedOptionTexture(fallbackTexture:string, featureObject?:FeatureObject):string{
  if(!featureObject)
    return fallbackTexture;
  const activeOptionSet = featureObject.optionSetObjects.find(oso => oso.options.some(o=> o.optionId === featureObject.selectedOptionId));
  const activeOption    = activeOptionSet?.options.find(o => o.optionId === featureObject.selectedOptionId);
  const activeTexture   = activeOption ? activeOption.texture : fallbackTexture;
  return activeTexture ? `${AppConfiguration.baseServerUrl}${activeTexture}` : fallbackTexture;
}

const DedicationPlateWidget: React.FunctionComponent<Props> = props => {
  const {
    className,
    isVisible,
    activeFeatureSet,
    onClose,
    hasPreviousFeature,
    hasNextFeature,
    onSelectPreviousFeature,
    onSelectNextFeature,
  } = props;
  const { customerConfigurationId } = useParams();
  const [modificationState, setModificationState] = React.useState<ModificationState>({
    error: null,
    reloadTrigger: false,
  });

  const [inscription, setInscription]             = React.useState<string>('');
  const [isInitialized, setInitialized]           = React.useState<boolean>(false);

  //Bugfix: default image is visible on page reload or page change
  //also this way is outdated
  //const defaultPlateImageUrl = './images/bg-plate-red@2x.png';
  const defaultPlateImageUrl = '';
  const [plateImageUrl, setPlateImageUrl] = React.useState<string>(findSelectedOptionTexture(defaultPlateImageUrl));


  //assign plate image only once. Do not assign default image back.
  React.useEffect(()=>{
    if(!activeFeatureSet)      
      return;

      const plateFeatureObject = activeFeatureSet?.featureObjects[0];
      const newPlateImageUrl   = `url(${findSelectedOptionTexture(defaultPlateImageUrl, plateFeatureObject)})`;
      const newPlateImageUrl2  = newPlateImageUrl.replace('.jpg', '2.jpg');
      if(inscription.length>0)
        setPlateImageUrl(newPlateImageUrl2);
      else
        setPlateImageUrl(newPlateImageUrl);

      // const newPlateImageUrl = '';
      // setPlateImageUrl(newPlateImageUrl);


  },[activeFeatureSet]);
  
  const debouncedUpdateInscription = React.useRef(debounce(updateInscription, 1000));

  const { item: configuration, error: loadError } = useItemFromUrl<CustomerConfiguration>(
    `${AppConfiguration.baseServerUrl}/api/customer-configurations/${customerConfigurationId}`,
    modificationState.reloadTrigger
    ); 

  const isMistral = configuration?.carModelId==='Mistral';
  let maxRowCount = 3;
  if(isMistral) maxRowCount = 1;
  
  React.useEffect(() => {
    if (isInitialized)
      return;

    if (configuration && configuration.inscription) {
      setInitialized(true);
      setInscription(configuration.inscription);
    }
  }, [configuration, isInitialized]
  );

  const modificationUrl = `${AppConfiguration.baseServerUrl}/api/customer-configurations/${customerConfigurationId}`;

  const valueChanged = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = ev.target.value;

    const plateFeatureObject = activeFeatureSet?.featureObjects[0];
    const newPlateImageUrl   = `url(${findSelectedOptionTexture(defaultPlateImageUrl, plateFeatureObject)})`;
    const newPlateImageUrl2  = newPlateImageUrl.replace('.jpg', '2.jpg');
    if(value.trim().length>0)
      setPlateImageUrl(newPlateImageUrl2);
    else
      setPlateImageUrl(newPlateImageUrl);

    setInscription(value);
    debouncedUpdateInscription.current(modificationUrl, value, setModificationState);
  };
  const onKeyUp = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    //limit row count
    var rowCount = ev.currentTarget.textContent?.split('\n').length;
    ev.currentTarget.setAttribute('rows', rowCount+'');
  };
  const onKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    //limit row count
    if(ev.key==="Enter")
    {
      var rowCount = ev.currentTarget.textContent?.split('\n').length || 0;
      if(rowCount>=maxRowCount) ev.nativeEvent.preventDefault();
      return;
    }
    //limit line length
    var maxLength = 35;
    var lines = (ev.currentTarget.textContent || '').split('\n');
    for(var i=0; i<lines.length; i++)
      if(lines[i].length>maxLength)
        lines[i] = lines[i].substring(0, maxLength);
    ev.currentTarget.value =  lines.join('\n');
  };

  if (loadError) {
    return (
      <div />
    );
  }

  let specials:string[] = [];
  if(isMistral) specials.push('dedicationplate_mis');

  const classNames   = [className, isVisible ? 'visible' : 'hidden'].filter(Boolean).join(' ');
  const classNamesBG = ['plate-area', ...specials].join(' ');
  const classNamesTA = [isMistral ? 'textarea_mis' : ''].join(' ');

  return (
    <div className={classNames}>

      <div className={classNamesBG} style={{ backgroundImage: plateImageUrl}}>
        {modificationState.error && <div>Had Error: {modificationState.error}</div>}
        <textarea className={classNamesTA} value={inscription} onChange={valueChanged} onKeyDown={onKeyDown} onKeyUp={onKeyUp} rows={maxRowCount} />
      </div>

      <div className="close-area" onClick={onClose} />

      <header className="dark-navigation">
        <h1>
          Dedication Plate
          <div className="button-on-circle">
            <ActionIconButton className="icon-close close-button" onClick={onClose} />
          </div>
        </h1>
        <ItemSwitcher
          visible={true}
          hasPrevious={hasPreviousFeature ? hasPreviousFeature : false}
          hasNext={hasNextFeature ? hasNextFeature : false}
          onSelectPrevious={onSelectPreviousFeature}
          onSelectNext={onSelectNextFeature}
        />
      </header>
      
    </div>
  );
};

export default styled(DedicationPlateWidget)`
  display: grid;
  width: 100vw;
  height: 100vh;
  /*background: url('./images/dedication-bg@2x.jpg');*/
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .close-area {
    position: absolute;
    margin-top: 80px;
    width: 100vw;
    height: 100vh;
    display:none;
  }

  .plate-area.dedicationplate_mis {
    top                 : 0;
    position            : absolute;
    width               : 100%;
    height              : 100%;
    background-position : center;
  }

  .plate-area > .textarea_mis {
    margin-top : 53vh;
    font-size  : 320%;
  }

  .no-close-area {
    position: absolute;
    left: 0;
    margin-top: 20vh;
    margin-right: 20vw;
    margin-left: 20vw;
    margin-bottom: 40vh;
    width: 60vw;
    height: 40vh;
  }

  header.dark-navigation {
    h1 {
      color: ${p => p.theme.colors.onboardingText};
      position: absolute;
      margin: 0;
      left: 0;
      right: 0;
      top: 0;
      font-family: 'ZwoLF Bold';
      font-size: 28px;
      line-height: 64px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;

      .button-on-circle {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 45px;
        height: 45px;
        margin-left: 10px;
        border-radius: 100px;
        background: ${p => p.theme.colors.onboardingText};
        display: flex;
        justify-content: center;
        align-items: center;

        .close-button {
          width: 35px;
          height: 35px;
          background-color: ${p => p.theme.colors.onboardingBackground};
        }
      }
    }

    div {
      width: 480px;
      height: 64px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      img.previous-switcher {
        position: relative;
        margin-top: 10px;
        width: 48px;
        height: 48px;
      }

      img.next-switcher {
        position: relative;
        margin-top: 10px;
        width: 48px;
        height: 48px;
      }
    }
  }

  .plate-area {
    width: 450px;
    min-height: 200px;
    top: 35%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-startq;

    textarea {
      margin:auto;
      margin-top: 25%;
      width: 100%;
      padding: 0;
      border: 1px dashed gray;
      border-radius: 0;
      color: #ffffff;
      text-align: center;
      font-family: 'BUGATTIText Regular';
      font-size: 26px;
      letter-spacing: 2px;
      background: none;
      /*overflow:hidden;*/
      /*overflow-wrap:normal;
      white-space:pre;*/
      resize:none;
    }
  }

  .plate-area.is-black {
    background: url('./images/bg-plate-black@2x.png');
    background-size: 100% 100%;
  }
  .plate-area.is-blue {
    background: url('./images/bg-plate-blue@2x.png');
    background-size: 100% 100%;
  }

  .plate-area.is-red {
    background: url('./images/bg-plate-red@2x.png');
    background-size: 100% 100%;
  }

  .inputnotvisible {
    display:none;
  }
`;
