import * as React                         from 'react';
import styled                             from "styled-components";
import { Action }                         from '../../../../shared/model/RawModel';


type Props = 
{
    className?: string;
    action: Action;
    onClick: ()=>void;
}

const CameraActionToggle: React.FunctionComponent<Props> = props => {
    const { className, action: action, onClick } = props;

    const isOn = action.state===1;

    return (
        <div className={`${className}`} >
            <label className="checkbox-container" htmlFor="toggle">
                <input id="toggle" type="checkbox" checked={isOn} onChange={onClick} />
                <span className="checkmark"></span>
            </label>
            <p>{action.title}</p>
        </div>
    );
}

export default styled(CameraActionToggle)`
  position: relative;
  margin-left: 14px;
  padding-top: 8px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
    margin-top: 3px;
    padding: 0 2px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  label {
    content: ' ';
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    width: 70px;
    height: 70px;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    align-self: flex-end;
    top: 0;
    left: 0;
    width: 70px;
    height: 48px;
    background-image: url('./images/switch-off@2x.png');
    background-size: 100% 100%;
  }

  /* Show the active switch image when checked */
  .checkbox-container input:checked ~ .checkmark {
    background-image: url('./images/switch-blue-on@2x.png');
    background-size: 100% 100%;
  }
`;
