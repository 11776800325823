import React from 'react';
import styled from 'styled-components';

import { OptionObject } from '../../../../../../shared/model/FeatureSet';
import { ID, Option } from '../../../../../../shared/model/RawModel';
import { AppConfiguration } from '../../../../../app/AppConfiguration';

type Props = {
  option: OptionObject;
  onSelect: (optionId: ID<Option>) => void;
  showLabel: boolean;
  className?: string;
  fixedSize?: React.CSSProperties;
};

const defaultOptionImageUrl = './images/placeHolderOption.png';
const OptionItem = (props: Props) => {
  const { option, onSelect, className, showLabel, fixedSize } = props;

  const textureUrl = `${AppConfiguration.baseServerUrl}${option.texture}`

  const label = showLabel && !option.hideLabel ? option.title : '';
  
  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);
  const [optionImageUrl, setOptionImageUrl] = React.useState<string>(textureUrl);

  const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setIsImageLoaded(true);
  }

  const onImageError= (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setOptionImageUrl(defaultOptionImageUrl);
    setIsImageLoaded(true);
  }

  const imageClassName = `option-image ${isImageLoaded ? 'fade-in' : ''}`;

  return (
    <li
      className={`${className} state-${option.status}`}
      title={option.title}
      onClick={() => onSelect(option.optionId)}
      style={{ ...fixedSize }}
    >
      <section className="option-image background" />
      <img className={imageClassName} src={optionImageUrl}  onLoad={onImageLoaded} onError={onImageError} alt=""/>
      <span className="option-title">{label}</span>
      {option.status === 'disabled' && <span className="reason-for-disabled">{option.reasonForDisabled}</span>}
      {option.status === 'unselected-preset-choice' && <img className="reset-icon" src="./icons/reset.svg" alt="" />}
    </li>
  );
};

export default styled(OptionItem)`
  min-width: 86px;
  height: 86px;
  cursor: pointer;
  position: relative;
  list-style: none;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  background-color: none;
  /* z-index: -1; */

  .option-image {
    position:absolute;
    left:0;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
    opacity:0; 
    transition: all 0s ease-out;
    
    &.background {
      background-color: black;
      opacity: 0.3;
    }

    &.fade-in {
      transition: all 300ms ease-out;
      opacity: 1;
    }
  }

  .reason-for-disabled {
    position: absolute;
    font-size: 12px;
    background-color: red;
    color: white;
    bottom: 0;
    left: 10px;
    right: 10px;
    padding: 3px;
  }

  &.state-selected-by-user,
  &.state-selected-from-preset {
    
    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
      min-width: 79px;
    }
    
    &:before {
      pointer-events: none;
      border: 4px solid black;
      height: 77px;
    }

    &:after {
      border: 3px solid white;
      height: 79px;
    }
  }

  &.state-unselected-preset-choice {
    position: sticky;
    left: 0px;
    right: 0px;
    /* box-shadow: -5px 0px 8px rgba(0, 0, 0, 0.3); */
    z-index: 3;

    > img.reset-icon {
      position: absolute;
      left: 5px;
      top: 5px;
      filter: drop-shadow(0 0 4px rgba(0,0,0,1));
    }
  }

  &.state-disabled {
    pointer-events: none;
    font-weight: 200;
    opacity: 0.5;
    cursor: not-allowed;
  }

  > span.option-title {
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: ${p => p.theme.colors.onboardingText};
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: -1px -1px 6px rgba(0, 0, 0, 0.5), 1px -1px 6px rgba(0, 0, 0, 0.5), -1px 1px 6px rgba(0, 0, 0, 0.5), 1px 1px 6px rgba(0, 0, 0, 0.5);
  }
`;
