import * as React from 'react';

export const useAnimationFrame = (
  update: (time: number, deltaTime: number) => boolean | void,
  runImmediately: boolean = true,
  deps?: React.DependencyList
) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef<number>();
  const previousTimeRef = React.useRef<number>();

  const stop = () => {
    if (requestRef.current !== undefined) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = undefined;
      previousTimeRef.current = undefined;
    }
  };

  const start = () => {
    if (requestRef.current === undefined) {
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  const animate = (time: number) => {
    let done: boolean | void = false;

    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      done = update(time, deltaTime);
    }

    if (done) {
      stop();
    } else {
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  React.useEffect(() => {
    if (runImmediately) {
      start();
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = undefined;
        previousTimeRef.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]); // Make sure the effect runs only when dependency changes

  return {
    start,
    stop,
  };
};
