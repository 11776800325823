import { useMemo } from "react";

export type ViewMode = 'feature-list' | 'active-feature' | 'garage' | 'presentation' | 'play-showroom' | 'play-tablet';
export type ViewState = {
    areViewChevronsVisible: boolean;
    isFeatureViewHeaderVisible: boolean;
    areHeaderIconsVisible: boolean;
    isFeatureSelectorVisible: boolean;
    isFeatureOptionChooserVisible: boolean;
    isGarageVisible: boolean;
    isViewNavigatorActivatable: boolean;
    isCameraControllerVisible: boolean;
    isAppMenuVisible: boolean;
}
export const useViewModeController = (viewMode: ViewMode) => {
    return useMemo(()=>{
      switch(viewMode){
        case 'feature-list':
          return {
            areViewChevronsVisible        : false,
            isFeatureViewHeaderVisible    : true,
            areHeaderIconsVisible         : true,
            isFeatureSelectorVisible      : true,
            isFeatureOptionChooserVisible : false,
            isGarageVisible               : false,
            isViewNavigatorActivatable    : true,
            isCameraControllerVisible     : false,
            isAppMenuVisible              : true
          }
        case 'active-feature':
          return {
            areViewChevronsVisible        : false,
            isFeatureViewHeaderVisible    : false,
            areHeaderIconsVisible         : false,
            isFeatureSelectorVisible      : false,
            isFeatureOptionChooserVisible : true,
            isGarageVisible               : false,
            isViewNavigatorActivatable    : false,
            isCameraControllerVisible     : false,
            isAppMenuVisible              : true
          }
        case 'garage':
          return {
            areViewChevronsVisible        : true,
            isFeatureViewHeaderVisible    : false,
            areHeaderIconsVisible         : false,
            isFeatureSelectorVisible      : false,
            isFeatureOptionChooserVisible : false,
            isGarageVisible               : true,
            isViewNavigatorActivatable    : false,
            isCameraControllerVisible     : false,
            isAppMenuVisible              : true
          }
        case 'presentation':
          return {
            areViewChevronsVisible        : true,
            isFeatureViewHeaderVisible    : false,
            areHeaderIconsVisible         : false,
            isFeatureSelectorVisible      : false,
            isFeatureOptionChooserVisible : false,
            isGarageVisible               : false,
            isViewNavigatorActivatable    : true,
            isCameraControllerVisible     : false,
            isAppMenuVisible              : true
          }
        case 'play-showroom':
          return {
            areViewChevronsVisible        : false,
            isFeatureViewHeaderVisible    : false,
            areHeaderIconsVisible         : false,
            isFeatureSelectorVisible      : false,
            isFeatureOptionChooserVisible : false,
            isGarageVisible               : false,
            isViewNavigatorActivatable    : false,
            isCameraControllerVisible     : true,
            isAppMenuVisible              : false
          }
        case 'play-tablet':
          return {
            areViewChevronsVisible        : false,
            isFeatureViewHeaderVisible    : false,
            areHeaderIconsVisible         : true,
            isFeatureSelectorVisible      : false,
            isFeatureOptionChooserVisible : false,
            isGarageVisible               : false,
            isViewNavigatorActivatable    : false,
            isCameraControllerVisible     : true,
            isAppMenuVisible              : true
          }
      }
    },[viewMode])
}