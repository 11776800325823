import React from 'react';
import styled from 'styled-components';

import AppRoutes from './AppRoutes';
import AppTheme from './AppTheme';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  className?: string;
};

const App: React.FunctionComponent<Props> = props => {
  return (
    <div className={props.className}>
      <AppTheme>
        <AppRoutes />
        <ToastContainer transition={Slide}/>
      </AppTheme>
    </div>
  );
};

export default styled(App)`
  @font-face {
    font-family: 'ZwoLF Bold'; // bold
    src: url('./fonts/ZwBdScLf.eot'); /* IE9 Compat Modes */
    src: url('./fonts/ZwBdScLf.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/ZwBdScLf.otf') format('opentype'),
      /* Open Type Font */ url('./fonts/ZwBdScLf.svg') format('svg'),
      /* Legacy iOS */ url('./fonts/ZwBdScLf.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('./fonts/ZwBdScLf.woff') format('woff'),
      /* Modern Browsers */ url('./fonts/ZwBdScLf.woff2') format('woff2'); /* Modern Browsers */
  }

  @font-face {
    font-family: 'ZwoLF SemiBold'; // semi bold
    src: url('./fonts/ZwSdPlLf.eot'); /* IE9 Compat Modes */
    src: url('./fonts/ZwSdPlLf.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/ZwSdPlLf.otf') format('opentype'),
      /* Open Type Font */ url('./fonts/ZwSdPlLf.svg') format('svg'),
      /* Legacy iOS */ url('./fonts/ZwSdPlLf.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('./fonts/ZwSdPlLf.woff') format('woff'),
      /* Modern Browsers */ url('./fonts/ZwSdPlLf.woff2') format('woff2'); /* Modern Browsers */
  }

  @font-face {
    font-family: 'ZwoLF SemiLight'; // semi light
    src: url('./fonts/ZwStPlLf.eot'); /* IE9 Compat Modes */
    src: url('./fonts/ZwStPlLf.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/ZwStPlLf.otf') format('opentype'),
      /* Open Type Font */ url('./fonts/ZwStPlLf.svg') format('svg'),
      /* Legacy iOS */ url('./fonts/ZwStPlLf.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('./fonts/ZwStPlLf.woff') format('woff'),
      /* Modern Browsers */ url('./fonts/ZwStPlLf.woff2') format('woff2'); /* Modern Browsers */
  }

  @font-face {
    font-family: 'BUGATTIDisplay Regular';
    src:  url('./fonts/BUGATTIDisplay-Regular.woff') format('woff'),
          url('./fonts/BUGATTIDisplay-Regular.woff2') format('woff2'),
          url('./fonts/BUGATTIDisplay-Regular.ttf') format('truetype');
         
  }

  @font-face {
    font-family: 'BUGATTIText Regular';
    src:  url('./fonts/BUGATTIText-Regular.woff') format('woff'),
          url('./fonts/BUGATTIText-Regular.woff2') format('woff2'),
          url('./fonts/BUGATTIText-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BUGATTIText Bold';
    src:  url('./fonts/BUGATTIText-Bold.woff') format('woff'),
          url('./fonts/BUGATTIText-Bold.woff2') format('woff2'),
          url('./fonts/BUGATTIText-Bold.ttf') format('truetype');
  }

  ::-webkit-scrollbar {
    height: 16px; /* height of the entire scrollbar */
    width: 16px; /* width of the entire scrollbar */
    background: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent !important;
    -webkit-appearance: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: white; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 6px solid transparent; /* creates padding around scroll thumb */
  }

  
  /* ----------------------------- TOAST SETUP --------------------------------------*/

  .Toastify__toast-container--top-center {
    top: 64px;
  }
  
  .Toastify__toast--default,
  .Toastify__toast--warning,
  .Toastify__toast--error {
    min-width: 420px;
    margin: 0;
    margin-top: 4px;
    line-height: 2em;
    padding: 20px;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(8px);
  }
  
  .Toastify__close-button {
    position: relative;
    right: -10px;
    top: -10px;
  }
  
  .Toastify__close-button > svg {
    height: 18px;
    width: 15px;
    opacity: 0.7;
  }

  .Toastify__progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0.5;
    transform-origin: left;
  }

  .Toastify__toast--default { 
    color: white;
    background-color: rgba(128, 128, 128, 0.85);
  }
  .Toastify__toast--default> .Toastify__progress-bar { background: rgb(160, 160, 160); }
  .Toastify__toast--default> .Toastify__close-button > svg { fill: white; }

  .Toastify__toast--warning { color: #EFD830; }
  .Toastify__toast--warning > .Toastify__progress-bar { background-color: #EFD830; }
  .Toastify__toast--warning > .Toastify__close-button > svg { fill: #EFD830; }

  .Toastify__toast--error { color: #FF0600; }
  .Toastify__toast--error > .Toastify__progress-bar { background-color: #FF0600; }
  .Toastify__toast--error > .Toastify__close-button > svg { fill: #FF0600; }



`;
