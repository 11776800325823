import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject, FeatureSetObject, OptionObject, OptionSetObject } from '../../../../../shared/model/FeatureSet';
import { Feature, ID, Option } from '../../../../../shared/model/RawModel';
import ActionIconButton from '../../../../components/ActionIconButton';
import { isFeatureActivated, isFeatureDeactivated } from '../../activationFeatureLogic';
import CustomerWishesWidget from '../Fullscreen/CustomerWish/CustomerWishesWidget';
import FeatureSettingsRegionArea from './FeatureSettingsRegion/FeatureSettingsRegionArea';
import FeatureToggle from './FeatureToggle';
import OptionsArea from './OptionsArea/OptionsArea';

type Props = {
  className?: string;
  visible: boolean;
  activeFeatureSet?: FeatureSetObject;
  onClose: () => void;
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  onResetFeatureToPreset: (featureId: ID<Feature>) => void;
  hasPreviousFeature?: boolean;
  hasNextFeature?: boolean;
  onSelectPreviousFeature?: () => void;
  onSelectNextFeature?: () => void;
};
export const createFeatureCallbackFunctions = (
  featureId: ID<Feature>,
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void,
  onResetFeatureToPreset: (featureId: ID<Feature>) => void
) => {
  return {
    onSelect: (optionId: ID<Option>) => {
      onSelectOption(featureId, optionId);
    },
    onResetToPreset: () => {
      onResetFeatureToPreset(featureId);
    },
  };
};

const FeatureAtBottomLayout: React.FunctionComponent<Props> = props => {
  const {
    className,
    visible,
    activeFeatureSet,
    onSelectOption,
    onResetFeatureToPreset,
    onClose,
    onSelectPreviousFeature,
    onSelectNextFeature,
    hasPreviousFeature,
    hasNextFeature,
  } = props;
  const [isOpenWishPopUp, setWishPopUpOpen] = React.useState<boolean>(false);
  const classNames = [className, visible ? 'visible' : 'hidden'].filter(Boolean).join(' ');
  const [runTimeWishCount, setRunTimeWishCount] = React.useState<number | undefined>(undefined);
  const headerRef = React.useRef<HTMLElement | null>(null);

  const wishCount = runTimeWishCount ? runTimeWishCount : activeFeatureSet ? activeFeatureSet.customerWishCount : 0;
  const wishPopUpState = wishCount > 0 ? 'whishes-set' : 'wish-popup';

  const isDeactivated = activeFeatureSet
    ? activeFeatureSet.featureObjects.find(f => isFeatureDeactivated(f)) !== undefined
    : false;
  const isActivated = activeFeatureSet
    ? activeFeatureSet.featureObjects.find(f => isFeatureActivated(f)) !== undefined
    : false;

  const optionAreaFeature = activeFeatureSet
    ? activeFeatureSet.featureObjects.find(f => f.uiContainer === 'optionsArea')
    : undefined;
  const optionAreaSelected =
    optionAreaFeature &&
    optionAreaFeature.optionSetObjects
      .flatMap(o => o.options)
      .find(o => o.optionId === optionAreaFeature.selectedOptionId);


  React.useEffect(() => {
    //reset customer wish pop-up when navigating between features
    setWishPopUpOpen(false);
    activeFeatureSet && setRunTimeWishCount(activeFeatureSet.customerWishCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFeatureSet, optionAreaFeature]);

  const featuresInSettingsRegionArea = activeFeatureSet
    ? activeFeatureSet.featureObjects.filter(f => f.uiContainer === 'featureSettingsRegion')
    : undefined;

  const featureActivator = activeFeatureSet
    ? activeFeatureSet.featureObjects.find(f => f.uiContainer === 'onOffToggleInFeatureSettingsRegion')
    : undefined;

  const optionAreaTitle = optionAreaSelected ? optionAreaSelected.title : '';
  const activatedTitle = isActivated && optionAreaTitle === '' ? 'selected' : optionAreaTitle;
  const layoutTitle = isDeactivated ? 'Not selected' : activatedTitle;

  const layoutTitleStyleClass = isDeactivated ? 'choice-placeholder' : 'selected-option';

  const doesFeatureOptionMatchSelection = (optionSet: OptionSetObject): Boolean => {
    if (optionSet.filterFeatureId === '') return true;

    const filteringFeature = activeFeatureSet
      ? activeFeatureSet.featureObjects.find(f => f.id === optionSet.filterFeatureId)
      : undefined;
    return filteringFeature !== undefined && filteringFeature.selectedOptionId === optionSet.filterOptionId;
  };
  const filterOptions = (feature: FeatureObject): OptionObject[] => {
    const matchingOptionSet = feature.optionSetObjects.find(o =>
      doesFeatureOptionMatchSelection(o)
    );
    if (matchingOptionSet !== undefined) return matchingOptionSet.options;
    else return [];
  };

  return (
    <div className={classNames}>
      {visible && <div className="outside-overlay" onClick={onClose} />}

      {optionAreaFeature && (
        <OptionsArea
          feature={optionAreaFeature}
          options={filterOptions(optionAreaFeature)}
          headerRef={headerRef}
          {...createFeatureCallbackFunctions(optionAreaFeature.id, onSelectOption, onResetFeatureToPreset)}
        />
      )}

      {
        <header ref={headerRef}>
          <section className="button-shape previous">
            <ActionIconButton
              className="icon-chevron-left-narrow-thin"
              onClick={onSelectPreviousFeature}
              disabled={!hasPreviousFeature}
            />
          </section>
          <section className="center">
            <section className="feature-name">
              {featureActivator && (
                <FeatureToggle
                  feature={featureActivator}
                  options={filterOptions(featureActivator)}
                  {...createFeatureCallbackFunctions(featureActivator.id, onSelectOption, onResetFeatureToPreset)}
                />
              )}
              <h3>{activeFeatureSet ? activeFeatureSet.title : ''}</h3>
            </section>
            <div className="choices-wrapper">
              <span className="selected-material-finish">
                {featuresInSettingsRegionArea && (
                  <FeatureSettingsRegionArea
                    features={featuresInSettingsRegionArea}
                    onSelectOption={onSelectOption}
                    onResetFeatureToPreset={onResetFeatureToPreset}
                    filterOptions={filterOptions}
                  />
                )}
              </span>
              <span className={layoutTitleStyleClass}>{layoutTitle}</span>
              {!isDeactivated && (
                <span
                  className={`wish-popup ${wishPopUpState}`}
                  onClick={() => {
                    setWishPopUpOpen(true);
                  }}
                >
                  <div className="bubble-bg"></div>
                  <p className="customer-wish-count">{wishCount > 0 ? wishCount : ''}</p>
                </span>
              )}
            </div>
          </section>
          <section className="button-shape next">
            <ActionIconButton
              className="icon-chevron-right-narrow-thin open-next"
              onClick={onSelectNextFeature}
              disabled={!hasNextFeature}
            />
          </section>
        </header>
      }
      {isOpenWishPopUp && activeFeatureSet && (
        <CustomerWishesWidget
          className="customer-wishes"
          isVisible={visible && isOpenWishPopUp}
          filterContent={true}
          filterFeatureSetId={activeFeatureSet.id}
          featureTitle={activeFeatureSet.title}
          onCloseWishView={() => {
            setWishPopUpOpen(false);
          }}
          onWishCountChanged={(count: number) => {
            setRunTimeWishCount(count);
          }}
        />
      )}
    </div>
  );
};
export default styled(FeatureAtBottomLayout)`


  .customer-wishes {
    position: absolute;
    bottom:0;
    
    &.hidden {
      transform: translateY(-100%);
    }

    &.visible {
      transform: translateY(0);
    }
  }

  > section.button-shape.previous {
    box-shadow: -2px 2px 4px 0 rgba(255,0,0,0.50);
  }

  > header {
    position: fixed;
    bottom: 0px;
    right: 0px;
    min-width: 456px;
    background-color: ${p => p.theme.colors.background};
    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: center;

    > section.center {
      height: 86px;
      margin-left: 73px;
      padding-bottom: 4px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > section.feature-name {
        min-height: 36;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > section {
        }

        > h3 {
          margin: 0;
          padding-left: 12px;
          line-height: 36px;
          font-size: 20px;
          font-family: 'ZwoLF SemiBold';
          font-weight: bold;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }

      > .choices-wrapper {
        margin-top: -4px;
        min-height: 27px;
        padding-left: 8px;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        > span.selected-option,
        > span.selected-material-finish select,
        > span.selected-material-finish label {
          line-height: 25px;
        }

        > span.selected-option {
          font-size: 16px;
          margin-left: 3px;
        }

        > span.selected-material-finish select,
        > span.selected-material-finish label {
          font-size: 16px;
          text-transform: uppercase;
          line-height: 25px;
          appearance: none;
          background: transparent;
          padding: 0 5px;
        }

        > span.selected-material-finish {
          select,
          label {
            color: ${p => p.theme.colors.foreground};
            margin-right: 2px;
            margin-left: 4px;
          }

          input {
            display: block;
            position: absolute;
            top: 42px;
            /* left:120px; */
            width: 100px;
            height: 20px;
          }
        }

        > span.selected-material-finish > span {
          display: flex;
          flex-direction: row;
        }

        > span.choice-placeholder {
          margin-top: -1px;
          padding-left: 28px;
          color: #a9b2bd;
        }

        > span.wish-popup {
          cursor: pointer;
          width: 30px;
          height: 27px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          p.customer-wish-count,
          .bubble-bg {
            position: absolute;
            top: 42px;
            margin-top: 2px;
            margin-left: 4px;
            width: 25px;
            height: 25px;
          }

          p.customer-wish-count {
            padding-top: 4px;
            margin: 0;
            font-size: 14px;
            font-family: 'ZwoLF SemiBold';
            color: ${p => p.theme.colors.background};
          }

          .bubble-bg {
            content: ' ';
            background-color: ${p => p.theme.colors.foreground};
            mask-image: url('./icons/wishes.svg');
            mask-size: 100% 100%;
            display: flex;
          }
        }

        > span.whishes-set {
          p.customer-wish-count {
            margin-top: 1px;
            margin-left: 4px;
            color: ${p => p.theme.colors.background};
          }

          .bubble-bg {
            mask-image: url('./icons/wishes-filled.svg');
          }
        }
      }
    }

    section.button-shape {
      height: 86px;
      width: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      > ${ActionIconButton} {
        width: 80px;
        height: 80px;
        mask-size: 50% 50%;
        mask-repeat: no-repeat;
      }
    }

    section.button-shape.previous {
      position: absolute;
      left: -33px;
      width: 106px;
      background-image: url('./images/picker-left-bg@2x.png');

      > ${ActionIconButton} {
        left: 5px;
      }
    }

    section.button-shape.next {
      width: 82px;
      background-image: url('./images/picker-right-bg@2x.png');

      > ${ActionIconButton} {
        left: 8px;
      }
    }
  }
`;
