import { useEffect } from 'react';

export type SwipeDirectionEnum = 'up' | 'down' | 'left' | 'right';
type Point = { x: number, y: number };

export function useSwipeAndClick(
    swipeContainer: HTMLElement | null,
    onSwiped: (direction: SwipeDirectionEnum) => void,
    onClicked: () => void
) {

    useEffect(() => {
        let lastContainer: HTMLElement | null = null;
        let hold: boolean = false;

        let startPoint: Point = { x: 0, y: 0 };
        const swipeThreshold: number = 100;

        //max distance allowed at the same time in perpendicular direction
        const slack: number = 50;
        const click = () => {
            if (hold === false) {
                hold = true;
                onClicked();
                setTimeout(() => {
                    hold = false;
                }, 500);
            }
        }
        const swipe = (direction: SwipeDirectionEnum) => {
            if (hold === false) {
                hold = true;
                onSwiped(direction);
                setTimeout(() => {
                    hold = false;
                }, 500);
            }
        }

        const onMouseDown = (ev: MouseEvent) => {
            startPoint.x = ev.pageX;
            startPoint.y = ev.pageY;
        }

        const onMouseMove = (ev: MouseEvent) => {
            ev.preventDefault();
        }

        const onMouseUp = (ev: MouseEvent) => {
            const endPoint = { x: ev.pageX, y: ev.pageY };
            evaluateInteraction(endPoint);
            ev.stopPropagation();
        }

        const onTouchStart = (ev: TouchEvent) => {
            const touch = ev.changedTouches[0];
            startPoint.x = touch.pageX;
            startPoint.y = touch.pageY;
        }

        const onTouchMove = (ev: TouchEvent) => {
            ev.preventDefault();
        }

        const onTouchEnd = (ev: TouchEvent) => {
            const touch = ev.changedTouches[0];
            const endPoint = { x: touch.pageX, y: touch.pageY };
            evaluateInteraction(endPoint);
            ev.stopPropagation();
        }

        const evaluateInteraction = (endPoint: Point) => {
            const dX = endPoint.x - startPoint.x;
            const dY = endPoint.y - startPoint.y;

            if (Math.abs(dX) >= swipeThreshold && Math.abs(dY) <= slack) {
                const swipeDirection = (dX < 0) ? 'left' : 'right';
                swipe(swipeDirection);
            } else if (Math.abs(dY) >= swipeThreshold && Math.abs(dX) <= slack) {
                const swipeDirection = (dY < 0) ? 'up' : 'down';
                swipe(swipeDirection);
            } else {
                click();
            }
        }

        if (swipeContainer) {
            lastContainer = swipeContainer;

            lastContainer.addEventListener('mousedown', onMouseDown);
            lastContainer.addEventListener('mousemove', onMouseMove);
            lastContainer.addEventListener('mouseup', onMouseUp);

            lastContainer.addEventListener('touchstart', onTouchStart, false);
            lastContainer.addEventListener('touchmove', onTouchMove, false);
            lastContainer.addEventListener('touchend', onTouchEnd);
        }

        return () => {
            if (lastContainer && lastContainer !== swipeContainer) {
                lastContainer.addEventListener('mousedown', onMouseDown);
                lastContainer.addEventListener('mousemove', onMouseMove);
                lastContainer.addEventListener('mouseup', onMouseUp);


                lastContainer.removeEventListener('touchstart', onTouchStart);
                lastContainer.removeEventListener('touchmove', onTouchMove);
                lastContainer.removeEventListener('touchend', onTouchEnd);

                lastContainer = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swipeContainer]);

}