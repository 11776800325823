import * as React from 'react';
import styled from 'styled-components';

import { CustomerWishObject } from '../../../../../../shared/model/CustomerWishSet';
import ActionIconButton from '../../../../../components/ActionIconButton';

type Props = {
  className?: string;
  customerWish: CustomerWishObject;
  onValueChanged: (value: string) => void;
  onDeleted: () => void;
  isFocusedOnStart?: boolean;
};

const CustomerWishItem: React.FunctionComponent<Props> = props => {
  const { customerWish, onValueChanged, onDeleted, isFocusedOnStart } = props;
  const inputRef = React.useRef<HTMLTextAreaElement | null>(null);

  const classes = `text ${customerWish.wish ? '' : 'default-value'}`;

  const valueChanged = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = ev.target.value;
    onValueChanged(value);
  };

  React.useEffect(() => {
    if (isFocusedOnStart)
      setTimeout(() => { inputRef && inputRef.current && inputRef.current.select(); }, 500);
  }, [isFocusedOnStart]);

  const title = customerWish.title ? customerWish.title : 'custom wish';

  return (
    <li className={props.className}>
      <h3 className="related-feature">{title}</h3>
      <span className="wish-line">
        <img className="icon" src="./icons/dot.svg" alt="" />
        <textarea
          className={classes}
          ref={inputRef}
          value={customerWish.wish}
          placeholder="note all special wishes inline here..."
          onChange={valueChanged}
        ></textarea>
        <ActionIconButton className="icon icon-delete" onClick={onDeleted} />
      </span>
    </li>
  );
};
export default styled(CustomerWishItem)`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 18px 0 0 0;
    padding-left: 41px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${p => p.theme.colors.onboardingText};
  }

  img,
  button {
    width: 40px;
    height: 49px;
  }

  button {
    margin: 0 8px;
    mask-size: 80%;
    mask-repeat: no-repeat;
  }

  span.wish-line {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    button {
      margin-top: 5px;
      width: 35px;
      height: 35px;
      background: ${p => p.theme.colors.onboardingText};
      opacity: 0.7;
      transition: opacity 250ms;

      &:hover {
        background: ${p => p.theme.colors.bugattiRed};
        opacity: 1;
        transition: opacity 250ms;
      }
    }
  }

  textarea.text {
    height: 84px;
    resize: none;
    white-space: normal;
    line-height: 30px;
    font-size: 28px;
    font-family: 'ZwoLF SemiLight';
    color: ${p => p.theme.colors.onboardingText};
    padding: 8px 0 0 0;
    background: transparent;

    &::placeholder {
      color: ${p => p.theme.colors.onboardingText};
      opacity: 0.4;
    } 
  }
`;
