import React from 'react';
import styled from 'styled-components';

import { FeatureSetObject } from '../../../../shared/model/FeatureSet';
import { Feature, ID, Option } from '../../../../shared/model/RawModel';
import FeatureAtBottomLayout from './FeatureAtBottom/FeatureAtBottomLayout';
import FullscreenLayout from './Fullscreen/FullscreenLayout';
import SidepanelLayout from './SidePanel/SidepanelLayout';

type Props = {
  className?: string;
  visible: boolean;
  activeFeatureSet?: FeatureSetObject;
  onClose: () => void;
  onSelectOption: (featureId: ID<Feature>, optionId: ID<Option>) => void;
  onResetFeatureToPreset: (featureId: ID<Feature>) => void;
  onSetMainFeatureAsConfigured: (featureSetObject: FeatureSetObject) => void;
  hasPreviousFeature?: boolean;
  hasNextFeature?: boolean;
  onSelectPreviousFeature?: () => void;
  onSelectNextFeature?: () => void;
};

const FeatureOptionChooser: React.FunctionComponent<Props> = props => {
  const { activeFeatureSet, onSetMainFeatureAsConfigured, visible } = props;
  React.useEffect(() => {
    if (activeFeatureSet !== undefined && !activeFeatureSet.isConfigured) {
      onSetMainFeatureAsConfigured(activeFeatureSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFeatureSet, visible]);

  const featureLayout = activeFeatureSet !== undefined ? activeFeatureSet.layout : '';
  const layoutProps = {
    onClose: props.onClose,
    onSelectOption: props.onSelectOption,
    onResetFeatureToPreset: props.onResetFeatureToPreset,
    hasPreviousFeature: props.hasPreviousFeature,
    hasNextFeature: props.hasNextFeature,
    onSelectPreviousFeature: props.onSelectPreviousFeature,
    onSelectNextFeature: props.onSelectNextFeature,
  };
  return (
    <div className={props.className}>
      <FeatureAtBottomLayout
        className={props.className}
        activeFeatureSet={activeFeatureSet}
        visible={visible && featureLayout === 'featureAtBottom'}
        {...layoutProps}
      />
      <FullscreenLayout
        className={props.className}
        activeFeatureSet={activeFeatureSet}
        visible={visible && featureLayout === 'fullscreen'}
        {...layoutProps}
      />
      <SidepanelLayout
        className={props.className}
        activeFeatureSet={activeFeatureSet}
        visible={visible && featureLayout === 'sidePanel'}
        {...layoutProps}
      />
    </div>
  );
};

export default styled(FeatureOptionChooser)`
  min-height: 86px;

  .widget-section {
    position: relative;
  }

  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

  &.hidden {
    transform: translateY(5000px);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0px);
    opacity: 1;
  }

  .side-panel-screen {
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

    &.hidden {
      transform: translateX(100vw);
      opacity: 0;
    }

    &.visible {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`;
