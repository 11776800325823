import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useAnimationFrame } from './useAnimationFrame';

type Props = {
    className?: string;
    visible: boolean;
    message: string;
    additionalMessage: string;
};

const LoadingSpinner: React.FunctionComponent<Props> = props => {
  const {className, visible, message, additionalMessage} = props;
  const processedProgress = 20;
  const radius = 250;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - processedProgress / 100 * circumference;

  const classNames = `state-${visible?'visible':'hidden'} ${className}`;

  const animationLength = 4000; //milisecond

  const [progress, setProgress] = useState<number>(0);
  const startTimeRef = React.useRef<number>();

  const controlAnimation = useAnimationFrame((t, delta) => {
    if (startTimeRef.current === undefined) {
      startTimeRef.current = t - delta;
    }
    const elapsedTime = t - startTimeRef.current;
    const normalizedTime = ((elapsedTime) % animationLength) / animationLength;
    setProgress(normalizedTime);
  }, false);


  React.useEffect(()=>{
    if(visible)
      controlAnimation.start();
    else
      controlAnimation.stop();
  },[visible, controlAnimation]);

  const rotationDegree = progress * 360;
  const rotationStyle = `rotate(${rotationDegree}deg)`;

  return (
      <div className={classNames}>
          <svg
              className="progress-ring"
              width="100vw"
              height="100vh">
              <circle
                  className="progress-ring__circle"
                  stroke="white"
                  strokeWidth="2"
                  fill="transparent"
                  r={radius}
                  cx="50.5vw"
                  cy="50vh"
                  strokeDasharray={`${circumference}px`}
                  strokeDashoffset={offset}
                  style={{transform:rotationStyle}} />
          </svg>
          <div className="label-container">
            <p className="label">
                {message}
            </p>
            <p className="additional-message">
              {additionalMessage}
            </p>
          </div>
      </div>);
};


export default styled(LoadingSpinner)`
position: absolute;
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
transition: opacity 1s;

&.state-visible{
  opacity: 0.6;
}

&.state-hidden{
  opacity: 0;
  pointer-events: none;
}

.label-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .label,
  .additional-message {
    color: white;
    margin: 0;
    padding: 0;
    font-size: 28px;
    text-transform: uppercase;
    font-family: 'ZwoLF Bold', sans-serif;
  }

  .additional-message {
    font-size: 18px;
    opacity: 0.5;
  }
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform-origin: 50% 50%;
}         

`;