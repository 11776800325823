import React from 'react';
import styled from 'styled-components';

import { FeatureSetObject } from '../../../shared/model/FeatureSet';
import { FeatureSet, ID } from '../../../shared/model/RawModel';
import FeatureItem from './FeatureItem';

type Props = {
  className?: string;
  visible: boolean;
  features: ReadonlyArray<FeatureSetObject>;
  activeFeatureSetId: ID<FeatureSet> | null;
  onSelect: (feature: FeatureSetObject) => void;
  highlightNextView: boolean;
  highlightPreviousView: boolean;
  hasPreviousView: boolean;
  hasNextView: boolean;
  onSelectPreviousView: () => void;
  onSelectNextView: () => void;
};

const FeatureSelector = (props: Props) => {
  const {
    className,
    visible,
    features,
    onSelect,
    activeFeatureSetId,
    hasPreviousView,
    hasNextView,
    onSelectPreviousView,
    onSelectNextView,
    highlightNextView,
    highlightPreviousView,
  } = props;

  const classNames = ['FeatureSelector',
    className,
    visible ? 'visible' : 'hidden',
    hasNextView ? '' : 'isLastView',
    hasPreviousView ? '' : 'isFirstView',
  ].filter(Boolean).join(' ');
  const buttonSizeState = hasPreviousView && hasNextView ? 'max-width-button' : 'constant-width-button';
  const featureLeanDirection = hasPreviousView ? 'lean-start' : 'lean-end';

  return (
    <div className={classNames}>
      {hasPreviousView && (
        <span
          className={`button-region left ${buttonSizeState} ${highlightPreviousView ? 'state-next' : ''}`}
          onClick={onSelectPreviousView}
        >
          <span className="button-region-background">
            <img className="button-region-icon" src="./icons/chevron-left-narrow-thin.svg" alt="" />
          </span>
          <img className="right" src="./icons/feature-right.svg" alt="" />
        </span>
      )}
      <ol className={featureLeanDirection}>
        {features.map(f => (
          <FeatureItem
            key={f.id}
            feature={f}
            onSelect={f => onSelect(f)}
            isActive={activeFeatureSetId ? activeFeatureSetId === f.id : false}
          />
        ))}
      </ol>
      {hasNextView && (
        <span
          className={`button-region right  ${buttonSizeState} ${highlightNextView ? 'state-next' : ''}`}
          onClick={onSelectNextView}
        >
          <img className="left" src="./icons/feature-left.svg" alt="" />
          <span className="button-region-background">
            <img className="button-region-icon" src="./icons/chevron-right-narrow-thin.svg" alt="" />
          </span>
        </span>
      )}
    </div>
  );
};

export default styled(FeatureSelector)`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: nowrap; */

 
  .button-region {
    /* flex-shrink: 3;
    flex-grow: 3; */
    height: 57px;
    opacity: 50%;
    display: flex;
    flex-direction: row;

    &.left .button-region-background {
      justify-content: flex-start;
    }
    
    &.right .button-region-background {
      justify-content: flex-end;
    }

    &.state-next {
      opacity: 100%;
    }

    &.max-width-button {
      width: 100%;
    }
    
    &.constant-width-button {
      width: 72px;
    }

    .button-region-background {
      background-color: ${p => p.theme.colors.background};
      width: 100%;
      align-content: center;
      display: flex;
      flex-direction: row;

      .button-region-icon {
        align-self: center;
        margin: 0;
        height: 80%;
        color: ${p => p.theme.colors.foreground};
        filter: invert();
      }
    }

    &.left,
    &.right {
      flex-shrink: 20;
      flex-grow: 1;
      flex-basis: 36px;
    }

    &.right > span > img.button-region-icon {
      width: 36px;
    }

    &.left > span > img.button-region-icon {
      width: 36px;
    } 
  }
  
  &.isFirstView,
  &.isLastView  {

    > ol,
    > span {
      max-width: fit-content;
    }
    
    span.left > span.button-region-background,
    span.right > span.button-region-background {
      width: 100px;
    }
  }

  &.isFirstView {
    justify-content: flex-end;
  }

  &.isLastView {
    justify-content: flex-start;
  }

  > ol {
    display: flex;
    align-items: center;
    margin: 0 12px;
    padding: 0;
    flex: 5;
    margin-left: -22px;
    margin-right: -22px;

    &.lean-start {
      justify-content: flex-start;
    }

    &.lean-end {
      justify-content: flex-end;
    }

    > li {
      &:not(:first-child) {
        margin-left: -22px;
      }
    }
  }

  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

  &.hidden {
    transform: translateY(250px);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0px);
    opacity: 1;
  }

`;
