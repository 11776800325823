import * as React from 'react';
import styled from 'styled-components';
import { ViewInfoUI } from '../../../../shared/model/CameraControllerResponse';

type Props = {
  className?: string;
  view: ViewInfoUI;
  isActive: boolean;
  onClicked: () => void;
};

const CameraButton: React.FunctionComponent<Props> = props => {
  const { className, view, isActive, onClicked } = props;

  return (
    <div className={className} onClick={onClicked}>
      <span className="hotspot">{view.title}</span>
      {isActive && <span className="camera-icon" />}
    </div>
  );
};

export default styled(CameraButton)`
  position: relative;
  cursor: pointer;
  margin-top: -7px;
  min-width: 100px;

  .camera-icon {
    position: absolute;
    background: url('./icons/video-record.svg') #ffffff;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    top: 26px;
    left: 32px;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-top: -14px;
  }

  .hotspot {
    position: absolute;
    height: 65px;
    width: 80px;
    min-width: inherit;
    margin-top: -17px;
    color: white;
    line-height: 40px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;

    &:after  {
      content: ' ';
      position: absolute;
      top: 46px;
      left: 46px;
      width: 6px;
      height: 6px;
      background: white;
      border-radius: 100%;
    }
  }
`;
