import { ID, Customer, Preset, CustomerConfiguration } from "../../shared/model/RawModel";
import { AppConfiguration } from "../app/AppConfiguration";
import { updateSelection } from "./updateConfiguration";
import { ModificationState, modifyItem } from "./modifyItem";
import { ConfigurationCodeResponse } from "../../shared/model/ConfigurationCodeResponse";
import { useEffect } from "react";

export const useGenerateConfigurationCodeFromActiveSlot = async (
  customerId: ID<Customer>,
  onLoaded: (codeResponse: ConfigurationCodeResponse) => void,
  onError: (errorMessage: string) => void
) => {
  const url = `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/generateConfigurationCode`;
  useEffect(()=>{
    const abortController = new AbortController();
    const onFetched = (response: Response)=>{
      if(response.ok)
      {
        response.json().then(jsonBody => {
          const codeResponse = jsonBody as ConfigurationCodeResponse;
          onLoaded(codeResponse);
        });
      }
      else
      {
        response.json().then(jsonBody => {
          const errorMessage = `${response.status} ${jsonBody.error}: ${jsonBody.message}`;
          onError(errorMessage);
        });
      }
    };

    fetch(url, { method:'PATCH', signal: abortController.signal })
      .then(onFetched)
      .catch(error => {
        if (error.name === 'AbortError') return;
        throw error
    });

    return () => {
      abortController.abort();
    }
  },[url]);
}

export const loadConfigurationCodeToActiveSlot = async (
  customerId: ID<Customer>,
  configurationCode: string,
  onLoaded: (configurationId: ID<CustomerConfiguration>) => void,
  onError: (errorMessage: string, status?:number) => void
) => {
  const url = `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/loadConfigurationCode/${configurationCode}`;
  const modificationCallback = (isSuccessful:boolean, jsonBody:any, responseStatus?:number) => {
    if(isSuccessful)
    {
      const createdCustomerConfiguration = jsonBody as CustomerConfiguration;
      onLoaded(createdCustomerConfiguration.id);
    }
    else
    {
      const errorMessage = `${jsonBody.error}: ${jsonBody.message}`;
      onError(errorMessage, responseStatus);
    }
  };
  modifyItem(url, 'PATCH', '', (state: ModificationState) => { }, modificationCallback);
}

export const loadPresetToActiveSlot = async (
  customerId: ID<Customer>,
  modelPrefix: string,
  presetId: ID<Preset>,
  countryVersion: string,
  onLoaded: (configurationId: ID<CustomerConfiguration>) => void
) => {
  const url = `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/loadPreset/${presetId}`;
  const modificationCallback = (isSuccessful:boolean, jsonBody:any) => {
    if(isSuccessful)
    {
      const createdCustomerConfiguration = jsonBody as CustomerConfiguration;
      const modificationUrl = `${AppConfiguration.baseServerUrl}/api/customer-configurations/${createdCustomerConfiguration.id}`;
      const countryVersionFeatureId = `${modelPrefix}CountryVersion_CountryVersion`;
      updateSelection(modificationUrl, countryVersionFeatureId, countryVersion, (state: ModificationState) => { });
      onLoaded(createdCustomerConfiguration.id);
    }
  };
  modifyItem(url, 'PATCH', '', (state: ModificationState) => { }, modificationCallback);
};

export const loadConfigurationToActiveSlot = async (
  customerId: ID<Customer>,
  configurationId: ID<CustomerConfiguration>,
  setModificationState: (state: ModificationState) => void,
  onLoaded?: (configurationId: ID<CustomerConfiguration>) => void
) => {
  const url = `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/loadConfiguration/${configurationId}`;
  const callback = (isSuccessful: boolean, jsonBody: any) => {
    if (isSuccessful && onLoaded) {
      const activeConfiguration = jsonBody as CustomerConfiguration;
      onLoaded(activeConfiguration.id);
    }
  }
  modifyItem(url, 'PATCH', '', setModificationState, callback);
}

export const saveConfiguration = async (
  customerId: ID<Customer>,
  callback?: (isSuccessful: boolean) => void
) => {
  const url = `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/saveConfiguration`;
  modifyItem(url, 'PUT', '', (state: ModificationState) => { }, callback);
}

export const deleteConfiguration = async (
  customerId: ID<Customer>,
  configurationId: ID<CustomerConfiguration>,
  callback?: (isSuccessful: boolean) => void
) => {
  const url = `${AppConfiguration.baseServerUrl}/api/customers/${customerId}/deleteConfiguration/${configurationId}`;
  modifyItem(url, 'PATCH', '', (state: ModificationState) => { }, callback);
}