import React, { useEffect } from 'react';
import styled from 'styled-components';

import { CarModel, ID, CustomerConfiguration } from '../../../shared/model/RawModel';
import { AppConfiguration } from '../../app/AppConfiguration';
import AppMenu from '../../components/AppMenu';
import { Page } from '../../components/Page';
import { useFullPageScroll } from '../../components/useFullPageScroll';
import { useItemFromUrl } from '../../components/useItemsFromServer';
import CarModelItem from './CarModelItem';
import { useParams, useNavigate } from 'react-router';
import { loadPresetToActiveSlot, loadConfigurationToActiveSlot } from '../../components/activeSlot';
import GarageItem from './GarageItem';
import { useGarageLogic } from '../feature-list/useGarageLogic';
import EnterCodeWidget from '../../components/EnterCodeWidget';
import { ModificationState } from '../../components/modifyItem';

export type FocusMode = 'Garage' | 'Preset';

type Props = {
  className?: string;
};

const ModelSelectionPage: React.FunctionComponent<Props> = props => {
  //XXX: countryVersion:any -> Framefield should check
  const { customerId, countryVersion, focusMode } = useParams() as {customerId:string, countryVersion:any, focusMode:FocusMode};
  const history = useNavigate();
  const [activePageIndex, setActivePageIndex] = React.useState<number>(0);
  const [isEnterCodeWidgetVisible, setIsEnterCodeWidgetVisible] = React.useState<boolean>(false);


  const { item: carModels } = useItemFromUrl<ReadonlyArray<CarModel>>(`${AppConfiguration.baseServerUrl}/api/models`);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const scroll = useFullPageScroll(contentRef.current, setActivePageIndex);

  const { listedGarageConfigurations, onConfigurationDelete } = useGarageLogic(customerId, countryVersion);
  const isGarageEmpty = !listedGarageConfigurations || listedGarageConfigurations.length === 0;

  //XXX: WAS SOLL DAS TUN???
  useEffect(()=>{
    const mode = focusMode;
    if(!isGarageEmpty && listedGarageConfigurations && mode === 'Preset')
    {
      scroll(1, contentRef.current);
    }
  }, [isGarageEmpty, listedGarageConfigurations, focusMode]);

  const createPageIndicator = (isGarageEmpty: boolean, pageCount: number) => {
    let children = [];
    if (!isGarageEmpty) {
      const isActive = activePageIndex === 0 ? 'active' : 'inactive';
      const classNames = `${isActive} home`;
      children.push(
        <li
          key={0}
          className={classNames}
          onClick={() => scroll(0, contentRef.current)}
        >
        </li>
      );
    }
    for (let i = 0; i < pageCount; i++) {
      const index = isGarageEmpty ? i : i + 1;
      const isActive = activePageIndex === index ? 'active' : 'inactive';
      const classNames = `${isActive} dot`;
      const carName = carModels![i].title;
      children.push(
        <li
          key={index}
          className={classNames}
          onClick={() => scroll(index, contentRef.current)}>
          <div className={'dotTitle'}>{carName}</div>
        </li>
      )
    }
    return children;
  }

  const forwardToFeatureListPage = (configurationId: ID<CustomerConfiguration>) => {
    history(`/features/${customerId}/${configurationId}/${countryVersion}`);
  }

  const onOpenEnterCodeWidget = () =>
  {
    setIsEnterCodeWidgetVisible(true);
  }

  const onCloseEnterCodeWidget = () => {
    setIsEnterCodeWidgetVisible(false);
  }

  if (!carModels)
    return <div />;

  //HACK: remove dealer prefix from url if needed
  if(AppConfiguration.isWebVersion && window.location.pathname!=='/')
  {
    window.location.pathname = '/';
    return (<div>Please Wait</div>)
  }

  return (
    <Page className={props.className}>
      <div className="models-container" ref={contentRef}>
        {!isGarageEmpty && listedGarageConfigurations &&
          <GarageItem
            customerConfigurations={listedGarageConfigurations}
            carModels={carModels}
            onActiveConfigurationSelected={configurationId => loadConfigurationToActiveSlot(customerId, configurationId, state => { }, forwardToFeatureListPage)}
            onConfigurationDelete={onConfigurationDelete} />
        }
        {carModels.map(model =>
          <CarModelItem
            key={model.id}
            model={model}
            onPresetSelected={presetId => loadPresetToActiveSlot(customerId, model.prefix, presetId, countryVersion, forwardToFeatureListPage)} />
        )}
      </div>
      <ul>
        {createPageIndicator(isGarageEmpty, carModels ? carModels.length : 0)}
      </ul >
      <AppMenu onOpenEnterCodeWidget={onOpenEnterCodeWidget} />
      {isEnterCodeWidgetVisible && <EnterCodeWidget onCloseCallback={onCloseEnterCodeWidget}/>}
    </Page >
  );
};

export default styled(ModelSelectionPage)`
  color: ${p => p.theme.colors.onboardingText};
  background-color: ${p => p.theme.colors.darkTransparentBackground};
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .models-container {
    transition: 1s cubic-bezier(0.5, 0, 0.5, 1);
    
    .garage-widget {
      width: 100vw;
    }
  }

  > ul { /* small paging indicator on the left */
    position: absolute;
    top: 45vh;
    left: 8px;
    padding: 16px;
    display: block;
    cursor: pointer;

    /* Reset styles */
    list-style-type: none;

    li {
      height: 20px;
      width: 20px;

      /* vertical align center for multiline title (e.g. "Grand Prix") */
      display: flex;
      flex-direction: row;
      align-items: center;

      &.active {
        background-color: ${p => p.theme.colors.onboardingText}; 
      }
      
      &.inactive {
        background-color: ${p => p.theme.colors.onboardingText};
        opacity: 0.3; 
      }
      
      /* OPTIONAL: Spacing between dots */
      margin: 16px 0;

      &.home{
        mask-position: center center;
        mask-size: cover;
        border: none;
        outline: none;
        mask-image: url('./icons/home.svg');
      }

      &.dot{
        border-radius: 9999px;
      }
    }

    .dotTitle{
      margin-left: 27px;
      width: 140px;
      min-width: 140px;
    }
  }


`;
