import { useEffect } from 'react';

type ScrollDirectionEnum = 'up' | 'down';
type Point = { x: number, y: number };

export function useFullPageScroll(
    scrollContainer: HTMLElement | null,
    onScrolled: (scrollIndex: number) => void
): (scrollIndex: number, container: HTMLElement | null) => void {

    const scrollTo = (scrollIndex: number, container: HTMLElement | null) => {
        if (container) {
            container.style.transform = 'translateY(' + scrollIndex * -100 + 'vh)';
            onScrolled(scrollIndex);
        }
    };

    useEffect(() => {
        let lastContainer: HTMLElement | null = null;
        let hold: boolean = false;

        let touchStartPoint: Point = { x: 0, y: 0 };
        const swipeThreshold: number = 100;

        //max distance allowed at the same time in perpendicular direction
        const slack: number = 50;

        //scroll towards a direction
        const scrollTowards = (scrollDirection: ScrollDirectionEnum) => {
            const step = 100;
            const vh = window.innerHeight / 100;
            const panel = lastContainer;
            
            if (panel === null)
                return;

            const panelLengthInVH = panel.offsetHeight / vh;
            let scrollPosition = parseInt(panel.style.transform.replace('translateY(', ''));
            
            if (scrollDirection === 'up' && Math.floor(Math.abs(scrollPosition - step)/100) < Math.floor(panelLengthInVH/100 )) {
                scrollPosition = scrollPosition - step;
            } else if (scrollDirection === 'down' && scrollPosition < 0) {
                scrollPosition = scrollPosition + step;
            };


            if (hold === false) {
                hold = true;
                scrollTo(Math.abs(scrollPosition / -100), panel);

                setTimeout(() => {
                    hold = false;
                }, 1000);
            }
        }

        const onWheel = (ev: WheelEvent) => {
            let scrollDirection: ScrollDirectionEnum = ev.deltaY > 0 ? 'up' : 'down';

            ev.stopPropagation();
            scrollTowards(scrollDirection);
        }

        const onTouchStart = (ev: TouchEvent) => {
            const touch = ev.changedTouches[0];
            touchStartPoint.x = touch.pageX;
            touchStartPoint.y = touch.pageY;
        }

        const onTouchMove = (ev: TouchEvent) => {
            //prevent scrolling when inside DIV
            ev.preventDefault();
        }

        const onTouchEnd = (ev: TouchEvent) => {
            const touch = ev.changedTouches[0];
            const dX = touch.pageX - touchStartPoint.x;
            const dY = touch.pageY - touchStartPoint.y;
            let scrollDirection: ScrollDirectionEnum;


            ev.stopPropagation();

            if (Math.abs(dY) >= swipeThreshold && Math.abs(dX) <= slack) {
                scrollDirection = (dY < 0) ? 'up' : 'down';
                scrollTowards(scrollDirection);
            }

        }

        if (scrollContainer) {
            lastContainer = scrollContainer;
            lastContainer.style.transform = 'translateY(0)';
            lastContainer.addEventListener('wheel', onWheel);
            lastContainer.addEventListener('touchstart', onTouchStart, false);
            lastContainer.addEventListener('touchmove', onTouchMove, false);
            lastContainer.addEventListener('touchend', onTouchEnd);
        }

        return () => {
            if (lastContainer ) {
                lastContainer.removeEventListener('wheel', onWheel);
                lastContainer.removeEventListener('touchstart', onTouchStart);
                lastContainer.removeEventListener('touchmove', onTouchMove);
                lastContainer.removeEventListener('touchend', onTouchEnd);

                lastContainer = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollContainer]);

    return scrollTo;

}