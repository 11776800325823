import { CarModel, ID, CustomerConfiguration } from "../../shared/model/RawModel";
import { AppConfiguration } from "../app/AppConfiguration";
import { LoadCarModelStatusResponse } from "../../shared/model/ActionResponse";
import React from "react";
import { throttle } from "lodash";

type CarLoadingState = {
    isLoading: boolean,
    isLoaded: boolean,
    progress: number,
}
const loadStatus = async (loadId: string, setCarLoadingState: (state: CarLoadingState) => void) => {
    const url = `${AppConfiguration.baseServerUrl}/api/loadcarmodel/status?id=${loadId}`;
    const response = await fetch(url);
    const jsonContent = await response.json();
    if (!response.ok) {
        throw new Error(jsonContent.message || 'Unknown Error');
    }

    const status = jsonContent as LoadCarModelStatusResponse;
    setCarLoadingState({
        isLoading : !status.isLoaded, // && status.progress<99.0,
        isLoaded  :  status.isLoaded,
        progress  :  status.progress
    });
}

function getLoadingStatus(
    loadId: string,
    setCarLoadingState: (state: CarLoadingState) => void) {
    loadStatus(loadId, setCarLoadingState)
        .catch(e => {
            console.error(e);
        });
}

export function useCarLoadingWitModelId(carModelId: ID<CarModel> | undefined): CarLoadingState {
    const [carLoadingState, setCarLoadingState] = React.useState<CarLoadingState>({
        isLoading: false,
        isLoaded: false,
        progress: 0
    });

    const throttledGetLoadingStatus = React.useRef(throttle(getLoadingStatus, 1000)).current;

    React.useEffect(() => {
        if (!carModelId)
            return;

        fetch(`${AppConfiguration.baseServerUrl}/api/loadcarmodel/carModel?carModelID=${carModelId}`);
        setCarLoadingState({
            isLoading: true,
            isLoaded: false,
            progress: 0
        });
    }, [carModelId]);

    if (carLoadingState.isLoading && carModelId) {
        throttledGetLoadingStatus(carModelId, setCarLoadingState);
    }

    return carLoadingState;
}

export function useCarLoadingWithConfigId(configurationId: ID<CustomerConfiguration> | undefined, activeSlotLastLoadedTimestamp:number): CarLoadingState {
    const [carLoadingState, setCarLoadingState] = React.useState<CarLoadingState>({
        isLoading: false,
        isLoaded: false,
        progress: 0
    });

    const throttledGetLoadingStatus = React.useRef(throttle(getLoadingStatus, 200)).current;

    React.useEffect(() => {
        if (!configurationId)
            return;

        fetch(`${AppConfiguration.baseServerUrl}/api/loadcarmodel/customerConfiguration?customerConfigurationId=${configurationId}`);
        setCarLoadingState({
            isLoading: true,
            isLoaded: false,
            progress: 0
        });
    }, [configurationId, activeSlotLastLoadedTimestamp]);

    if (carLoadingState.isLoading && configurationId) {
        throttledGetLoadingStatus(configurationId, setCarLoadingState);
    }

    return carLoadingState;
}