import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../../shared/model/FeatureSet';
import { ID, Option } from '../../../../../../shared/model/RawModel';
import ToggleWidget from '../ToggleWidget';
import OptionListWidget from './OptionListWidget';

type Props = {
  className?: string;
  feature: FeatureObject;
  options: ReadonlyArray<OptionObject>;
  headerRef: React.MutableRefObject<HTMLElement | null>;
  onResetToPreset: () => void;
  onSelect: (optionId: ID<Option>) => void;
};
const OptionsArea: React.FunctionComponent<Props> = props => {
  const { feature, options } = props;


  if (options.length === 0) {
    return <section />;
  }

  switch (feature.uiComponent) {
    case 'scrollableListPicker':
      return <OptionListWidget {...props} />;
    case 'optionToggle':
      return <ToggleWidget {...props} />;
    default:
      return <div className="error-message">No known options area ui component is found</div>;
  }
};
export default styled(OptionsArea)``;
