import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppConfiguration } from '../app/AppConfiguration';
import ActionIconButton from './ActionIconButton';
import LoadingSpinner from './LoadingSpinner';
import { useExportVideo } from './useExportVideo';
import { ID, CustomerConfiguration } from '../../shared/model/RawModel';

type Props = {
    className?: string;
    onCloseCallback: () => void;
}

const ExportVideoWidget: React.FunctionComponent<Props> = props => {
    const { className,  onCloseCallback } = props;
    const { customerConfigurationId } = useParams() as {customerConfigurationId:ID<CustomerConfiguration>};

    const {exportVideoState, cancelExport }= useExportVideo(customerConfigurationId);

    const showConfirm = (message : string) => {
      if(!AppConfiguration.isElectronApp)
      {
        return window.confirm(message);
      }

      const electron = window.require('electron')
      const { dialog } = electron.remote
      const buttonIdx = dialog.showMessageBoxSync(null, {
        type: 'warning',
        message,
        buttons: ['OK', 'Cancel'],
        cancelId: 1,
        defaultId: 0,
      })
      return buttonIdx === 0;
    }


    const onClose = () => {
      if (!showConfirm("Are you sure about aborting video export?"))
        return;

      cancelExport();
      onCloseCallback();
    }

    const formClassName = !exportVideoState.isExporting ? 'message-wrapper visible' : 'message-wrapper hidden';
    const exportMessage = `remaining time ${exportVideoState.eta_min}min`;

    return (
      <div className={className}>
        <header className="dark-navigation">
          <h1>
            Video Export
            <div className="button-on-circle">
              <ActionIconButton className="icon-close" onClick={onClose} />
            </div>
          </h1>
        </header>
          <div className={formClassName}>
            <div className='exported-message'>
              <span className="checkmark" />
                <div>video successfully exported</div>
            </div>
          </div>
          <LoadingSpinner visible={exportVideoState.isExporting} message="Exporting Video" additionalMessage={exportMessage} />
        </div>
    )
}

export default styled(ExportVideoWidget)`
position: absolute;
top: 0;
left: 0;
right: 0;
height: 100%;
background-color: ${p => p.theme.colors.darkTransparentBackground};
backdrop-filter: blur(8px);
pointer-events: all;

header.dark-navigation {
  h1 {
    color: ${p => p.theme.colors.onboardingText};
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    font-family: 'ZwoLF Bold';
    font-size: 28px;
    line-height: 69px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;

    .button-on-circle {
      position: fixed;
      top:8px;
      right: 8px;
      width: 45px;
      height: 45px;
      margin-left: 10px;
      border-radius: 100px;
      background-color: ${p => p.theme.colors.onboardingText};
      display: flex;
      justify-content: center;
      align-items: center;
      z-index:1;
      
      .close-button {
        width: 35px;
        height: 35px;
        background-color: ${p => p.theme.colors.onboardingBackground};
      }
    }
  }

  div {
    width: 540px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    img {
      top: -6px;
      position: relative;
      width: 48px;
      height: 48px;
    }
  }
}

.message-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 43px;
  padding: 55px;
  color: ${p => p.theme.colors.onboardingText};
  opacity: 0;

  .exported-message{
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkmark{
      width: 100px;
      height:100px;
      mask-image: url('./icons/checkmark.svg');
      mask-size: 100% 100%;
      background-color: ${p => p.theme.colors.onboardingText};
    }
  }

  &.visible {
    pointer-events: all;
    opacity: 1;
    transition: opacity 1000ms;
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transition: opacity 1000ms;
  }
} 

${LoadingSpinner}
{
  top: 0px;
}
`;