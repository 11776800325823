import * as React from 'react';
import styled from 'styled-components';

import { FeatureObject, OptionObject } from '../../../../../../shared/model/FeatureSet';
import { ID, Option } from '../../../../../../shared/model/RawModel';

type Props = {
  className?: string;
  feature: FeatureObject;
  options: ReadonlyArray<OptionObject>;
  onSelect: (optionId: ID<Option>) => void;
  onResetToPreset: () => void;
};
const FeatureSettingsToggle: React.FunctionComponent<Props> = props => {
  const { options, onSelect } = props;

  if (options.length > 2 || options.length === 0) {
    return <div className="error-message">Feature Settings Toggle: {props.feature.title} does not have 1 or 2 options</div>
  }

  if (options.length === 1) 
  {
    return <div>{options[0].title}</div>
  }

  const first = options[0];
  const second = options[1];
  const isSecondSelected = second.status === 'selected-from-preset' || second.status === 'selected-by-user';
  const selectedOptionTitle = isSecondSelected ? second.title : first.title;
  const inputChanged: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.checked) {
      onSelect(second.optionId);
    } else {
      onSelect(first.optionId);
    }
  };

  return (
    <section className={props.className}>
      <label htmlFor="toggle">{selectedOptionTitle}</label>
      <input id="toggle" type="checkbox" checked={isSecondSelected} onChange={inputChanged} />
    </section>
  );
};
export default styled(FeatureSettingsToggle)`

  select,
  label {
    border-radius:0;
    border: 1px solid ${p => p.theme.colors.foregroundDimmed};
    transition: border 250ms;
  }
  select:focus,
  label:focus {
    border: 1px solid ${p => p.theme.colors.foreground};
    transition: border 250ms;
  }

  > input {
    opacity: 0;
  }
`;
